import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Colors from '../../styles/Colors';

const Button = (props) => {
    const {
        btnType, btnText, disabled, onClick,
    } = props;

    const getColor = (type) => {
        let color;
        switch (type) {
            case 'primary':
                color = Colors.midNightGreen;
                break;
            case 'secondary':
                color = Colors.lightKhaki;
                break;
            default:
                color = Colors.midNightGreen;
        }
        return color;
    };

    const getBackgroundColor = (type) => {
        let color;
        switch (type) {
            case 'primary':
                color = Colors.lightKhaki;
                break;
            case 'secondary':
                color = 'transparent';
                break;
            default:
                color = Colors.lightKhaki;
        }
        return color;
    };

    const getBorder = (type) => {
        let border;
        switch (type) {
            case 'primary':
                border = `1px solid ${Colors.lightKhaki}`;
                break;
            case 'secondary':
                border = `1px solid ${Colors.lightKhaki}`;
                break;
            default:
                border = `1px solid ${Colors.lightKhaki}`;
        }
        return border;
    };

    const getHoverColor = (type) => {
        let color;
        switch (type) {
            case 'primary':
                color = Colors.midNightGreen;
                break;
            case 'secondary':
                color = Colors.ivory;
                break;
            default:
                color = Colors.midNightGreen;
        }
        return color;
    };

    const getHoverBackgroundColor = (type) => {
        let color;
        switch (type) {
            case 'primary':
                color = Colors.ivory;
                break;
            case 'secondary':
                color = 'transparent';
                break;
            default:
                color = Colors.ivory;
        }
        return color;
    };

    const getHoverBorder = (type) => {
        let border;
        switch (type) {
            case 'primary':
                border = `1px solid ${Colors.ivory}`;
                break;
            case 'secondary':
                border = `1px solid ${Colors.ivory}`;
                break;
            default:
                border = `1px solid ${Colors.ivory}`;
        }
        return border;
    };

    return (
        <Container>
            <Btn
                color={getColor(btnType)}
                backgroundColor={getBackgroundColor(btnType)}
                border={getBorder(btnType)}
                hoverColor={getHoverColor(btnType)}
                hoverBackgroundColor={getHoverBackgroundColor(btnType)}
                hoverBorder={getHoverBorder(btnType)}
                disabled={disabled}
                onClick={onClick}
            >
                {btnText}
            </Btn>
        </Container>
    );
};

Button.propTypes = {
    btnType: PropTypes.string.isRequired,
    btnText: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    onClick: () => {},
    disabled: false,
};

export default Button;

const Container = styled.div`
    width: 100%;
`;

const Btn = styled.button`
    width: 100%;
    color: ${(props) => props.color};
    background-color: ${(props) => props.backgroundColor};
    border: ${(props) => props.border};
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    padding: 8px 20px;
    font-size: 24px;
    line-height: 36px;
    border-radius: 8px;
    font-family: Iceberg, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    font-weight: 600;
    @media (max-width: 992px) {
        font-size: 20px;
        line-height: 32px;
        padding: 6px 12px;
    }
    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
        padding: 6px 12px;
    }
    :hover {
        background-color: ${(props) => (props.disabled ? props.backgroundColor : props.hoverBackgroundColor)};
        border: ${(props) => (props.disabled ? props.border : props.hoverBorder)};
        color: ${(props) => (props.disabled ? props.color : props.hoverColor)};
    }
`;
