const externalAddresses = {
    MAIN_WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    MAIN_USDC: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    OPT_USDC: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
    OPT_VETH: '0x8C835DFaA34e2AE61775e80EE29E2c724c6AE2BB',
    OPT_PERP_LP_REWARD: '0x3230cbb08c64d0804be5b7f4ce43834291490a91',
    OPT_PERP: '0x9e1028F5F1D5eDE59748FFceE5532509976840E0',
    OPT_PERP_ORDERBOOK: '0xDfcaEBe8f6ea5E022BeFAFaE8c6Cdae8D4E1094b',
    CHAINLINK_ORACLE: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
};

export default externalAddresses;
