import React from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Colors from '../../styles/Colors';
import { openNewTab } from '../../utils/common';

const OPT_BRIDGE_URL = 'https://app.optimism.io/bridge';
const OPT_MEDIUM_ARTICLE = 'https://medium.com/diamond-protocol/how-to-deposit-usdc-into-diamond-protousdc-lp-vault-bb7dca1005f4';

const OptInfoModal = (props) => {
    const { isOpen, handleClose } = props;

    return (
        <InfoModal
            show={isOpen}
            onHide={handleClose}
        >
            <ModalHeader
                closeButton
                closeVariant='white'
            >
                <ModalTitle>What Is Optimisim?</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Text>
                    Optimism is a Layer 2 protocol of Ethereum. Cash & Carry Vault is built on this network for its high scalability and low
                    gas. In order to deposit in this vault, you need to make sure you deposit your USDC and some ETH (we recommend 0.05 ETH)
                    into your Optimism network. You can do this by connecting your wallet through the Optimism App. Please note you can
                    freely transfer your assets between your Optimism wallet and your Ethereum wallet, they will have the same wallet
                    address. To deposit into your own Optimism Wallet, you can visit
                </Text>
                <TextWthHyperLink onClick={() => openNewTab(OPT_BRIDGE_URL)}>{` ${OPT_BRIDGE_URL} `}</TextWthHyperLink>
                <Text>
                    connect your wallet and follow the flow there. For more detailed demonstration of how to deposit into your own Optimism
                    wallet, you can visit:
                </Text>
                <TextWthHyperLink onClick={() => openNewTab(OPT_MEDIUM_ARTICLE)}>{` ${OPT_MEDIUM_ARTICLE}`}</TextWthHyperLink>
            </ModalBody>
        </InfoModal>
    );
};

OptInfoModal.propTypes = {
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
};

OptInfoModal.defaultProps = {
    isOpen: false,
    handleClose: () => {},
};

export default OptInfoModal;

const InfoModal = styled(Modal)`
    .modal-content {
        margin: 20vh auto auto auto;
        width: 90%;
        max-width: 350px;
        border-radius: 20px;
        border: 1px solid ${Colors.gray5};
        background-color: ${Colors.midNightGreen};
        color: ${Colors.ivory};
        padding: 12px 12px 24px 12px;
    }
    backdrop-filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.3);
`;

const ModalHeader = styled(Modal.Header)`
    border-width: 0;
    color: ${Colors.ivory};
`;

const ModalTitle = styled(Modal.Title)`
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
`;

const ModalBody = styled(Modal.Body)`
    /* padding-top: 0px; */
`;

const Text = styled.span`
    font-size: 14px;
    line-height: 22px;
`;

const TextWthHyperLink = styled(Text)`
    color: #70a5f3;
    cursor: pointer;
    :hover {
        color: ${Colors.lightKhaki};
    }
`;
