export const SET_ACCOUNT_BALANCE = 'SET_ACCOUNT_BALANCE';
export const SET_CONNECTED_ACCOUNT = 'SET_CONNECTED_ACCOUNT';
export const SET_PROVIDER = 'SET_PROVIDER';
export const VAULT_DEPOSIT_START = 'VAULT_DEPOSIT_START';
export const VAULT_DEPOSIT_SUCCEED = 'VAULT_DEPOSIT_SUCCEED';
export const VAULT_DEPOSIT_FAILED = 'VAULT_DEPOSIT_FAILED';
export const VAULT_WITHDRAW_START = 'VAULT_WITHDRAW_START';
export const VAULT_WITHDRAW_SUCCEED = 'VAULT_WITHDRAW_SUCCEED';
export const VAULT_WITHDRAW_FAILED = 'VAULT_WITHDRAW_FAILED';
export const SET_SELECT_WALLET_MODAL_STATUS = 'SET_SELECT_WALLET_MODAL_STATUS';
export const SET_CONNECTED_WALLET_MODAL_STATUS = 'SET_CONNECTED_WALLET_MODAL_STATUS';
export const RESET_DEPOSIT_HASH = 'RESET_DEPOSIT_HASH';
export const RESET_WITHDRAW_HASH = 'RESET_WITHDRAW_HASH';
export const GET_VAULT_CAPACITY = 'GET_VAULT_CAPACITY';
export const GET_VAULT_TOTAL_BALANCE = 'GET_VAULT_TOTAL_BALANCE';
export const GET_VAULT_USER_BALANCE = 'GET_VAULT_USER_BALANCE';
export const GET_VAULT_TX_DATA_ASC = 'GET_VAULT_TX_DATA_ASC';
export const GET_VAULT_TX_DATA_DESC = 'GET_VAULT_TX_DATA_DESC';
export const GET_VAULT_STATUS = 'GET_VAULT_STATUS';
export const OPTION_PAY_PREMIUM_START = 'OPTION_PAY_PREMIUM_START';
export const OPTION_PAY_PREMIUM_SUCCEED = 'OPTION_PAY_PREMIUM_SUCCEED';
export const OPTION_PAY_PREMIUM_FAILED = 'OPTION_PAY_PREMIUM_FAILED';
export const RESET_PAY_PREMIUM_HASH = 'RESET_PAY_PREMIUM_HASH';
export const GET_VAULT_ESTI_APY = 'GET_VAULT_ESTI_APY';
export const GET_VAULT_TOKEN_PRICE = 'GET_VAULT_TOKEN_PRICE';
export const GET_VAULT_ROUND_DATA = 'GET_VAULT_ROUND_DATA';
export const MAKER_APPROVE_WETH_START = 'MAKER_APPROVE_WETH_START';
export const MAKER_APPROVE_WETH_SUCCEED = 'MAKER_APPROVE_WETH_SUCCEED';
export const MAKER_APPROVE_WETH_FAILED = 'MAKER_APPROVE_WETH_FAILED';
export const RESET_APPROVE_WETH_HASH = 'RESET_APPROVE_WETH_HASH';
export const SET_SELECT_NETWORK_MODAL_STATUS = 'SET_SELECT_NETWORK_MODAL_STATUS';
export const SET_ACTIVE_NETWORK_CHAINID = 'SET_ACTIVE_NETWORK_CHAINID';
export const SET_OPT_INFO_MODAL_STATUS = 'SET_OPT_INFO_MODAL_STATUS';
