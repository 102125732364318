/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
// import { isMobile } from 'react-device-detect';
import Colors from '../../styles/Colors';
import { openNewTab } from '../../utils/common';

import metamask from '../../images/metamask.png';
import walletConnect from '../../images/walletConnect.png';
import Button from './Button';

const METAMASK_URL = process.env.REACT_APP_METAMASK_URL;

const SelectWalletModal = (props) => {
    const {
        isOpen, handleClose, selectMethod, setSelectMethod, connectToMetaMask, connectToWalletConnect,
    } = props;

    // const isDappBrowser = Boolean(window.ethereum);
    const metaMaskExisted = typeof window.ethereum !== 'undefined';

    return (
        <WalletModal
            show={isOpen}
            onHide={handleClose}
        >
            <ModalHeader
                closeButton
                closeVariant='white'
            >
                <ModalTitle>Connect Wallet</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <MethodContainer>
                    <WalletBtnContainer
                        onClick={() => setSelectMethod('MetaMask')}
                        isSelected={selectMethod === 'MetaMask'}
                    >
                        <LeftContainer>
                            <WalletBtnText>{metaMaskExisted ? 'MetaMask' : 'Install MataMask'}</WalletBtnText>
                        </LeftContainer>
                        <WalletBtnIcon
                            src={metamask}
                            alt='metamask icon'
                        />
                    </WalletBtnContainer>
                    <WalletBtnContainer
                        onClick={() => setSelectMethod('WalletConnect')}
                        isSelected={selectMethod === 'WalletConnect'}
                    >
                        <LeftContainer>
                            <WalletBtnText>WalletConnect</WalletBtnText>
                        </LeftContainer>
                        <WalletBtnIcon
                            src={walletConnect}
                            alt='walletConnect icon'
                        />
                    </WalletBtnContainer>
                </MethodContainer>
                <Button
                    btnType='primary'
                    btnText='Next'
                    onClick={
                        selectMethod === 'MetaMask'
                            ? metaMaskExisted
                                ? connectToMetaMask
                                : () => openNewTab(METAMASK_URL)
                            : connectToWalletConnect
                    }
                />
            </ModalBody>
        </WalletModal>
    );
};

SelectWalletModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    selectMethod: PropTypes.string.isRequired,
    setSelectMethod: PropTypes.func.isRequired,
    connectToMetaMask: PropTypes.func.isRequired,
    connectToWalletConnect: PropTypes.func.isRequired,
};

SelectWalletModal.defaultProps = {};

export default SelectWalletModal;

const WalletModal = styled(Modal)`
    .modal-content {
        margin: 20vh auto auto auto;
        width: 90%;
        max-width: 350px;
        border-radius: 20px;
        border: 1px solid ${Colors.gray5};
        background-color: ${Colors.midNightGreen};
        color: ${Colors.ivory};
        padding: 20px 12px 24px 12px;
    }
    backdrop-filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.3);
`;

const ModalHeader = styled(Modal.Header)`
    border-width: 0;
    color: ${Colors.ivory};
`;

const ModalTitle = styled(Modal.Title)`
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
`;

const ModalBody = styled(Modal.Body)``;

const MethodContainer = styled.div`
    margin-bottom: 30px;
`;

const WalletBtnContainer = styled.div`
    padding: 16px 18px;
    border: 1px solid ${Colors.midNightGreen};
    border-radius: 10px;
    border: ${(props) => `1px solid ${props.isSelected ? Colors.lightKhaki : Colors.ivory}`};
    color: ${Colors.ivory};
    box-shadow: ${(props) => (props.isSelected ? `0 0 2px ${Colors.lightKhaki}` : 'none')};
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    :hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

const WalletBtnText = styled.span`
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const WalletBtnIcon = styled.img`
    width: 30px;
    height: 30px;
    object-fit: contain;
`;
