import React from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Colors from '../../styles/Colors';
import { openNewTab } from '../../utils/common';

const DISCORD_URL = process.env.REACT_APP_DISCORD_URL;

const ReachDSModal = (props) => {
    const { isOpen, handleClose } = props;

    return (
        <InfoModal
            show={isOpen}
            onHide={handleClose}
        >
            <ModalHeader
                closeButton
                closeVariant='white'
            >
                <ModalTitle>Still having problems?</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Text>Reach us at </Text>
                <TextWthHyperLink onClick={() => openNewTab(DISCORD_URL)}>{` ${DISCORD_URL} `}</TextWthHyperLink>
            </ModalBody>
        </InfoModal>
    );
};

ReachDSModal.propTypes = {
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
};

ReachDSModal.defaultProps = {
    isOpen: false,
    handleClose: () => {},
};

export default ReachDSModal;

const InfoModal = styled(Modal)`
    .modal-content {
        margin: 20vh auto auto auto;
        width: 90%;
        max-width: 350px;
        border-radius: 20px;
        border: 1px solid ${Colors.gray5};
        background-color: ${Colors.midNightGreen};
        color: ${Colors.ivory};
        padding: 12px 12px 24px 12px;
    }
    backdrop-filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.3);
`;

const ModalHeader = styled(Modal.Header)`
    border-width: 0;
    color: ${Colors.ivory};
`;

const ModalTitle = styled(Modal.Title)`
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
`;

const ModalBody = styled(Modal.Body)`
    /* padding-top: 0px; */
`;

const Text = styled.span`
    font-size: 14px;
    line-height: 22px;
`;

const TextWthHyperLink = styled(Text)`
    color: #70a5f3;
    cursor: pointer;
    :hover {
        color: ${Colors.lightKhaki};
    }
`;
