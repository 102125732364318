/* eslint-disable max-len */
export const optionVaultDisclaimers = [
    {
        num: 1,
        text: 'The primary risk for running this covered call strategy is that the vault may incur a weekly loss in the case where the call options sold by the vault expire in-the-money (meaning the price of ETH is above the strike price of the call options minted by the vault).',
    },
    {
        num: 2,
        text: 'This vault is run by smart contracts. A smart contract is a program running on the blockchain. The program may have bugs, hacked attacks, or business logic errors, which may lead to asset loss in the contract.',
    },
    {
        num: 3,
        text: 'Blockchain risks: The blockchain is also composed of programs and it may also have bugs or be attacked, which may cause malfunctions and affect its applications.',
    },
    {
        num: 4,
        text: 'Counterparty risks: Counter parties of this product are on-chain option protocol providers, centralized and decentralized exchanges, etc. These counterparties’ operations, algorithms, vault securities all may present potential risk for this product.',
    },
    {
        num: 5,
        text: 'Other risks: Blockchain and its applications are emerging technologies, which may include other risks not listed above.',
    },
];

export const perpVaultDisclaimers = [
    {
        num: 1,
        text: 'The cash & carry strategy is profitable only when the funding rates on Perpetual Protocol are positive. However, the strategy and investors can incur losses if the funding rates turn negative. We cannot predict the directional movement of funding rate on Perpetual Protocol.',
    },
    {
        num: 2,
        text: 'In our cash & carry strategy, even though the vault opens the short position with 1X collateral ratio, we might still face liquidation risk if price soars almost 100%. To cope with this, a key role called keeper in our cash & carry vault will monitor the price on both the spot side and the perpetual side. If the price has moved more than our safety threshold (33%), the keeper will automatically re-balance the positions to avoid liquidation. In the extreme market condition, where price rises very high very fast, or our keeper fails to re-balance in time, this strategy may suffer losses, even liquidation.',
    },
    {
        num: 3,
        text: 'The smart contract has gone through thorough tests and examinations by our team, and it is also audited by security firm Quantstamp in May, 2022. However, even though we have done the best we can to ensure the quality of our code, there might still consist unknown issues or bugs. Or we might be victims of potential hacks. Therefore, users should invest with caution and DYOR (Do Your Own Research).',
    },
    {
        num: 4,
        text: 'Blockchain risks: The blockchain is also composed of programs and it may also have bugs or be attacked, which may cause malfunctions and affect its applications.',
    },
    {
        num: 5,
        text: 'Counterparty risks: Counter parties of this product are on-chain option protocol providers, centralized and decentralized exchanges, etc. These counterparties’ operations, algorithms, vault securities all may present potential risk for this product.',
    },
    {
        num: 6,
        text: 'Other risks: Blockchain and its applications are emerging technologies, which may include other risks not listed above.',
    },
];

export const disclaimerParagrah1 = `An investment in Diamond Protocol Vaults involves a high degree of risk, including the risk that the entire amount invested
may be lost. Diamond Protocol Vaults invest in and actively trades financial instruments using strategies and investment
techniques with significant risk characteristics, including the risks arising from the volatility of the crypto markets, the
risks of borrowings and short sales, the risks arising from leverage associated with trading in the crypto currencies/assets
and over-the-counter derivatives markets, the illiquidity of derivative instruments and the risk of loss from counterparty
defaults. No guarantee or representation is made that the investment programme will be successful or that the Vaults’
returns will exhibit low correlation with an investor’s other investments. Diamond Protocol Vaults may use such investment
techniques as option transactions, margin transactions, short sales, leverage, derivatives trading and futures and forward
contracts, which practices can involve substantial volatility and can, in certain circumstances, substantially increase the
adverse impact to which the Diamond Protocol investment portfolio may be subject. All investments risk the loss of capital.
The investment techniques and strategies and the nature of the securities and or instruments to be purchased and traded by
Diamond Protocol may increase this risk. While the Diamond Protocol team will devote its best efforts to product, there can
be no assurance that various vaults on Diamond Protocol will not incur losses`;

export const disclaimerParagrah2 = `. Many unforeseeable events may cause sharp market fluctuations, which could adversely affect the investment. Changes in
the macroeconomic environment, including, for example, interest rates, inflation rates, industry conditions,
competition, technological developments, political events and trends, changes to tax laws, currency exchange rates,
regulatory policy, employment and consumer demand and innumerable other factors, can substantially and adversely affect
the performance of an underlying investment strategy. None of these conditions will be within the control of the Diamond
Protocol Team. Digital assets are assets that are loosely regulated and there is no central marketplace for currency
exchange of such digital assets. Supply of the digital assets is determined by a computer code, not by a central bank,
and prices can be extremely volatile. Several factors may affect the price of digital assets, including, but not limited
to: supply and demand, investors’ expectations with respect to the rate of inflation, interest rates, currency exchange
rates or future regulatory measures (if any) that restrict the trading of digital assets or the use of digital assets as
a form of payment. There is no assurance that digital assets will maintain their long-term value in terms of purchasing
power in the future, or that acceptance of digital asset payments by mainstream retail merchants and commercial
businesses will continue to grow. A principal risk of an investment into regulated derivatives whose underlying exposure
is digital assets is the rapid fluctuation of their market price. High price volatility undermines the role of digital
assets as a medium of exchange as retailers are much less likely to accept them as a form of payment. The value of an
investment relates directly to the price of the digital assets derivatives, and fluctuations in the price of digital
assets could adversely affect the value of the investment. Currently, there is relatively modest use of digital assets
in the retail and commercial marketplace compared to its use by speculators, thus contributing to price volatility that
could adversely affect an investment in the Diamond Protocol Vaults. If future regulatory actions or policies limit the
ability to own or exchange digital assets in the retail and commercial marketplace, or use them for payments, or own
them generally, the price and demand for digital assets may decrease. Such decrease in demand may result in the winding
up and liquidation of the Diamond Protocol Vaults at a time that may be disadvantageous to the investors, or may
adversely affect the investment. To the extent the public demand for digital assets were to decrease or Diamond Protocol
is unable to find a willing counterparty for its derivatives whose underlying assets exposure are digital assets, the
price of such digital assets could fluctuate rapidly and Diamond Protocol may be unable to close its position related to
the derivative products. Investors will also be subject to the risk of price fluctuations of digital assets until their
investment is fully redeemed from Diamond Protocol. Further, if the supply of digital assets available to the public
were to increase or decrease suddenly due to, for example, a change in a digital asset’s source code, the dissolution of
a virtual currency exchange, or seizure of digital assets by government authorities, the price of digital assets could
fluctuate rapidly. Such changes in demand and supply of digital assets could adversely affect the investment which is
benchmarked against the price of those digital assets. In addition, governments may intervene, directly and by
regulation, in the digital asset market, with the specific effect, or intention, of influencing digital asset prices and
valuation (e.g. releasing previously seized digital assets). Similarly, any government action or regulation may
indirectly affect the digital assets market or cryptocurrency and digital asset network, influencing digital asset use
or prices. The investment characteristics of digital assets generally differ from those of traditional currencies,
commodities or securities. Importantly, digital assets are not backed by a central bank or a national, supra-national or
quasi-national organization, any hard assets, human capital, or other form of credit. Rather, digital assets are
market-based: a digital asset's value is determined by (and fluctuates often, according to) supply and demand factors,
the number of merchants that accept it, and the value that various market participants place on it through their mutual
agreement, barter or transactions. Diamond Protocol uses centralized exchanges, decentralized exchanges, OTC traders,
and vault managers to maintain the operation of the vault and deployment of investment instruments. The functioning of
these parties of interests will directly affect the operation of Diamond Protocol and in the event that their operations
are defrauded, defunct, mal-function, or attacked, the operation and returns of Diamond Protocol will be adversely
affected and may result in the loss of funds from Investors. Prospective investors should consider the above risk
factors carefully in determining whether an investment in the Diamond Protocol is a suitable investment.`;
