/* eslint-disable no-unused-vars */
import {
    SET_CONNECTED_ACCOUNT,
    SET_ACCOUNT_BALANCE,
    SET_PROVIDER,
    VAULT_DEPOSIT_START,
    VAULT_DEPOSIT_SUCCEED,
    VAULT_DEPOSIT_FAILED,
    VAULT_WITHDRAW_START,
    VAULT_WITHDRAW_SUCCEED,
    VAULT_WITHDRAW_FAILED,
    SET_SELECT_WALLET_MODAL_STATUS,
    SET_CONNECTED_WALLET_MODAL_STATUS,
    RESET_DEPOSIT_HASH,
    RESET_WITHDRAW_HASH,
    GET_VAULT_CAPACITY,
    GET_VAULT_TOTAL_BALANCE,
    GET_VAULT_USER_BALANCE,
    GET_VAULT_TX_DATA_DESC,
    GET_VAULT_TX_DATA_ASC,
    GET_VAULT_STATUS,
    OPTION_PAY_PREMIUM_START,
    OPTION_PAY_PREMIUM_SUCCEED,
    OPTION_PAY_PREMIUM_FAILED,
    RESET_PAY_PREMIUM_HASH,
    GET_VAULT_ESTI_APY,
    GET_VAULT_TOKEN_PRICE,
    GET_VAULT_ROUND_DATA,
    MAKER_APPROVE_WETH_START,
    MAKER_APPROVE_WETH_SUCCEED,
    MAKER_APPROVE_WETH_FAILED,
    RESET_APPROVE_WETH_HASH,
    SET_SELECT_NETWORK_MODAL_STATUS,
    SET_ACTIVE_NETWORK_CHAINID,
    SET_OPT_INFO_MODAL_STATUS,
} from './actionType';

import {
    depositETHIntoVault,
    getUserBalanceInEthVault,
    getDiamondVaultAum,
    withdrawETHFromVault,
    getMainnetEthBalance,
    getBrowserProvider,
    getVaultMaxCapacity,
    getDiamondVaultTxData,
    getVaultLockedStatus,
    makerPayPremium,
    getDiamondVaultEstiApy,
    getGinzaRoundsData,
    makerApproveWETH,
    getOptUsdcBalance,
    getCapacityOfBasisTrading,
    getTotalAssetInBasisTrading,
    getUserBalanceInBasisTradingVault,
} from '../../utils/diamond';

export function setConnectedAccount(accountData) {
    return { type: SET_CONNECTED_ACCOUNT, data: accountData };
}

export const onDeposit = (ethAmount, provider, accountAddress, vaultAddress) => async (dispatch) => {
    dispatch({ type: VAULT_DEPOSIT_START });
    try {
        const res = await depositETHIntoVault(ethAmount, provider, accountAddress, vaultAddress);
        dispatch({ type: VAULT_DEPOSIT_SUCCEED, payload: { hash: res.hash } });
        await res.wait();
        dispatch({ type: RESET_DEPOSIT_HASH });
    } catch (error) {
        dispatch({ type: VAULT_DEPOSIT_FAILED, payload: { error } });
    }
};

export const onWithdrawETH = (ethAmount, provider, accountAddress, vaultAddress, isWithdrawAll) => async (dispatch) => {
    dispatch({ type: VAULT_WITHDRAW_START });
    try {
        const res = await withdrawETHFromVault(ethAmount, provider, accountAddress, vaultAddress, isWithdrawAll);
        dispatch({ type: VAULT_WITHDRAW_SUCCEED, payload: { hash: res.hash } });
        await res.wait();
        dispatch({ type: RESET_WITHDRAW_HASH });
    } catch (error) {
        dispatch({ type: VAULT_WITHDRAW_FAILED, payload: { error } });
    }
};

export const getVaultAum = (contractAddress, vaultId) => async (dispatch) => {
    if (contractAddress) {
        const balance = await getDiamondVaultAum(contractAddress);
        dispatch({ type: GET_VAULT_TOTAL_BALANCE, payload: { balance, id: vaultId } });
    }
};

export const getEthVaultUserBalance = (contractAddress, connectedAccount, vaultId) => async (dispatch) => {
    if (contractAddress) {
        const balance = await getUserBalanceInEthVault(contractAddress, connectedAccount);
        dispatch({ type: GET_VAULT_USER_BALANCE, payload: { balance, id: vaultId } });
    }
};

export const getBasisVaultUserBalance = (proxyAddress, connectedAccount, vaultId) => async (dispatch) => {
    if (proxyAddress) {
        const balance = await getUserBalanceInBasisTradingVault(proxyAddress, connectedAccount);
        dispatch({ type: GET_VAULT_USER_BALANCE, payload: { balance, id: vaultId } });
    }
};

export const setUserMainEthBalance = (connectedAccount, chain, currency) => async (dispatch) => {
    const balance = await getMainnetEthBalance(connectedAccount);
    dispatch({ type: SET_ACCOUNT_BALANCE, payload: { balance, chain, currency } });
};

export const setBrowserProvider = (connectedAccount) => async (dispatch) => {
    const provider = await getBrowserProvider(connectedAccount);
    dispatch({ type: SET_PROVIDER, payload: { provider } });
};

export const setSelectWalletModalStatus = (status) => async (dispatch) => {
    dispatch({ type: SET_SELECT_WALLET_MODAL_STATUS, payload: status });
};

export const setConnectedWalletModalStatus = (status) => async (dispatch) => {
    dispatch({ type: SET_CONNECTED_WALLET_MODAL_STATUS, payload: status });
};

export const setSelectNetworkModalStatus = (status) => async (dispatch) => {
    dispatch({ type: SET_SELECT_NETWORK_MODAL_STATUS, payload: status });
};

export const setOptInfoModalStatus = (status) => async (dispatch) => {
    dispatch({ type: SET_OPT_INFO_MODAL_STATUS, payload: status });
};

export const resetDepositHash = () => async (dispatch) => {
    dispatch({ type: RESET_DEPOSIT_HASH });
};

export const resetWithdrawHash = () => async (dispatch) => {
    dispatch({ type: RESET_WITHDRAW_HASH });
};

export const getVaultCapacity = (contractAddress, vaultId) => async (dispatch) => {
    if (contractAddress) {
        const capacity = await getVaultMaxCapacity(contractAddress);
        dispatch({ type: GET_VAULT_CAPACITY, payload: { capacity, id: vaultId } });
    }
};

export const getVaultTxData = (ginzaId, sort, vaultId) => async (dispatch) => {
    if (ginzaId) {
        const txData = await getDiamondVaultTxData(ginzaId, sort);
        if (sort === 'time asc') {
            dispatch({ type: GET_VAULT_TX_DATA_ASC, payload: { txData, id: vaultId } });
        } else {
            dispatch({ type: GET_VAULT_TX_DATA_DESC, payload: { txData, id: vaultId } });
        }
    }
};

export const getVaultStatus = (contractAddress, vaultId) => async (dispatch) => {
    if (contractAddress) {
        const isUnlocked = await getVaultLockedStatus(contractAddress);
        dispatch({ type: GET_VAULT_STATUS, payload: { isUnlocked, id: vaultId } });
    }
};

export const onPayPremium = (ethAmount, provider, accountAddress, optionAddress) => async (dispatch) => {
    dispatch({ type: OPTION_PAY_PREMIUM_START });
    try {
        const res = await makerPayPremium(ethAmount, provider, accountAddress, optionAddress);
        dispatch({ type: OPTION_PAY_PREMIUM_SUCCEED, payload: { hash: res.hash } });
        await res.wait();
        dispatch({ type: RESET_PAY_PREMIUM_HASH });
    } catch (error) {
        dispatch({ type: OPTION_PAY_PREMIUM_FAILED, payload: { error } });
    }
};

export const getVaultApy = (ginzaId, vaultId) => async (dispatch) => {
    if (ginzaId) {
        const res = await getDiamondVaultEstiApy(ginzaId);
        dispatch({ type: GET_VAULT_ESTI_APY, payload: { data: res, id: vaultId } });
        dispatch({ type: GET_VAULT_TOKEN_PRICE, payload: { data: res, id: vaultId } });
    }
};

export const getRoundsData = (ginzaId, vaultId) => async (dispatch) => {
    if (ginzaId) {
        const res = await getGinzaRoundsData(ginzaId);
        dispatch({ type: GET_VAULT_ROUND_DATA, payload: { data: res, id: vaultId } });
    }
};

export const onApproveWETH = (ethAmount, provider, accountAddress, optionAddress) => async (dispatch) => {
    dispatch({ type: MAKER_APPROVE_WETH_START });
    try {
        const res = await makerApproveWETH(ethAmount, provider, accountAddress, optionAddress);
        dispatch({ type: MAKER_APPROVE_WETH_SUCCEED, payload: { hash: res.hash } });
        await res.wait();
        dispatch({ type: RESET_APPROVE_WETH_HASH });
    } catch (error) {
        dispatch({ type: MAKER_APPROVE_WETH_FAILED, payload: { error } });
    }
};

export const setUserOptUsdcBalance = (connectedAccount, chain, currency) => async (dispatch) => {
    const balance = await getOptUsdcBalance(connectedAccount);
    dispatch({ type: SET_ACCOUNT_BALANCE, payload: { balance, chain, currency } });
};

export const getBasisTradingVaultCapacity = (proxyAddress, vaultId) => async (dispatch) => {
    if (proxyAddress) {
        const capacity = await getCapacityOfBasisTrading(proxyAddress);
        dispatch({ type: GET_VAULT_CAPACITY, payload: { capacity, id: vaultId } });
    }
};

export const getBasisTradingAum = (proxyAddress, vaultId) => async (dispatch) => {
    if (proxyAddress) {
        const balance = await getTotalAssetInBasisTrading(proxyAddress);
        dispatch({ type: GET_VAULT_TOTAL_BALANCE, payload: { balance, id: vaultId } });
    }
};

export const setActiveNetworkChainId = (chainId) => async (dispatch) => {
    dispatch({ type: SET_ACTIVE_NETWORK_CHAINID, payload: chainId });
};
