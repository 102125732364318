import {
    SET_CONNECTED_ACCOUNT,
    SET_ACCOUNT_BALANCE,
    SET_PROVIDER,
    VAULT_DEPOSIT_START,
    VAULT_DEPOSIT_SUCCEED,
    VAULT_DEPOSIT_FAILED,
} from '../actions/actionType';

const initialState = {
    connectedAccount: {
        accountAddress: '',
        chainId: '',
        connectedOption: '',
    },
    accountBalance: {
        main: {
            eth: '',
        },
        opt: {
            usdc: '',
        },
    },
    provider: {},
    vaultDeposit: {
        isProcessing: false,
        isFinished: false,
        errorMsg: '',
    },
    depositHash: '',
};

// eslint-disable-next-line default-param-last
const memberReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONNECTED_ACCOUNT: {
            const { accountAddress, chainId, connectOption } = action.data;
            const deployedChainIds = [1, 10, 31337];
            const chainIsCorrect = deployedChainIds.includes(+chainId);
            return {
                ...state,
                connectedAccount: {
                    accountAddress: chainIsCorrect ? accountAddress : '',
                    chainId: chainIsCorrect ? +chainId : '',
                    connectedOption: chainIsCorrect ? connectOption : '',
                },
            };
        }
        case SET_ACCOUNT_BALANCE: {
            const { balance, chain, currency } = action.payload;
            return {
                ...state,
                accountBalance: {
                    ...state.accountBalance,
                    [chain]: {
                        [currency]: balance,
                    },
                },
            };
        }
        case SET_PROVIDER: {
            const { provider } = action.payload;
            return {
                ...state,
                provider,
            };
        }
        case VAULT_DEPOSIT_START: {
            return {
                ...state,
                vaultDeposit: {
                    isProcessing: true,
                    isFinished: false,
                    errorMsg: '',
                },
            };
        }
        case VAULT_DEPOSIT_SUCCEED: {
            const { hash } = action.payload;
            return {
                ...state,
                vaultDeposit: {
                    isProcessing: false,
                    isFinished: true,
                    errorMsg: '',
                },
                depositHash: hash,
            };
        }
        case VAULT_DEPOSIT_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                vaultDeposit: {
                    isProcessing: false,
                    isFinished: false,
                    errorMsg: error,
                },
            };
        }
        default:
            return state;
    }
};

export default memberReducer;
