/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../styles/Colors';
import { H3, H4 } from '../../styles/typography';
import bear from '../../images/bear.png';
import bull from '../../images/bull.png';
import bullBear from '../../images/bull_bear.png';
import diamond from '../../images/diamond.png';

const VaultCard = (props) => {
    const { vaultConstData } = props;
    const {
        title: vaultTitle, description: vaultDesc, disabled: vaultIsDisabled, icon, metrics, path,
    } = vaultConstData;
    const {
        marketType, leverage, downProtection, termDuration, riskProfile,
    } = metrics;

    const [metricIsShow, setMetricIsShow] = useState(false);

    const getMarketImg = (marketT) => {
        let img;
        switch (marketT) {
            case 'Down Market':
                img = bear;
                break;
            case 'Up Market':
                img = bull;
                break;
            case 'Neutral':
                img = bullBear;
                break;
            default:
        }
        return img;
    };

    const getMarketColor = (marketT) => {
        let color;
        switch (marketT) {
            case 'Down Market':
                color = '#005E00';
                break;
            case 'Up Market':
                color = '#942a0d';
                break;
            case 'Neutral':
                color = '#942a0d';
                break;
            default:
        }
        return color;
    };

    return (
        <Container color={getMarketColor(marketType)}>
            <VaultInfoContainer>
                <RowContainer>
                    <Title>{vaultTitle}</Title>
                    <ImageContainer>
                        <Image
                            src={icon}
                            alt='vault icon'
                        />
                    </ImageContainer>
                </RowContainer>
                <DescText>{vaultDesc}</DescText>
                <DiamondImgContainer>
                    <Image
                        src={diamond}
                        alt='diamond icon'
                    />
                </DiamondImgContainer>
                <Button
                    onClick={() => window.open(path, '_blank', 'noopener,noreferrer')}
                    disabled={vaultIsDisabled}
                >
                    {vaultIsDisabled ? 'COMING SOON' : 'TRY IT FIRST'}
                </Button>
            </VaultInfoContainer>
            {/* {!!accountAddress && (
                <VaultBalanceContainer>
                    <ListItemContainer>
                        <ListItemTitle>Your Balance</ListItemTitle>
                        <ListItemValue>
                            {`${parseFloat(vaultUserBalances[vaultId] || 0).toLocaleString('en', {
                                maximumFractionDigits: 6,
                            })} ${currency}`}
                        </ListItemValue>
                    </ListItemContainer>
                </VaultBalanceContainer>
            )} */}
            <MetricsContainer>
                <MetricTitleRow>
                    {metricIsShow ? (
                        <MarketRowContainer>
                            <MarketImg src={getMarketImg(marketType)} />
                            <MetricTitle>{marketType}</MetricTitle>
                        </MarketRowContainer>
                    ) : (
                        <MetricTitle>Vault Characteristics</MetricTitle>
                    )}
                    <ArrowIcon
                        icon={faAngleDown}
                        isShow={metricIsShow}
                        disabled={!marketType}
                        onClick={() => marketType && setMetricIsShow(!metricIsShow)}
                    />
                </MetricTitleRow>
                <MetricInfoContainer isShow={metricIsShow}>
                    <div>
                        <MetricInfoLabel>Downside Protection</MetricInfoLabel>
                        <MetricInfoValue>{downProtection}</MetricInfoValue>
                    </div>
                    <div>
                        <MetricInfoLabel>Risk & Appetite</MetricInfoLabel>
                        <MetricInfoValue>{riskProfile}</MetricInfoValue>
                    </div>
                    <div>
                        <MetricInfoLabel>Term Duration</MetricInfoLabel>
                        <MetricInfoValue>{termDuration}</MetricInfoValue>
                    </div>
                    <div>
                        <MetricInfoLabel>Leverage</MetricInfoLabel>
                        <MetricInfoValue>{leverage}</MetricInfoValue>
                    </div>
                </MetricInfoContainer>
            </MetricsContainer>
        </Container>
    );
};

VaultCard.propTypes = {
    vaultConstData: PropTypes.object,
};

VaultCard.defaultProps = {
    vaultConstData: {},
};

export default VaultCard;

const Container = styled.div`
    flex: 0 0 320px;
    border-radius: 16px;
    border: 2px solid ${Colors.lightKhaki};
    background-color: rgba(0, 61, 69, 0.9);
    padding: 0 0 20px 0;
    background: ${(props) => `linear-gradient(to bottom, #003d45 75%, ${props.color} 100%)`};
    @media (max-width: 768px) {
        max-width: 350px;
    }
`;

const VaultInfoContainer = styled.div`
    display: grid;
    grid-template-rows: 1.2fr 1fr 1.5fr 0.9fr;
    padding: 18px 24px 0px 24px;
    align-items: flex-start;
    @media (max-width: 992px) {
        width: 100%;
        max-width: none;
    }
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding: 24px 24px 0px 24px;
    }
`;

const RowContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled(H3)`
    color: ${Colors.lightKhaki};
    font-weight: bold;
    font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    font-size: 26px;
    line-height: 34px;
    @media (max-width: 768px) {
        font-size: 24px;
        line-height: 32px;
    }
`;

const ImageContainer = styled.div`
    height: 48px;
    position: relative;
    margin-left: 8px;
    @media (max-width: 768px) {
        height: 40px;
    }
`;

const Image = styled.img`
    height: 100%;
    object-fit: contain;
`;

const DescText = styled(H4)`
    width: 100%;
    color: ${Colors.ivory};
    margin: 4px 0 12px 0;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
        margin: 16px 0 12px 0;
    }
`;

const Button = styled.button`
    width: 100%;
    max-height: 52px;
    padding: 8px;
    font-size: 22px;
    line-height: 26px;
    font-family: Iceberg, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    font-weight: 600;
    text-align: center;
    border-radius: 8px;
    margin: 12px 0;
    opacity: ${(props) => (props.disabled ? '0.7' : '1')};
    background-color: ${(props) => (props.disabled ? Colors.midNightGreen : Colors.lightKhaki)};
    color: ${(props) => (props.disabled ? Colors.lightKhaki : Colors.midNightGreen)};
    border: ${(props) => (props.disabled ? `1px solid ${Colors.lightKhaki}` : `1px solid ${Colors.lightKhaki}`)};
    :hover {
        background-color: ${(props) => (props.disabled ? Colors.midNightGreen : Colors.ivory)};
        border: ${(props) => (props.disabled ? `1px solid ${Colors.lightKhaki}` : `1px solid ${Colors.ivory}`)};
    }
    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 26px;
        padding: 6px;
    }
`;

const MetricsContainer = styled.div`
    width: 100%;
    margin-top: 18px;
    padding: 18px 24px 0 24px;
    border-top: 1px solid ${Colors.lightKhaki};
`;

const MetricTitleRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 500ms ease all;
`;

const MetricTitle = styled(H4)`
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    text-align: left;
    color: ${Colors.lightKhaki};
`;

const ArrowIcon = styled(FontAwesomeIcon)`
    color: ${Colors.lightKhaki};
    font-size: 20px;
    cursor: ${(props) => !props.disabled && 'pointer'};
    transform: ${(props) => props.isShow && 'rotate(180deg)'};
    opacity: ${(props) => props.disabled && '0.5'};
`;

const MarketRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MarketImg = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 12px;
`;

const MetricInfoContainer = styled.div`
    max-height: ${(props) => (props.isShow ? '300px' : '0px')};
    transition: 500ms ease all;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
`;

const MetricInfoLabel = styled(H4)`
    font-size: 11px;
    line-height: 14px;
    color: ${Colors.ivory};
    margin-top: 12px;
`;

const MetricInfoValue = styled(H4)`
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: ${Colors.lightKhaki};
    margin-top: 4px;
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 22px;
    }
`;

const DiamondImgContainer = styled.div`
    width: 100%;
    height: 80px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
        height: 60px;
        margin: 20px 0 20px 0;
    }
`;
