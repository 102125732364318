import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../styles/Colors';
import VaultCard from './VaultCard';
import background from '../../images/background.png';

import {
    getVaultAum, getVaultTxData, getVaultApy, getBasisTradingAum,
} from '../../redux/actions/actionCreater';
import { vaultConstData } from '../../utils/constant/vaults';
import { H1, H4 } from '../../styles/typography';
import OptInfoModal from '../../components/common/OptInfoModal';

const HomePage = () => {
    const vaultTotalBalances = useSelector((state) => state.vault.vaultTotalBalances);
    const vaultUserBalances = useSelector((state) => state.vault.vaultUserBalances);
    const vaultApys = useSelector((state) => state.vault.vaultApys);

    const ethVaults = vaultConstData.filter((vault) => vault.type === 'ethVault');
    const lpVaults = vaultConstData.filter((vault) => vault.type === 'lpVault');

    const [optInfoIsShow, setOptInfoIsShow] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        vaultConstData.forEach((vault) => {
            const {
                ginzaId, id: vaultId, contractAddress, type,
            } = vault;
            // Get Vaults AUM
            if (type === 'ethVault') {
                dispatch(getVaultAum(contractAddress, vaultId));
                // Get Vaults Tx Data
                dispatch(getVaultTxData(ginzaId, 'time desc', vaultId));
                dispatch(getVaultTxData(ginzaId, 'time asc', vaultId));
            } else if (type === 'lpVault') {
                dispatch(getBasisTradingAum(contractAddress, vaultId));
            }
            // get Vaults APY
            dispatch(getVaultApy(ginzaId, vaultId));
        });
    }, []);

    return (
        <Container>
            <BackgroundImage
                src={background}
                alt='background image'
            />
            <AlertContainer>
                <FontAwesomeIcon
                    icon={faExclamationCircle}
                    color='#597a52'
                />
                <AlertText>Diamond Protocol is still under beta testing. Please use at your own risk.</AlertText>
            </AlertContainer>
            <CategoryContainer>
                <CategoryTitle>ETH Vault</CategoryTitle>
                <VaultsContainer>
                    {ethVaults.map((vault) => (
                        <VaultCard
                            key={vault.id}
                            vaultConstData={vault}
                            totalBalance={vaultTotalBalances[vault.id]}
                            userBalance={vaultUserBalances[vault.id]}
                            vaultApy={vaultApys[vault.id]}
                        />
                    ))}
                </VaultsContainer>
            </CategoryContainer>
            <CategoryContainer>
                <CategoryTitle>USDC LP Vault</CategoryTitle>
                <VaultsContainer>
                    {lpVaults.map((vault) => (
                        <VaultCard
                            key={vault.id}
                            vaultConstData={vault}
                            totalBalance={vaultTotalBalances[vault.id]}
                            vaultApy={vaultApys[vault.id]}
                            openOptInfoModal={() => setOptInfoIsShow(true)}
                        />
                    ))}
                </VaultsContainer>
            </CategoryContainer>
            <OptInfoModal
                isOpen={optInfoIsShow}
                handleClose={() => {
                    setOptInfoIsShow(false);
                }}
            />
        </Container>
    );
};

export default HomePage;

const Container = styled.div`
    width: 100%;
    background-color: ${Colors.midNightGreen};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0 80px 0;
    position: relative;
    overflow: hidden;
    @media (max-width: 992px) {
        padding: 24px 0;
    }
`;

const BackgroundImage = styled.img`
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
`;

const CategoryContainer = styled.div`
    width: 100%;
    max-width: 1020px;
    margin-top: 72px;
    position: relative;
    @media (max-width: 992px) {
        width: 90%;
        margin-top: 60px;
    }
`;

const CategoryTitle = styled(H1)`
    font-size: 42px;
    line-height: 55px;
    margin-bottom: 40px;
    font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    text-align: left;
    color: ${Colors.ivory};
    @media (max-width: 1200px) {
        text-align: center;
    }
    @media (max-width: 992px) {
        font-size: 36px;
        line-height: 48px;
        margin-bottom: 32px;
    }
    @media (max-width: 768px) {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 28px;
    }
`;

const VaultsContainer = styled.div`
    display: flex;
    grid-gap: 28px;
    align-items: flex-start;
    flex-wrap: wrap;
    @media (max-width: 1200px) {
        grid-gap: 32px;
        justify-content: center;
    }
    @media (max-width: 992px) {
        grid-gap: 18px;
    }
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const AlertContainer = styled.div`
    padding: 12px 24px;
    border: 1px solid ${Colors.midNightGreen};
    border-radius: 12px;
    background-color: #d2f2a6;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 768px) {
        width: 90%;
        max-width: 500px;
        padding: 12px 12px;
    }
`;

const AlertText = styled(H4)`
    color: ${Colors.midNightGreen};
    line-height: 24px;
    font-size: 14px;
    line-height: 20px;
    margin-left: 8px;
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 18px;
    }
`;
