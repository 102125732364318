/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import 'react-multi-carousel/lib/styles.css';
import StrategyComponent from '../StrategyComponent';
import Graph from '../../../components/common/Graph';
import strategyImage from '../../../images/eth_bull_vault_strategy.gif';

const Strategy = () => {
    const slider1 = {
        image: <Image src={strategyImage} />,
        title: 'Diamond ETH Bull Vault',
        text: 'Every week, the vault receives ETH from investors and deploy 100% into cover call option strategies. Before end of Saturday of each week, the vault mints European ETH call options by depositing its ETH balance as collateral in an Opyn vault. Strike price is set by managers of the vault and the expiry date is set to the following Friday. In return, the vault receives option premiums as profits.',
    };

    const slider2 = {
        image: <Graph priceType='below' />,
        title: '',
        text: 'Spot price ends below strike price at contract expiration, vault profits earned and retained.',
    };

    const slider3 = {
        image: <Graph priceType='within' />,
        title: '',
        text: 'Spot price ends above strike price at contract expiration, but profits earned is enough to cover the shortfall, vault still returns net positive profits.',
    };

    const slider4 = {
        image: <Graph priceType='above' />,
        title: '',
        text: 'Spot price ends above strike price at contract expiration, profits for this term is negative. ETH are taken out of the vault to pay call option buyers.',
    };

    return (
        <StrategyComponent
            title='Vault Strategy'
            desc='ETH Bull vault earns yield by deploying all ETH deposits in a ETH Covered Call strategy. Deposits are welcome at any time and will be deployed every Saturday. Withdrawal can be done every Friday from 16:00 to 22:00 (GMT+8).'
            sliderComponents={[slider1, slider2, slider3, slider4]}
        />
    );
};

export default Strategy;

const Image = styled.img`
    border-radius: 8px;
    height: 100%;
`;
