/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CoveredCallGraph from '../../../components/common/CoveredCallGraph';
import Colors from '../../../styles/Colors';
import { getETHPrice, getExpiryTimestamp } from '../../../utils/diamond';
import OptionABI from '../../../ABI/option.json';

import { H3, H4 } from '../../../styles/typography';

const Snapshot = (props) => {
    const { txDataDesc, vaultConstData } = props;
    const { optionAddress } = vaultConstData;

    const [options, setOptions] = useState([]);
    const [currMarketPrice, setCurrMarketPrice] = useState(0);
    const [timeToExpiry, setTimeToExpiry] = useState({ day: 0, hour: 0, minute: 0 });
    const [timeToExpiryIsValid, setTimeToExpiryIsValid] = useState(false);
    const [totalPremium, setTotalPremium] = useState(0);

    const getCurrMarketPrice = async () => {
        const price = await getETHPrice();
        setCurrMarketPrice(price);
    };

    const getOptionsData = (txData) => {
        const latestTimestamp = txData.rows[0]?.data?.timeStamp;
        const fetchedTx = txData.rows.filter((item) => item.data.timeStamp === latestTimestamp);
        const parsedOptions = fetchedTx.map((item) => ({
            strikePrice: parseFloat(item.data.strikePrice),
            subTotalPremium: parseFloat(item.data.yield),
            amount: parseFloat(item.data.quantity),
        }));
        parsedOptions.sort((a, b) => a.strikePrice - b.strikePrice);
        setOptions(parsedOptions);
        return parsedOptions;
    };

    const getTotlaPremium = (txData) => {
        const latestTimestamp = txData.rows[0]?.data?.timeStamp;
        const fetchedTx = txData.rows.filter((item) => item.data.timeStamp === latestTimestamp);
        const premium = fetchedTx.reduce((pre, curr) => pre + parseFloat(curr.data.yield), 0);
        setTotalPremium(premium);
    };

    const getStrikePriceWord = (optionArray) => {
        let text;
        if (options.length > 0) {
            text = `$${optionArray[0].strikePrice.toLocaleString()}`;
            optionArray.slice(1, optionArray.length).forEach((item) => {
                text += ` & $${item.strikePrice.toLocaleString()}`;
            });
        }
        return text;
    };

    const getTimeToExpiry = async (optAddress) => {
        const expiryTimestamp = parseFloat(await getExpiryTimestamp(optAddress));
        const currTimestamp = Math.floor(Date.now() / 1000);

        let restTime = parseFloat(expiryTimestamp) - currTimestamp;
        if (restTime > 0) {
            const restDay = Math.floor(restTime / (60 * 60 * 24));

            restTime -= 60 * 60 * 24 * restDay;
            const restHour = Math.floor(restTime / (60 * 60));

            restTime -= 60 * 60 * restHour;
            const restMinute = Math.floor(restTime / 60);

            setTimeToExpiryIsValid(true);
            setTimeToExpiry({ day: restDay, hour: restHour, minute: restMinute });
        }
    };

    useEffect(() => {
        getCurrMarketPrice();
    }, []);

    useEffect(() => {
        if (txDataDesc?.total_rows > 1) {
            getOptionsData(txDataDesc);
            getTotlaPremium(txDataDesc);
        }
    }, [txDataDesc]);

    useEffect(() => {
        if (optionAddress && OptionABI) {
            getTimeToExpiry(optionAddress);
        }
    }, [optionAddress, OptionABI]);

    return (
        <Container>
            <Title>Weekly Strategy Snapshot</Title>
            <MainContainer>
                <GraphContainer>
                    <CoveredCallGraph
                        width='100%'
                        height='250px'
                        xLabel='ETH Price'
                        yLabel='Profit'
                        strikePriceLabel='Strike Price:'
                        optionData={options}
                        currMarketPrice={currMarketPrice}
                    />
                </GraphContainer>
                <InfoContainer>
                    <InfoItem>
                        <InfoTitle>Current ETH Price</InfoTitle>
                        <InfoValue>
                            {currMarketPrice > 0 ? `$${currMarketPrice.toLocaleString('en', { maximumFractionDigits: 2 })}` : '-'}
                        </InfoValue>
                    </InfoItem>
                    <InfoItem>
                        <InfoTitle>Selected ETH Strike Price</InfoTitle>
                        <InfoValue>{options.length > 0 ? getStrikePriceWord(options) : '-'}</InfoValue>
                    </InfoItem>
                    <InfoItem>
                        <InfoTitle>This Week’s Premium</InfoTitle>
                        <InfoValue>
                            {totalPremium ? `${totalPremium.toLocaleString('en', { maximumFractionDigits: 6 })} ETH` : '-'}
                        </InfoValue>
                    </InfoItem>
                    <InfoItem>
                        <InfoTitle>Time to Expiry</InfoTitle>
                        <InfoValue>
                            {timeToExpiryIsValid ? `${timeToExpiry.day}D ${timeToExpiry.hour}H ${timeToExpiry.minute}M` : 'Expired'}
                        </InfoValue>
                    </InfoItem>
                </InfoContainer>
            </MainContainer>
        </Container>
    );
};

Snapshot.propTypes = {
    txDataDesc: PropTypes.object,
    vaultConstData: PropTypes.object,
};

Snapshot.defaultProps = {
    txDataDesc: {},
    vaultConstData: {},
};

export default Snapshot;

const Container = styled.div`
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        max-width: none;
    }
`;

const Title = styled(H3)`
    color: ${Colors.ivory};
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 40px;
    font-weight: bold;
    font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    @media (max-width: 992px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 24px;
    }
    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 20px;
    }
`;

const MainContainer = styled.div`
    width: 100%;
    padding: 16px 24px;
    background-color: rgba(0, 0, 0, 0.3);
    border-width: 0;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const GraphContainer = styled.div`
    width: 100%;
    padding: 12px 0;
    border-bottom: 1px solid gray;
    @media (max-width: 768px) {
        padding: 0;
    }
`;

const InfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 32px 0px 0px 0px;
`;

const InfoItem = styled.div`
    width: 50%;
    margin-bottom: 24px;
`;

const InfoTitle = styled(H4)`
    color: ${Colors.ivory};
    font-size: 12px;
    line-height: 16px;
`;

const InfoValue = styled(H4)`
    color: ${Colors.ivory};
    margin-top: 4px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
`;
