/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */

import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PropTypes from 'prop-types';
import Colors from '../../styles/Colors';
import { H2, H3, H4 } from '../../styles/typography';

const StrategyComponent = (props) => {
    const {
        title, desc, sliderComponents, hyperLinkTextComponent,
    } = props;

    const responsive = {
        desktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    return (
        <Container>
            <Title>{title}</Title>
            <VaultDesc>{desc}</VaultDesc>
            {hyperLinkTextComponent}
            <SlidesContainer>
                <Carousel
                    responsive={responsive}
                    showDots
                    removeArrowOnDeviceType={['desktop', 'tablet', 'mobile']}
                    autoPlay={false}
                    autoPlaySpeed={10000}
                >
                    {sliderComponents.map((slider, index) => (
                        <SlideContainer key={index}>
                            <ImageContainer>{slider.image}</ImageContainer>
                            <TextContainer>
                                <SlideSubTitle>{slider.title}</SlideSubTitle>
                                <SlideText>{slider.text}</SlideText>
                            </TextContainer>
                        </SlideContainer>
                    ))}
                </Carousel>
            </SlidesContainer>
        </Container>
    );
};

StrategyComponent.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    sliderComponents: PropTypes.array,
    hyperLinkTextComponent: PropTypes.element,
};

StrategyComponent.defaultProps = {
    title: '',
    desc: '',
    sliderComponents: [],
    hyperLinkTextComponent: null,
};

export default StrategyComponent;

const Container = styled.div`
    width: 100%;
    max-width: 500px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        max-width: none;
    }
`;

const Title = styled(H3)`
    color: ${Colors.ivory};
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 40px;
    font-weight: bold;
    font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    @media (max-width: 992px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 24px;
    }
    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 20px;
    }
`;

const VaultDesc = styled(H4)`
    display: inline;
    color: ${Colors.ivory};
    font-size: 14px;
    line-height: 22px;
    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const SlidesContainer = styled.div`
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 24px 0px;
    margin-top: 24px;
    .react-multi-carousel-dot--active button {
        background: ${Colors.gray3};
    }
`;

const ImageContainer = styled.div`
    width: 100%;
    height: 180px;
    background-color: #0c3e45;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    @media (max-width: 992px) {
        height: 170px;
    }
    @media (max-width: 768px) {
        height: 150px;
    }
`;

const Image = styled.img`
    border-radius: 8px;
    height: 100%;
`;

const TextContainer = styled.div`
    margin-top: 24px;
`;

const SlideContainer = styled.div`
    padding: 0 24px 32px 24px;
`;

const SlideSubTitle = styled(H4)`
    color: ${Colors.ivory};
    font-weight: bold;
    margin-bottom: 12px;
    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const SlideText = styled(H4)`
    color: ${Colors.ivory};
    font-size: 14px;
    line-height: 22px;
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;
