import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Colors from '../../../styles/Colors';

import Title from '../TitleComponent';
import ActionColumn from './ActionColumn';
import Performance from '../PerformanceComponent';
import Snapshot from './Snapshot';
import Strategy from './Strategy';
import Transaction from './Transaction';
import Disclaimer from '../DisclaimerComponent';
import PayPremium from './PayPremuim';
import FeeStructure from './FeeStructure';

import {
    getVaultAum,
    getEthVaultUserBalance,
    getVaultCapacity,
    getVaultTxData,
    getVaultStatus,
    getVaultApy,
    getRoundsData,
    setActiveNetworkChainId,
} from '../../../redux/actions/actionCreater';
import { vaultConstData } from '../../../utils/constant/vaults';
import { optionVaultDisclaimers } from '../../../utils/constant/disclaimer';

const VaultInfoPage = () => {
    const provider = useSelector((state) => state.member.provider);
    const accountAddress = useSelector((state) => state.member.connectedAccount.accountAddress);
    const connectedAccount = useSelector((state) => state.member.connectedAccount);
    const vaultCapacities = useSelector((state) => state.vault.vaultCapacities);
    const vaultTotalBalances = useSelector((state) => state.vault.vaultTotalBalances);
    const vaultUserBalances = useSelector((state) => state.vault.vaultUserBalances);
    const vaultTxDataDescs = useSelector((state) => state.vault.vaultTxDataDescs);
    const vaultIsUnlocked = useSelector((state) => state.vault.vaultIsUnlocked);
    const vaultApys = useSelector((state) => state.vault.vaultApys);
    const vaultTokenPriceDiffs = useSelector((state) => state.vault.vaultTokenPriceDiffs);
    const vaultRounds = useSelector((state) => state.vault.vaultRounds);

    const [matchedVaultConstData, setMatchedVaultConstData] = useState({});

    const dispatch = useDispatch();
    const location = useLocation();

    const pathName = location.pathname;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const data = vaultConstData.filter((vault) => pathName === vault.path)?.[0];
        setMatchedVaultConstData(data);
    }, []);

    // Get User Balance in Vault
    useEffect(() => {
        const { contractAddress, id: vaultId } = matchedVaultConstData;
        if (Object.keys(provider).length > 0 && accountAddress && contractAddress) {
            dispatch(getEthVaultUserBalance(contractAddress, connectedAccount, vaultId));
        }
    }, [provider, accountAddress, matchedVaultConstData]);

    // Get Vault Info From Blockchain
    useEffect(() => {
        const { contractAddress, id } = matchedVaultConstData;
        if (contractAddress) {
            dispatch(getVaultAum(contractAddress, id));
            dispatch(getVaultCapacity(contractAddress, id));
            dispatch(getVaultStatus(contractAddress, id));
        }
    }, [matchedVaultConstData]);

    // Get Vault Info from Ginza
    useEffect(() => {
        const { ginzaId, id: vaultId, chainId } = matchedVaultConstData;
        if (chainId) {
            dispatch(setActiveNetworkChainId(chainId.toString()));
        }
        if (ginzaId) {
            dispatch(getVaultTxData(ginzaId, 'time desc', vaultId));
            dispatch(getVaultTxData(ginzaId, 'time asc', vaultId));
            dispatch(getVaultApy(ginzaId, vaultId));
            dispatch(getRoundsData(ginzaId, vaultId));
        }
    }, [matchedVaultConstData]);

    return (
        <Container>
            <Row>
                <Title
                    vaultConstData={matchedVaultConstData}
                    capacity={vaultCapacities[matchedVaultConstData.id]}
                    totalBalance={vaultTotalBalances[matchedVaultConstData.id]}
                    vaultApy={vaultApys[matchedVaultConstData.id]}
                />
            </Row>
            <Row>
                <LeftContainer>
                    <Performance
                        vaultApy={vaultApys[matchedVaultConstData.id]}
                        tokenPriceDiff={vaultTokenPriceDiffs[matchedVaultConstData.id]}
                        vaultRound={vaultRounds[matchedVaultConstData.id]}
                    />
                    <Snapshot
                        vaultConstData={matchedVaultConstData}
                        txDataDesc={vaultTxDataDescs[matchedVaultConstData.id]}
                    />
                    <Strategy />
                    <FeeStructure vaultConstData={matchedVaultConstData} />
                </LeftContainer>
                <RightContainer>
                    <ActionColumn
                        vaultConstData={matchedVaultConstData}
                        userBalance={vaultUserBalances[matchedVaultConstData.id]}
                        isUnlocked={vaultIsUnlocked[matchedVaultConstData.id]}
                    />
                </RightContainer>
            </Row>
            <Section>
                <Transaction
                    vaultConstData={matchedVaultConstData}
                    txDataDesc={vaultTxDataDescs[matchedVaultConstData.id]}
                />
            </Section>
            <Section>
                <Disclaimer disclaimerList={optionVaultDisclaimers} />
            </Section>
            <Section>
                <PayPremium
                    vaultConstData={matchedVaultConstData}
                    userBalance={vaultUserBalances[matchedVaultConstData.id]}
                    isUnlocked={vaultIsUnlocked[matchedVaultConstData.id]}
                />
            </Section>
        </Container>
    );
};

export default VaultInfoPage;

const Container = styled.div`
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    min-height: calc(100vh - 150px);
    background-color: ${Colors.midNightGreen};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 24px 80px 24px;
    @media (max-width: 768px) {
        padding: 30px 16px 60px 16px;
    }
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const Section = styled(Row)`
    margin-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        margin-top: 70px;
    }
`;

const LeftContainer = styled.div`
    width: calc(100% - 350px);
    margin-top: 60px;
    display: grid;
    grid-gap: 60px;
    overflow: hidden;
    @media (max-width: 768px) {
        width: 100%;
        max-width: none;
    }
`;

const RightContainer = styled.div`
    width: 350px;
    margin-left: 24px;
    margin-top: 70px;
    padding: 24px;
    border: 1px solid ${Colors.lightKhaki};
    border-radius: 16px;
    @media (max-width: 768px) {
        width: 100%;
        margin-left: 0px;
        margin-top: 40px;
        order: -1;
    }
`;
