/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Button from '../../../components/common/Button';
import networks from '../../../utils/constant/networks';

import { H3, H4 } from '../../../styles/typography';
import Colors from '../../../styles/Colors';
import {
    onDeposit,
    getVaultAum,
    onWithdrawETH,
    setSelectNetworkModalStatus,
    setUserMainEthBalance,
    getEthVaultUserBalance,
} from '../../../redux/actions/actionCreater';
import { getEstiGasFee, waitForTransaction } from '../../../utils/diamond';
import { alertText } from '../../../components/common/Toast';

const ActionColumn = (props) => {
    const { vaultConstData, userBalance, isUnlocked } = props;
    const {
        contractAddress: vaultContractAddress, currency, id: vaultId, estiMaxDepositGas, chainId: deployedChainId,
    } = vaultConstData;

    const [selectedTab, setSelectedTab] = useState(0);
    const [ethAmount, setEthAmount] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isWithdrawAll, setIsWithdrawAll] = useState(false);
    const [estiGasFee, setEstiGasFee] = useState(0);

    const accountBalance = useSelector((state) => state.member.accountBalance);
    const provider = useSelector((state) => state.member.provider);
    const connectedAccount = useSelector((state) => state.member.connectedAccount);
    const accountAddress = useSelector((state) => state.member.connectedAccount.accountAddress);
    const chainId = useSelector((state) => state.member.connectedAccount.chainId);
    const depositHash = useSelector((state) => state.vault.depositHash);
    const withdrawHash = useSelector((state) => state.vault.withdrawHash);
    const depositIsProcessing = useSelector((state) => state.vault.vaultDeposit.isProcessing);
    const withdrawIsProcessing = useSelector((state) => state.vault.vaultWithdraw.isProcessing);

    const dispatch = useDispatch();

    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';
    const isUnderCorrectNetwork = isProduction ? +chainId === deployedChainId : +chainId === 31337;
    const ethBalance = accountBalance.main.eth;

    const handleTabSwitch = async (tabName) => {
        setSelectedTab(tabName);
        setEthAmount('');
        setErrorMsg('');
    };

    const handleConnectWallet = async () => {
        dispatch(setSelectNetworkModalStatus({ status: true }));
    };

    const handleDeposit = async () => {
        if (!isUnderCorrectNetwork) {
            setErrorMsg(`Please use ${networks[isProduction ? deployedChainId : 31337]} network`);
        } else {
            const amount = parseFloat(ethAmount).toString();
            await dispatch(onDeposit(amount, provider, accountAddress, vaultContractAddress));
            setEthAmount('');
        }
    };

    const handleWithdraw = async () => {
        if (!isUnderCorrectNetwork) {
            setErrorMsg(`Please use ${networks[isProduction ? deployedChainId : 31337]} network`);
        } else {
            const amount = parseFloat(ethAmount).toString();
            await dispatch(onWithdrawETH(amount, provider, accountAddress, vaultContractAddress, isWithdrawAll));
            setEthAmount('');
        }
    };

    const updateAllBalance = async () => {
        dispatch(getVaultAum(vaultContractAddress, vaultId));
        dispatch(getEthVaultUserBalance(vaultContractAddress, connectedAccount, vaultId));
        dispatch(setUserMainEthBalance(connectedAccount, 'main', 'eth'));
    };

    const showDepositPendingAlert = async (hash) => {
        const id = toast.loading(alertText(`Transaction hash ${hash} is processing...`));
        await waitForTransaction(provider, hash);
        toast.update(id, {
            render: alertText('Deposit ETH Succeed!'),
            type: 'success',
            isLoading: false,
            autoClose: 5000,
        });
        updateAllBalance();
    };

    const showWithdrawPendingAlert = async (hash) => {
        const id = toast.loading(alertText(`Transaction hash ${hash} is processing...`));
        await waitForTransaction(provider, hash);
        toast.update(id, {
            render: alertText('Withdraw ETH Succeed!'),
            type: 'success',
            isLoading: false,
            autoClose: 5000,
        });
        updateAllBalance();
    };

    useEffect(() => {
        if (depositHash) {
            showDepositPendingAlert(depositHash);
        }
    }, [depositHash]);

    useEffect(() => {
        if (withdrawHash) {
            showWithdrawPendingAlert(withdrawHash);
        }
    }, [withdrawHash]);

    const isValidNumber = (n) => !Number.isNaN(parseFloat(n)) && !Number.isNaN(n - 0) && parseFloat(n) > 0;

    const handleMaxAmount = async () => {
        if (selectedTab === 0) {
            setEthAmount(ethBalance > estiGasFee ? ethBalance - estiGasFee : ethBalance);
        } else {
            setEthAmount(userBalance);
            setIsWithdrawAll(true);
        }
    };

    const renderDepositBtn = () => (
        <Button
            onClick={handleDeposit}
            disabled={!isValidNumber(ethAmount) || depositIsProcessing}
            btnType='primary'
            btnText={depositIsProcessing ? 'PROCESSING...' : `DEPOSIT ${currency}`}
        />
    );

    const renderWithdrawBtn = () => (
        <Button
            onClick={handleWithdraw}
            disabled={!isValidNumber(ethAmount) || withdrawIsProcessing}
            btnType='primary'
            btnText={withdrawIsProcessing ? 'PROCESSING...' : 'WITHDRAW ETH'}
        />
    );

    const renderActionBtn = () => {
        if (accountAddress && isUnderCorrectNetwork) {
            return (
                <>
                    {selectedTab === 0 && renderDepositBtn()}
                    {selectedTab !== 0 && renderWithdrawBtn()}
                </>
            );
        }
        if (accountAddress && !isUnderCorrectNetwork) {
            return (
                <Button
                    onClick={handleConnectWallet}
                    btnType='primary'
                    btnText='SWITCH NETWORK'
                    disabled={false}
                />
            );
        }
        return (
            <Button
                onClick={handleConnectWallet}
                btnType='primary'
                btnText='CONNECT WALLET'
                disabled={false}
            />
        );
    };

    useEffect(() => {
        const getEstiGas = async () => {
            const gas = await getEstiGasFee(estiMaxDepositGas);
            setEstiGasFee(gas);
        };

        if (estiMaxDepositGas) {
            getEstiGas();
        }
    }, [estiMaxDepositGas]);

    return (
        <Container>
            <RowContainer>
                <PositionContainer>
                    <InfoTitle>Your Position</InfoTitle>
                    <InfoText>
                        {`${
                            isUnderCorrectNetwork ? parseFloat(userBalance).toLocaleString('en', { maximumFractionDigits: 6 }) : '-'
                        } ${currency}`}
                    </InfoText>
                </PositionContainer>
            </RowContainer>
            <TabsContainer>
                <Tab
                    isSelected={selectedTab === 0}
                    onClick={() => handleTabSwitch(0)}
                >
                    Deposit
                </Tab>
                <Tab
                    isSelected={selectedTab !== 0}
                    onClick={() => handleTabSwitch(1)}
                >
                    Withdraw
                </Tab>
            </TabsContainer>
            {!isUnlocked && selectedTab !== 0 ? (
                <LockedHintContainer>
                    <LockedHint>Vault is locked currently</LockedHint>
                </LockedHintContainer>
            ) : (
                <>
                    <InputLabelRowContainer>
                        <InputLabel>Amount</InputLabel>
                        {!!accountAddress && (
                            <MaxBtn
                                onClick={handleMaxAmount}
                                disabled={(selectedTab === 0 && !(estiGasFee > 0)) || (selectedTab !== 0 && !userBalance)}
                            >
                                Max
                            </MaxBtn>
                        )}
                    </InputLabelRowContainer>
                    <InputContainer>
                        <Input
                            type='number'
                            placeholder='0'
                            value={ethAmount}
                            onChange={(e) => {
                                setIsWithdrawAll(false);
                                setEthAmount(e.target.value);
                            }}
                            disabled={vaultConstData.disabled}
                        />
                    </InputContainer>
                </>
            )}
            <BtnContainer>
                {renderActionBtn()}
                {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
            </BtnContainer>
            <BalanceContainer>
                <BalanceTitle>Wallet Balance</BalanceTitle>
                <BalanceValue>
                    {`${ethBalance ? parseFloat(ethBalance).toLocaleString(undefined, { maximumFractionDigits: 4 }) : '-'} ${currency}`}
                </BalanceValue>
            </BalanceContainer>
        </Container>
    );
};

ActionColumn.propTypes = {
    vaultConstData: PropTypes.object,
    userBalance: PropTypes.string,
    isUnlocked: PropTypes.bool,
};

ActionColumn.defaultProps = {
    vaultConstData: {},
    userBalance: '0',
    isUnlocked: null,
};

export default ActionColumn;

const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

const RowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

const PositionContainer = styled.div`
    flex: 1;
`;

const InfoTitle = styled(H4)`
    color: ${Colors.ivory};
    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const InfoText = styled(H3)`
    color: ${Colors.ivory};
    font-weight: bold;
    @media (max-width: 992px) {
        font-size: 20px;
        line-height: 32px;
    }
    @media (max-width: 768px) {
        margin-top: 4px;
        font-size: 16px;
        line-height: 24px;
    }
`;

const TabsContainer = styled.div`
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 12px;
    @media (max-width: 992px) {
        margin-top: 6px;
    }
`;

const Tab = styled(H4)`
    color: ${(props) => (props.isSelected ? Colors.ivory : Colors.lightKhaki)};
    font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
    padding: 4px 0px;
    flex: 1;
    text-align: center;
    border-bottom: ${(props) => (props.isSelected ? `2px solid ${Colors.ivory}` : `1px solid ${Colors.lightKhaki}`)};
    cursor: ${(props) => (props.isSelected ? 'inherit' : 'pointer')};
    font-size: 20px;
    line-height: 32px;
    font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    @media (max-width: 992px) {
        font-size: 16px;
        line-height: 24px;
    }
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

const InputContainer = styled.div`
    width: 100%;
    margin-top: 12px;
    padding: 4px;
    background-color: ${Colors.gray3};
    border-radius: 8px;
`;

const Input = styled.input`
    width: 100%;
    font-size: 32px;
    line-height: 44px;
    font-weight: bold;
    padding: 4px 8px;
    background-color: inherit;
    border: none;
    :focus {
        border: none;
        outline: none;
    }
    @media (max-width: 768px) {
        font-size: 28px;
        line-height: 40px;
        padding: 2px 6px;
    }
`;

const LockedHintContainer = styled.div`
    margin-top: 8px;
    padding: 4px;
    width: 100%;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        padding: 4px;
        min-height: 52px;
    }
`;

const LockedHint = styled(H4)`
    color: ${Colors.ivory};
    font-size: 18px;
    line-height: 24px;
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

const BtnContainer = styled.div`
    width: 100%;
    margin-top: 24px;
    @media (max-width: 768px) {
        margin-top: 16px;
    }
`;

const BalanceContainer = styled(RowContainer)`
    margin-top: 24px;
    @media (max-width: 768px) {
        margin-top: 16px;
    }
`;

const BalanceTitle = styled(H4)`
    color: ${Colors.ivory};
    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const BalanceValue = styled(BalanceTitle)`
    text-align: right;
`;

const ErrorMsg = styled(H4)`
    color: ${Colors.danger.default};
    margin-top: 12px;
`;

const InputLabelRowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
`;

const InputLabel = styled(H4)`
    color: ${Colors.ivory};
    width: 100%;
    margin-left: 4px;
    font-size: 14px;
    line-height: 18px;
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const MaxBtn = styled.button`
    font-size: 13px;
    line-height: 16px;
    color: ${Colors.gray2};
    padding: 2px 8px;
    border: 1px solid ${Colors.gray2};
    border-radius: 4px;
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
