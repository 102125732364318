/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../styles/Colors';
import { H3, H4 } from '../../styles/typography';
import ethImg from '../../images/label_ethereum.png';
import optImg from '../../images/label_optimisim.png';
import bear from '../../images/bear.png';
import bull from '../../images/bull.png';
import bullBear from '../../images/bull_bear.png';
import { getBasisVaultUserBalance, getEthVaultUserBalance } from '../../redux/actions/actionCreater';

const VaultCard = (props) => {
    const { vaultConstData, totalBalance, openOptInfoModal } = props;
    const {
        title: vaultTitle,
        description: vaultDesc,
        strategy: vaultStrategy,
        path: vaultPath,
        disabled: vaultIsDisabled,
        currency,
        icon,
        chainId,
        type,
        contractAddress,
        id: vaultId,
        metrics,
        targetedApy,
    } = vaultConstData;
    const {
        marketType, leverage, downProtection, termDuration, riskProfile,
    } = metrics;

    const connectedAccount = useSelector((state) => state.member.connectedAccount);
    const vaultUserBalances = useSelector((state) => state.vault.vaultUserBalances);

    const { accountAddress } = connectedAccount;

    const [metricIsShow, setMetricIsShow] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getMarketImg = (marketT) => {
        let img;
        switch (marketT) {
            case 'Down Market':
                img = bear;
                break;
            case 'Up Market':
                img = bull;
                break;
            case 'Neutral':
                img = bullBear;
                break;
            default:
        }
        return img;
    };

    const getMarketColor = (marketT) => {
        let color;
        switch (marketT) {
            case 'Down Market':
                color = '#005E00';
                break;
            case 'Up Market':
                color = '#942a0d';
                break;
            case 'Neutral':
                color = '#942a0d';
                break;
            default:
        }
        return color;
    };

    useEffect(() => {
        // Get User Balance In Vault
        if (accountAddress) {
            if (type === 'ethVault') {
                dispatch(getEthVaultUserBalance(contractAddress, connectedAccount, vaultId));
            } else if (type === 'lpVault') {
                dispatch(getBasisVaultUserBalance(contractAddress, connectedAccount, vaultId));
            }
        }
    }, [accountAddress]);

    return (
        <Container color={getMarketColor(marketType)}>
            <ChainContainer>
                <ChainImage
                    src={+chainId === 1 ? ethImg : optImg}
                    alt='vault icon'
                />
                {+chainId === 10 && (
                    <Icon
                        icon={faInfoCircle}
                        onClick={openOptInfoModal}
                    />
                )}
            </ChainContainer>
            <VaultInfoContainer>
                <RowContainer>
                    <Title>{vaultTitle}</Title>
                    <ImageContainer>
                        <Image
                            src={icon}
                            alt='vault icon'
                        />
                    </ImageContainer>
                </RowContainer>
                <DescText>{vaultDesc}</DescText>
                <ListItemContainer>
                    <ListItemTitle>Targeted APY</ListItemTitle>
                    <ListItemBoldValue>
                        {`${targetedApy ? targetedApy.toLocaleString('en', { maximumFractionDigits: 2 }) : '-'} %`}
                    </ListItemBoldValue>
                </ListItemContainer>
                <ListItemContainer>
                    <ListItemTitle>AUM</ListItemTitle>
                    <ListItemValue>
                        {`${
                            totalBalance !== '' ? parseFloat(totalBalance).toLocaleString('en', { maximumFractionDigits: 6 }) : '-'
                        } ${currency}`}
                    </ListItemValue>
                </ListItemContainer>
                <ListItemContainer>
                    <ListItemTitle>Strategy</ListItemTitle>
                    <ListItemValue>{vaultStrategy}</ListItemValue>
                </ListItemContainer>
                <Button
                    onClick={() => navigate(`${vaultPath}`)}
                    disabled={vaultIsDisabled}
                >
                    {vaultIsDisabled ? 'COMING SOON' : 'DEPOSIT'}
                </Button>
            </VaultInfoContainer>
            {!!accountAddress && (
                <VaultBalanceContainer>
                    <ListItemContainer>
                        <ListItemTitle>Your Balance</ListItemTitle>
                        <ListItemValue>
                            {`${parseFloat(vaultUserBalances[vaultId] || 0).toLocaleString('en', {
                                maximumFractionDigits: 6,
                            })} ${currency}`}
                        </ListItemValue>
                    </ListItemContainer>
                </VaultBalanceContainer>
            )}
            <MetricsContainer>
                <MetricTitleRow>
                    {metricIsShow ? (
                        <MarketRowContainer>
                            <MarketImg src={getMarketImg(marketType)} />
                            <MetricTitle>{marketType}</MetricTitle>
                        </MarketRowContainer>
                    ) : (
                        <MetricTitle>Vault Characteristics</MetricTitle>
                    )}
                    <ArrowIcon
                        icon={faAngleDown}
                        isShow={metricIsShow}
                        disabled={!marketType}
                        onClick={() => marketType && setMetricIsShow(!metricIsShow)}
                    />
                </MetricTitleRow>
                <MetricInfoContainer isShow={metricIsShow}>
                    <div>
                        <MetricInfoLabel>Downside Protection</MetricInfoLabel>
                        <MetricInfoValue>{downProtection}</MetricInfoValue>
                    </div>
                    <div>
                        <MetricInfoLabel>Risk & Appetite</MetricInfoLabel>
                        <MetricInfoValue>{riskProfile}</MetricInfoValue>
                    </div>
                    <div>
                        <MetricInfoLabel>Term Duration</MetricInfoLabel>
                        <MetricInfoValue>{termDuration}</MetricInfoValue>
                    </div>
                    <div>
                        <MetricInfoLabel>Leverage</MetricInfoLabel>
                        <MetricInfoValue>{leverage}</MetricInfoValue>
                    </div>
                </MetricInfoContainer>
            </MetricsContainer>
        </Container>
    );
};

VaultCard.propTypes = {
    vaultConstData: PropTypes.object,
    totalBalance: PropTypes.string,
    openOptInfoModal: PropTypes.func,
};

VaultCard.defaultProps = {
    vaultConstData: {},
    totalBalance: '',
    openOptInfoModal: () => {},
};

export default VaultCard;

const Container = styled.div`
    flex: 0 0 320px;
    border-radius: 16px;
    border: 2px solid ${Colors.lightKhaki};
    background-color: rgba(0, 61, 69, 0.9);
    padding: 0 0 20px 0;
    background: ${(props) => `linear-gradient(to bottom, #003d45 75%, ${props.color} 100%)`};
    @media (max-width: 768px) {
        max-width: 350px;
    }
`;

const ChainContainer = styled.div`
    width: 100%;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Icon = styled(FontAwesomeIcon)`
    margin-left: 8px;
    color: ${Colors.lightKhaki};
    cursor: pointer;
    :hover {
        color: ${Colors.ivory};
    }
`;

const VaultInfoContainer = styled.div`
    display: grid;
    grid-template-rows: 1.2fr 1.2fr 0.5fr 0.5fr 0.5fr 0.9fr;
    padding: 18px 24px 0px 24px;
    align-items: flex-start;
    border-top: 1px solid ${Colors.lightKhaki};
    @media (max-width: 992px) {
        width: 100%;
        max-width: none;
    }
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding: 24px 24px 0px 24px;
    }
`;

const VaultBalanceContainer = styled.div`
    padding: 0px 24px;
`;

const RowContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled(H3)`
    color: ${Colors.lightKhaki};
    font-weight: bold;
    font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    font-size: 26px;
    line-height: 34px;
    @media (max-width: 768px) {
        font-size: 24px;
        line-height: 32px;
    }
`;

const ImageContainer = styled.div`
    height: 48px;
    position: relative;
    margin-left: 8px;
    @media (max-width: 768px) {
        height: 40px;
    }
`;

const Image = styled.img`
    height: 100%;
    object-fit: contain;
`;

const DescText = styled(H4)`
    width: 100%;
    color: ${Colors.ivory};
    margin: 4px 0 12px 0;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
        margin: 16px 0 12px 0;
    }
`;

const ListItemContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
`;

const ListItemTitle = styled(H4)`
    color: ${Colors.ivory};
    font-size: 14px;
    line-height: 20px;
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
    }
`;

const ListItemValue = styled(H4)`
    color: ${Colors.ivory};
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
    }
`;

const ListItemBoldValue = styled(ListItemValue)`
    color: ${Colors.lightKhaki};
`;

const Button = styled.button`
    width: 100%;
    max-height: 52px;
    padding: 8px;
    font-size: 22px;
    line-height: 26px;
    font-family: Iceberg, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    font-weight: 600;
    text-align: center;
    border-radius: 8px;
    margin: 12px 0;
    opacity: ${(props) => (props.disabled ? '0.7' : '1')};
    background-color: ${(props) => (props.disabled ? Colors.midNightGreen : Colors.lightKhaki)};
    color: ${(props) => (props.disabled ? Colors.lightKhaki : Colors.midNightGreen)};
    border: ${(props) => (props.disabled ? `1px solid ${Colors.lightKhaki}` : `1px solid ${Colors.lightKhaki}`)};
    :hover {
        background-color: ${(props) => (props.disabled ? Colors.midNightGreen : Colors.ivory)};
        border: ${(props) => (props.disabled ? `1px solid ${Colors.lightKhaki}` : `1px solid ${Colors.ivory}`)};
    }
    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 26px;
        padding: 6px;
    }
`;

const ChainImage = styled.img`
    width: 146x;
    height: 40px;
    @media (max-width: 768px) {
        width: 130x;
        height: 36px;
    }
`;

const MetricsContainer = styled.div`
    width: 100%;
    margin-top: 18px;
    padding: 18px 24px 0 24px;
    border-top: 1px solid ${Colors.lightKhaki};
`;

const MetricTitleRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 500ms ease all;
`;

const MetricTitle = styled(H4)`
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    text-align: left;
    color: ${Colors.lightKhaki};
`;

const ArrowIcon = styled(FontAwesomeIcon)`
    color: ${Colors.lightKhaki};
    font-size: 20px;
    cursor: ${(props) => !props.disabled && 'pointer'};
    transform: ${(props) => props.isShow && 'rotate(180deg)'};
    opacity: ${(props) => props.disabled && '0.5'};
`;

const MarketRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MarketImg = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 12px;
`;

const MetricInfoContainer = styled.div`
    max-height: ${(props) => (props.isShow ? '300px' : '0px')};
    transition: 500ms ease all;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
`;

const MetricInfoLabel = styled(H4)`
    font-size: 11px;
    line-height: 14px;
    color: ${Colors.ivory};
    margin-top: 12px;
`;

const MetricInfoValue = styled(H4)`
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: ${Colors.lightKhaki};
    margin-top: 4px;
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 22px;
    }
`;
