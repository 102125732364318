import styled from 'styled-components';
import React from 'react';
import Colors from '../../styles/Colors';

// eslint-disable-next-line import/prefer-default-export
export const alertText = (text) => (
    <AlertDiv>
        <AlertText data-testid='alertText'>{text}</AlertText>
    </AlertDiv>
);

const AlertDiv = styled.div`
    width: 100%;
    margin-left: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    line-break: normal;
    overflow-wrap: anywhere;
`;

const AlertText = styled.div`
    width: 100px;
    flex-grow: 1;
    font-size: 16px;
    line-height: 24px;
    color: ${Colors.ivory};
`;
