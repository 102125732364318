/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import twitter from '../../images/twitter.png';
import twitterHover from '../../images/twitter_hover.png';
import discord from '../../images/discord.png';
import discordHover from '../../images/discord_hover.png';

const TWITTER_URL = process.env.REACT_APP_TWITTER_URL;
const DISCORD_URL = process.env.REACT_APP_DISCORD_URL;

const ContactLink = forwardRef(({
    children, href, passHref, ...rest
}, ref) => (
    <a
        {...rest}
        href={href}
        ref={ref}
        target='_blank'
        rel='noreferrer'
    >
        {children}
    </a>
));

const Contacts = () => {
    const [isHoveringTwitter, setIsHoveredTwitter] = useState(false);
    const onMouseEnterTitter = () => setIsHoveredTwitter(true);
    const onMouseLeaveTwitter = () => setIsHoveredTwitter(false);

    const [isHoveringDiscord, setIsHoveredDiscord] = useState(false);
    const onMouseEnterDiscord = () => setIsHoveredDiscord(true);
    const onMouseLeaveDiscord = () => setIsHoveredDiscord(false);

    return (
        <ContactsContainer>
            <Twitter
                onMouseEnter={onMouseEnterTitter}
                onMouseLeave={onMouseLeaveTwitter}
            >
                <ContactLink
                    href={TWITTER_URL}
                    passHref
                >
                    {isHoveringTwitter ? (
                        <Image
                            src={twitterHover}
                            alt='twitter logo'
                            width='40'
                            height='40'
                            layout='intrinsic'
                        />
                    ) : (
                        <Image
                            src={twitter}
                            alt='twitter logo'
                            width='40'
                            height='40'
                            layout='intrinsic'
                        />
                    )}
                </ContactLink>
            </Twitter>
            <Discord
                onMouseEnter={onMouseEnterDiscord}
                onMouseLeave={onMouseLeaveDiscord}
            >
                <ContactLink
                    href={DISCORD_URL}
                    passHref
                >
                    {isHoveringDiscord ? (
                        <Image
                            src={discordHover}
                            alt='discord logo'
                            width='40'
                            height='40'
                            layout='intrinsic'
                        />
                    ) : (
                        <Image
                            src={discord}
                            alt='discord logo'
                            width='40'
                            height='40'
                            layout='intrinsic'
                        />
                    )}
                </ContactLink>
            </Discord>
        </ContactsContainer>
    );
};

export default Contacts;

ContactLink.propTypes = {
    children: PropTypes.element,
    href: PropTypes.string.isRequired,
    passHref: PropTypes.bool,
};

ContactLink.defaultProps = {
    children: undefined,
    passHref: false,
};

const ContactsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

const Twitter = styled.div`
    width: 40px;
`;

const Discord = styled(Twitter)`
    margin-left: 16px;
`;

const Image = styled.img`
    width: 40px;
    height: 40px;
`;
