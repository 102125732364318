/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import 'react-multi-carousel/lib/styles.css';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StrategyComponent from '../StrategyComponent';
import strategyImage from '../../../images/basis_trading_vault_strategy.gif';
import Colors from '../../../styles/Colors';
import { openNewTab } from '../../../utils/common';

const VAULT_DESC_URL = 'https://medium.com/diamond-protocol/diamonds-new-cash-and-carry-vault-aad0d5b8e6af';
const BASIS_TRADING_FAQ_URL = 'https://dmo-protocol.gitbook.io/diamond/usdc-cash-and-carry-vault/faq';

const _renderHyperLinkText = () => (
    <Container>
        <VaultDesc>To learn more about this vault, please visit: </VaultDesc>
        <FAQRow onClick={() => openNewTab(VAULT_DESC_URL)}>
            <VaultDesc>&#x2022; </VaultDesc>
            <FAQText>Step by step guide</FAQText>
            <Icon icon={faUpRightFromSquare} />
        </FAQRow>
        <FAQRow onClick={() => openNewTab(BASIS_TRADING_FAQ_URL)}>
            <VaultDesc>&#x2022; </VaultDesc>
            <FAQText>FAQ</FAQText>
            <Icon icon={faUpRightFromSquare} />
        </FAQRow>
    </Container>
);

const Strategy = () => {
    const slider1 = {
        image: <Image src={strategyImage} />,
        title: 'ETH Cash and Carry Vault',
        text: 'When user deposited USDC into our vault, we split the fund into two parts: one part for buying spot on Uniswap, and the rest for shorting perpetual contract on Perpetual Protocol. Our algorithm will find the best rebalance frequency for the current market, and help our users rebalance accordingly.',
    };

    return (
        <StrategyComponent
            title='Vault Strategy'
            desc='Basis Trading is a commonly used trading strategy in the crypto world; it is similar to the “Cash and Carry” strategy in TradFi. In Basis Trading, investors short the same amount of perpetual contracts as their spot assets to achieve delta neutral, and earn funding rates by holding the short position & rebalancing between spot and short position.'
            sliderComponents={[slider1]}
            hyperLinkTextComponent={_renderHyperLinkText()}
        />
    );
};

export default Strategy;

const Container = styled.div`
    margin-top: 6px;
`;

const Image = styled.img`
    border-radius: 8px;
    height: 100%;
`;
const VaultDesc = styled.span`
    color: ${Colors.ivory};
    font-size: 14px;
    line-height: 22px;
    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const FAQRow = styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    :hover {
        span,
        svg {
            color: ${Colors.lightKhaki};
        }
    }
`;

const Icon = styled(FontAwesomeIcon)`
    color: ${Colors.ivory};
    margin-left: 8px;
    font-size: 16px;
    @media (max-width: 992px) {
        margin-left: 6px;
        font-size: 14px;
    }
`;

const FAQText = styled(VaultDesc)`
    text-decoration: underline;
    font-weight: bold;
    margin-left: 8px;
    /* font-size: 18px;
    line-height: 28px;
    @media (max-width: 992px) {
        font-size: 16px;
        line-height: 24px;
    }
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
    } */
`;
