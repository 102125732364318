import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import Head from 'next/head';
// import { useRouter } from 'next/router';

import Header from './header';
import Footer from './footer';
import Colors from '../../styles/Colors';

const Layout = (props) => {
    // const { pathname } = useRouter();
    const { children } = props;

    // const returnTitle = (path) => {
    //   let title;
    //   if (path === '/') {
    //     title = 'Coinomo';
    //   } else if (path === '/yield') {
    //     title = 'Coinomo | Yield';
    //   }
    //   return title;
    // };

    return (
        <>
            {/* <Head>
              <title>{returnTitle(pathname)}</title>
          </Head> */}
            <Header />
            <Main>{children}</Main>
            <Footer />
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.element.isRequired,
};

export default Layout;

const Main = styled.main`
    width: 100%;
    background-color: ${Colors.midNightGreen};
    padding-top: 90px;
    @media (max-width: 992px) {
        padding-top: 70px;
    }
`;
