import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Colors from '../../styles/Colors';
import { H2 } from '../../styles/typography';

const NoMatchPage = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <Title>Page Not Existed</Title>
            <Button onClick={() => navigate('/')}>Back to Vaults Page</Button>
        </Container>
    );
};

export default NoMatchPage;

const Container = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 24px;
    min-height: calc(100vh - 150px);
    background-color: ${Colors.midNightGreen};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Title = styled(H2)`
    color: ${Colors.ivory};
`;

const Button = styled.button`
    background-color: ${Colors.lightKhaki};
    color: ${Colors.gray6};
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    padding: 8px 24px;
    border-radius: 8px;
    margin-top: 24px;
`;
