/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import Button from '../../../components/common/Button';
import networks from '../../../utils/constant/networks';
import { H3, H4 } from '../../../styles/typography';
import Colors from '../../../styles/Colors';
import { setSelectNetworkModalStatus, getBasisTradingAum, getBasisVaultUserBalance } from '../../../redux/actions/actionCreater';
import {
    getOptUsdcBalance,
    getOptEstiGasFee,
    waitForTransaction,
    onDepositBasisTradingVault,
    onWithdrawBasisTradingVault,
} from '../../../utils/diamond';
import { alertText } from '../../../components/common/Toast';
import ReachDSModal from '../../../components/common/ReachDSModal';

const slippageOptions = [0.5, 1, 3];

const ActionColumn = (props) => {
    const { vaultConstData, userBalance } = props;
    const {
        contractAddress: proxyAddress, currency, id: vaultId, estiMaxDepositGas, chainId: deployedChainId,
    } = vaultConstData;

    const [selectedTab, setSelectedTab] = useState(0);
    const [usdcAmount, setUsdcAmount] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [estiGasFee, setEstiGasFee] = useState(0);
    const [usdcBalance, setUsdcBalance] = useState('');
    const [isDepositing, setIsDepositing] = useState(false);
    const [isWithdrawing, setIsWithdrawing] = useState(false);
    const [slippageNum, setSlippageNum] = useState(0.5);
    const [dsModalIsShow, setDsModalIsShow] = useState(false);

    const provider = useSelector((state) => state.member.provider);
    const connectedAccount = useSelector((state) => state.member.connectedAccount);
    const accountAddress = useSelector((state) => state.member.connectedAccount.accountAddress);
    const chainId = useSelector((state) => state.member.connectedAccount.chainId);
    const vaultUserBalances = useSelector((state) => state.vault.vaultUserBalances);

    const dispatch = useDispatch();

    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';
    const isUnderCorrectNetwork = isProduction ? +chainId === deployedChainId : +chainId === 31337;

    const getUsdcBalance = async (account) => {
        const _usdcBalance = await getOptUsdcBalance(account);
        setUsdcBalance(_usdcBalance);
        return _usdcBalance;
    };

    const resetActionColumn = () => {
        setUsdcAmount('');
        setErrorMsg('');
    };

    const handleTabSwitch = async (tabName) => {
        setSelectedTab(tabName);
        setUsdcAmount('');
        setErrorMsg('');
    };

    const handleConnectWallet = async () => {
        dispatch(setSelectNetworkModalStatus({ status: true }));
    };

    const handleDeposit = async () => {
        if (!isUnderCorrectNetwork) {
            setErrorMsg(`Please use ${networks[isProduction ? deployedChainId : 31337]} network`);
        } else {
            setIsDepositing(true);
            resetActionColumn();
            try {
                const res = await onDepositBasisTradingVault(connectedAccount, (+usdcAmount).toFixed(6), proxyAddress, provider);
                showPendingAlert(res.hash, `Deposit ${currency.toUpperCase()} Succeed`);
            } catch (error) {
                console.log(error.message);
            }
            setIsDepositing(false);
        }
    };

    const handleWithdraw = async () => {
        if (!isUnderCorrectNetwork) {
            setErrorMsg(`Please use ${networks[isProduction ? deployedChainId : 31337]} network`);
        } else {
            setIsWithdrawing(true);
            resetActionColumn();
            try {
                const res = await onWithdrawBasisTradingVault(
                    connectedAccount,
                    (+usdcAmount).toFixed(6),
                    proxyAddress,
                    slippageNum * 100,
                    provider,
                );
                showPendingAlert(res.hash, `Withdraw ${currency.toUpperCase()} Succeed`);
            } catch (error) {
                console.log(error.message);
            }
            setIsWithdrawing(false);
        }
    };

    const updateAllBalance = async () => {
        getUsdcBalance(connectedAccount);
        dispatch(getBasisTradingAum(proxyAddress, vaultId));
        dispatch(getBasisVaultUserBalance(proxyAddress, connectedAccount, vaultId));
    };

    const showPendingAlert = async (hash, successText) => {
        const id = toast.loading(alertText(`Transaction hash ${hash} is processing...`));
        await waitForTransaction(provider, hash);
        toast.update(id, {
            render: alertText(successText),
            type: 'success',
            isLoading: false,
            autoClose: 5000,
        });
        updateAllBalance();
    };

    const isValidNumber = (n) => !Number.isNaN(parseFloat(n)) && !Number.isNaN(n - 0) && parseFloat(n) > 0;

    const handleMaxAmount = async () => {
        if (selectedTab === 0) {
            setUsdcAmount(usdcBalance > estiGasFee ? (usdcBalance - estiGasFee).toFixed(6) : (+usdcBalance).toFixed(6));
        } else {
            setUsdcAmount(userBalance);
        }
    };

    const getEstiGas = async () => {
        const gas = await getOptEstiGasFee(estiMaxDepositGas);
        setEstiGasFee(gas);
    };

    useEffect(() => {
        if (connectedAccount.accountAddress) {
            updateAllBalance();
        }
    }, [connectedAccount]);

    useEffect(() => {
        if (estiMaxDepositGas) {
            getEstiGas();
        }
    }, [estiMaxDepositGas]);

    const renderDepositBtn = () => (
        <Button
            onClick={handleDeposit}
            disabled={!isValidNumber(usdcAmount) || isDepositing}
            btnType='primary'
            btnText={isDepositing ? 'PROCESSING...' : `DEPOSIT ${currency}`}
        />
    );

    const renderWithdrawBtn = () => (
        <Button
            onClick={handleWithdraw}
            disabled={!isValidNumber(usdcAmount) || isWithdrawing}
            btnType='primary'
            btnText={isWithdrawing ? 'PROCESSING...' : `WITHDRAW ${currency}`}
        />
    );

    const renderActionBtn = () => {
        if (accountAddress && isUnderCorrectNetwork) {
            return selectedTab === 0 ? renderDepositBtn() : renderWithdrawBtn();
        }
        if (accountAddress && !isUnderCorrectNetwork) {
            return (
                <Button
                    onClick={handleConnectWallet}
                    btnType='primary'
                    btnText='SWITCH NETWORK'
                    disabled={false}
                />
            );
        }
        return (
            <Button
                onClick={handleConnectWallet}
                btnType='primary'
                btnText='CONNECT WALLET'
                disabled={false}
            />
        );
    };

    return (
        <Container>
            <RowContainer>
                <PositionContainer>
                    <InfoTitle>Your Position</InfoTitle>
                    <InfoText>
                        {`${
                            isUnderCorrectNetwork && !!accountAddress
                                ? (+vaultUserBalances[vaultId] || 0).toLocaleString('en', { maximumFractionDigits: 6 })
                                : '-'
                        } ${currency}`}
                    </InfoText>
                </PositionContainer>
                <QuestionIcon
                    icon={faCircleQuestion}
                    onClick={() => setDsModalIsShow(true)}
                />
            </RowContainer>
            <TabsContainer>
                <Tab
                    isSelected={selectedTab === 0}
                    onClick={() => handleTabSwitch(0)}
                >
                    Deposit
                </Tab>
                <Tab
                    isSelected={selectedTab !== 0}
                    onClick={() => handleTabSwitch(1)}
                >
                    Withdraw
                </Tab>
            </TabsContainer>
            {selectedTab !== 0 && (
                <SlippageOptionContainer>
                    <InputLabel>Max Slippage Tolerance</InputLabel>

                    <SlippageBtns>
                        {slippageOptions.map((item) => (
                            <SlippageBtn
                                key={item}
                                onClick={() => setSlippageNum(item)}
                                isSelected={slippageNum === item}
                            >
                                {`${item}%`}
                            </SlippageBtn>
                        ))}
                    </SlippageBtns>
                </SlippageOptionContainer>
            )}
            <InputLabelRowContainer>
                <InputLabel>Amount</InputLabel>
                {!!accountAddress && (
                    <MaxBtn
                        onClick={handleMaxAmount}
                        disabled={(selectedTab === 0 && !(estiGasFee > 0)) || (selectedTab !== 0 && !userBalance)}
                    >
                        Max
                    </MaxBtn>
                )}
            </InputLabelRowContainer>
            <InputContainer>
                <Input
                    type='number'
                    placeholder='0'
                    value={usdcAmount}
                    onChange={(e) => {
                        setUsdcAmount(e.target.value);
                    }}
                    disabled={vaultConstData.disabled}
                />
            </InputContainer>
            {selectedTab !== 0 && (
                <>
                    <MinAmountReceiveText>The minimum amount you will receive:</MinAmountReceiveText>
                    <MinAmountRow>
                        <MinAmountValue>{+(usdcAmount * (1 - slippageNum / 100)).toFixed(6) || '-'}</MinAmountValue>
                        <MinAmountCurrency>{`${currency}`}</MinAmountCurrency>
                    </MinAmountRow>
                </>
            )}
            <BtnContainer>
                {renderActionBtn()}
                {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
            </BtnContainer>
            <BalanceContainer>
                <BalanceTitle>Wallet Balance</BalanceTitle>
                <BalanceValue>
                    {`${usdcBalance ? (+usdcBalance).toLocaleString('en', { maximumFractionDigits: 4 }) : '-'} ${currency}`}
                </BalanceValue>
            </BalanceContainer>
            <ReachDSModal
                isOpen={dsModalIsShow}
                handleClose={() => setDsModalIsShow(false)}
            />
        </Container>
    );
};

ActionColumn.propTypes = {
    vaultConstData: PropTypes.object,
    userBalance: PropTypes.string,
};

ActionColumn.defaultProps = {
    vaultConstData: {},
    userBalance: '0',
};

export default ActionColumn;

const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

const RowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

const PositionContainer = styled.div`
    flex: 1;
`;

const InfoTitle = styled(H4)`
    color: ${Colors.ivory};
    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const InfoText = styled(H3)`
    color: ${Colors.ivory};
    font-weight: bold;
    @media (max-width: 992px) {
        font-size: 20px;
        line-height: 32px;
    }
    @media (max-width: 768px) {
        margin-top: 4px;
        font-size: 16px;
        line-height: 24px;
    }
`;

const TabsContainer = styled.div`
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 12px;
    @media (max-width: 992px) {
        margin-top: 6px;
    }
`;

const Tab = styled(H4)`
    color: ${(props) => (props.isSelected ? Colors.ivory : Colors.lightKhaki)};
    font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
    padding: 4px 0px;
    flex: 1;
    text-align: center;
    border-bottom: ${(props) => (props.isSelected ? `2px solid ${Colors.ivory}` : `1px solid ${Colors.lightKhaki}`)};
    cursor: ${(props) => (props.isSelected ? 'inherit' : 'pointer')};
    font-size: 20px;
    line-height: 32px;
    font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    @media (max-width: 992px) {
        font-size: 16px;
        line-height: 24px;
    }
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

const InputContainer = styled.div`
    width: 100%;
    margin-top: 12px;
    padding: 4px;
    background-color: ${Colors.gray3};
    border-radius: 8px;
`;

const Input = styled.input`
    width: 100%;
    font-size: 32px;
    line-height: 44px;
    font-weight: bold;
    padding: 4px 8px;
    background-color: inherit;
    border: none;
    :focus {
        border: none;
        outline: none;
    }
    @media (max-width: 768px) {
        font-size: 28px;
        line-height: 40px;
        padding: 2px 6px;
    }
`;

const BtnContainer = styled.div`
    width: 100%;
    margin-top: 24px;
    @media (max-width: 768px) {
        margin-top: 16px;
    }
`;

const BalanceContainer = styled(RowContainer)`
    margin-top: 24px;
    @media (max-width: 768px) {
        margin-top: 16px;
    }
`;

const BalanceTitle = styled(H4)`
    color: ${Colors.ivory};
    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const BalanceValue = styled(BalanceTitle)`
    text-align: right;
`;

const ErrorMsg = styled(H4)`
    color: ${Colors.danger.default};
    margin-top: 12px;
`;

const InputLabelRowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
`;

const InputLabel = styled(H4)`
    color: ${Colors.ivory};
    width: 100%;
    margin-left: 4px;
    font-size: 14px;
    line-height: 18px;
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const MaxBtn = styled.button`
    font-size: 13px;
    line-height: 16px;
    color: ${Colors.gray2};
    padding: 2px 8px;
    border: 1px solid ${Colors.gray2};
    border-radius: 4px;
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const SlippageOptionContainer = styled.div`
    width: 100%;
    margin: 6px 0 24px 0;
`;

const SlippageBtns = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8px;
    margin-top: 12px;
`;

const SlippageBtn = styled.button`
    padding: 6px 12px;
    color: ${(props) => (props.isSelected ? Colors.midNightGreen : Colors.ivory)};
    border: 1px solid ${Colors.lightKhaki};
    border-radius: 8px;
    background-color: ${(props) => (props.isSelected ? Colors.lightKhaki : 'inherit')};
    font-size: 16px;
    line-height: 20px;
    font-weight: ${(props) => (props.isSelected ? 'bold' : 500)};
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
    }
`;

const MinAmountReceiveText = styled(H4)`
    margin: 24px 0 6px 0;
    color: ${Colors.gray3};
    font-style: italic;
    width: 100%;
    font-size: 12px;
    line-height: 16px;
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const MinAmountRow = styled.div`
    width: 100%;
    margin: 0px 0 6px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

const MinAmountValue = styled(H4)`
    color: ${Colors.ivory};
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const MinAmountCurrency = styled(MinAmountValue)`
    font-weight: normal;
    margin-left: 4px;
`;

const QuestionIcon = styled(FontAwesomeIcon)`
    margin-top: 12px;
    font-size: 20px;
    color: ${Colors.ivory};
    cursor: pointer;
    :hover {
        color: ${Colors.lightKhaki};
    }
`;
