/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler,
} from 'chart.js';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../styles/Colors';

import { H2, H3, H4 } from '../../styles/typography';

const Performance = (props) => {
    const { vaultApy, tokenPriceDiff, vaultRound } = props;

    const [tokenPriceArray, setTokenPriceArray] = useState([]);
    const [labelArray, setLabelArray] = useState([]);
    const [lastWeekPerformance, setLastWeekPerformance] = useState('');

    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);

    const getGradient = (context) => {
        let width;
        let height;
        let gradient;
        const { chart } = context;
        const { ctx, chartArea } = chart;
        if (chartArea) {
            const chartWidth = chartArea.right - chartArea.left;
            const chartHeight = chartArea.bottom - chartArea.top;
            if (!gradient || width !== chartWidth || height !== chartHeight) {
                // Create the gradient because this is either the first render
                // or the size of the chart has changed
                width = chartWidth;
                height = chartHeight;
                gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                gradient.addColorStop(0, '#013d45c7');
                // gradient.addColorStop(0.5, 'yellow');
                gradient.addColorStop(1, '#00cc98bd');
            }
        }

        return gradient;
    };

    useEffect(() => {
        if (vaultRound.length > 0) {
            const labels = vaultRound.map((item) => {
                const date = new Date(item.round_start_timestamp * 1000);
                const month = date.toLocaleString('en', { month: 'short' });
                const day = date.toLocaleString('en', { day: 'numeric' });
                return `${month}-${day}`;
            });
            setLabelArray(labels);

            const prices = vaultRound.map((item) => {
                const price = (parseFloat(item.share_price) / 10 ** 18 - 1) * 100;
                return price;
            });
            setTokenPriceArray(prices);

            const yieldDiff = prices[prices.length - 1] - prices[prices.length - 2];
            setLastWeekPerformance(yieldDiff.toFixed(2));
        }
    }, [vaultRound]);

    const draggedLine = (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
            const activePoint = chart.tooltip._active[0];
            const { ctx } = chart;
            const { x } = activePoint.element;
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;

            // draw vertical line
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'white';
            ctx.setLineDash([4, 5]);
            ctx.stroke();
            ctx.restore();
        }
    };

    return (
        <Container>
            <Title>Vault Performance</Title>
            <YieldInfoContainer>
                <TitleRow>
                    <YieldTitle>Historical ROI</YieldTitle>
                    <HintContainer>
                        <InfoIcon icon={faCircleInfo} />
                        <Hint>The ROI since the inception of ETH Bull vault</Hint>
                    </HintContainer>
                </TitleRow>
                <YieldValue>
                    {tokenPriceDiff.toString() ? `${(tokenPriceDiff * 100).toLocaleString('en', { maximumFractionDigits: 4 })} %` : '-'}
                </YieldValue>
            </YieldInfoContainer>
            <MainContainer>
                <Line
                    plugins={[
                        {
                            afterDraw: draggedLine,
                        },
                    ]}
                    options={{
                        layout: {
                            padding: {
                                top: 20,
                                right: 20,
                                bottom: 0,
                                left: 20,
                            },
                        },
                        scales: {
                            y: {
                                display: true,
                                beginAtZero: true,
                                grid: {
                                    color: 'rgba(266, 266, 266, 0.1)',
                                    display: true,
                                },
                                ticks: {
                                    color: Colors.gray3,
                                    callback(val) {
                                        return `${val.toFixed(2)}`;
                                    },
                                },
                            },
                            x: {
                                display: true,
                                grid: {
                                    display: false,
                                },
                                ticks: {
                                    color: Colors.gray3,
                                    maxRotation: 0,
                                    align: 'start',
                                    autoSkip: true,
                                    autoSkipPadding: 12,
                                    maxTicksLimit: 4,
                                },
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                intersect: false,
                                enabled: true,
                                position: 'average',
                                displayColors: false,
                                callbacks: {
                                    label: (context) => {
                                        let label = context.dataset.label || '';

                                        if (label) {
                                            label += ': ';
                                        }
                                        if (context.parsed.y !== null) {
                                            label += `${context.parsed.y.toFixed(4)}%`;
                                        }
                                        return label;
                                    },
                                },
                            },
                        },
                        animation: false,
                        responsive: true,
                        hover: {
                            mode: 'nearest',
                            intersect: false,
                        },
                    }}
                    data={{
                        labels: labelArray,
                        datasets: [
                            {
                                id: 1,
                                label: 'Yield',
                                data: tokenPriceArray,
                                borderColor: '#00cc98',
                                backgroundColor: (context) => getGradient(context),
                                pointRadius: 6,
                                fill: true,
                                pointBorderColor: 'rgba(0, 0, 0, 0)',
                                pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                                pointHoverBackgroundColor: Colors.ivory,
                                pointHoverBorderColor: Colors.ivory,
                            },
                        ],
                    }}
                />
            </MainContainer>
            <ApyContainer>
                {/* <ApyItem>
                        <ApyTitle>Projected Yield (APY)</ApyTitle>
                        <ApyValue>{vaultApy ? `${vaultApy.toLocaleString('en', { maximumFractionDigits: 2 })} %` : '-'}</ApyValue>
                    </ApyItem> */}
                <ApyItem>
                    <ApyTitle>APY Based On Last Week Result</ApyTitle>
                    <ApyValue>{lastWeekPerformance ? `${(lastWeekPerformance * 52).toFixed(2)} %` : '-'}</ApyValue>
                </ApyItem>
            </ApyContainer>
        </Container>
    );
};

Performance.propTypes = {
    vaultApy: PropTypes.number,
    tokenPriceDiff: PropTypes.number,
    vaultRound: PropTypes.array,
};

Performance.defaultProps = {
    vaultApy: 0,
    tokenPriceDiff: 0,
    vaultRound: [],
};

export default Performance;

const Container = styled.div`
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        max-width: none;
    }
`;

const Title = styled(H3)`
    color: ${Colors.ivory};
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 40px;
    font-weight: bold;
    font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    @media (max-width: 992px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 24px;
    }
    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 20px;
    }
`;

const MainContainer = styled.div`
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-width: 0;
    position: relative;
`;

const YieldInfoContainer = styled.div`
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-width: 0;
    border-radius: 8px 8px 0 0;
    padding: 24px 24px 12px 24px;
`;

const YieldTitle = styled(H4)`
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.gray3};
`;

const YieldValue = styled(H4)`
    color: ${Colors.ivory};
    margin-top: 4px;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

const ApyContainer = styled.div`
    /* width: 100%; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 18px 24px 24px 24px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 0 0 8px 8px;
`;

const ApyItem = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 1px solid ${Colors.gray5};
    padding-top: 18px;
    margin-top: 6px;
`;

const ApyTitle = styled(H4)`
    color: ${Colors.ivory};
    font-size: 12px;
    line-height: 16px;
`;

const ApyValue = styled(H4)`
    color: ${Colors.ivory};
    margin-top: 4px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
`;

const Hint = styled.span`
    visibility: hidden;
    min-width: 180px;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.gray3};
    background-color: rgba(0, 0, 0, 0.5);
    text-align: left;
    padding: 5px 12px;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    left: calc(100% + 4px);
    top: 0;
    z-index: 1;
`;

const HintContainer = styled.div`
    display: inline-block;
    position: relative;
    :hover {
        span {
            visibility: visible;
        }
    }
`;

const InfoIcon = styled(FontAwesomeIcon)`
    font-size: 12px;
    color: ${Colors.ivory};
    margin-left: 8px;
`;

const TitleRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;
