/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Colors from '../../styles/Colors';
import { disclaimerParagrah1, disclaimerParagrah2 } from '../../utils/constant/disclaimer';

import { H3, H4 } from '../../styles/typography';

const DisclaimerComponent = ({ disclaimerList }) => {
    const [isShowAll, setIsShowAll] = useState(false);

    return (
        <Container>
            <Title>Risk & Disclaimer</Title>
            <DisclaimerLists>
                {disclaimerList.map((item) => (
                    <DisclaimerItemContainer key={item.num}>
                        <DisclaimerItemLabel>{`${item.num}.`}</DisclaimerItemLabel>
                        <DisclaimerItem>{item.text}</DisclaimerItem>
                    </DisclaimerItemContainer>
                ))}
            </DisclaimerLists>
            <DisclaimerText>
                {`${disclaimerParagrah1}${!isShowAll ? ' ...' : disclaimerParagrah2}
                `}
            </DisclaimerText>
            <ShowAll onClick={() => setIsShowAll(!isShowAll)}>{isShowAll ? 'Show Less' : 'Show All'}</ShowAll>
        </Container>
    );
};

DisclaimerComponent.propTypes = {
    disclaimerList: PropTypes.array,
};

DisclaimerComponent.defaultProps = {
    disclaimerList: [],
};

export default DisclaimerComponent;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Title = styled(H3)`
    color: ${Colors.ivory};
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 40px;
    font-weight: bold;
    font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    @media (max-width: 992px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 24px;
    }
    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 20px;
    }
`;

const DisclaimerLists = styled.div`
    width: 100%;
    margin-bottom: 32px;

    margin-bottom: 32px;
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
`;

const DisclaimerItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 8px;
`;

const DisclaimerItemLabel = styled(H4)`
    flex: 0 0 20px;
    color: ${Colors.ivory};
    font-size: 14px;
    line-height: 22px;
    font-style: normal;
    font-weight: normal;
    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const DisclaimerItem = styled(H4)`
    flex: 1 1 100px;
    color: ${Colors.ivory};
    font-size: 14px;
    line-height: 22px;
    font-style: normal;
    font-weight: normal;
    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const DisclaimerText = styled(H4)`
    display: inline;
    color: ${Colors.ivory};
    font-size: 14px;
    line-height: 24px;
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const ShowAll = styled(DisclaimerText)`
    margin-top: 2px;
    color: #1890ff;
    font-weight: bold;
`;
