/* eslint-disable no-unexpected-multiline */
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import VaultABI from '../ABI/vault.json';
import OptionABI from '../ABI/option.json';
import WethABI from '../ABI/weth.json';
import UsdcABI from '../ABI/usdc.json';
import Erc4626VaultABI from '../ABI/diamondErc4626Vault.json';
import externalAddresses from './constant/externalAddresses';

export const getBrowserProvider = async (connectedAccount) => {
    let provider;
    if (connectedAccount?.connectedOption === 'WalletConnect') {
        if (process.env.REACT_APP_NODE_ENV === 'DEVELOP') {
            provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);
        } else {
            provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_MAINNET_URL);
        }
    } else if (window.ethereum) {
        provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    } else if (process.env.REACT_APP_NODE_ENV === 'DEVELOP') {
        provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);
    } else {
        provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_MAINNET_URL);
    }
    return provider;
};

// eslint-disable-next-line no-unused-vars
export const getMainnetEthBalance = async (connectedAccount) => {
    let ethBalance = '';
    const { accountAddress, chainId } = connectedAccount;

    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';
    const isUnderCorrectChain = isProduction ? +chainId === 1 : +chainId === 31337;

    const rpcProvider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_MAINNET_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    if (isUnderCorrectChain && accountAddress) {
        const signer = await rpcProvider.getSigner(accountAddress);
        const signerEthBalance = (await signer.getBalance()).toString();
        ethBalance = ethers.utils.formatEther(signerEthBalance.toString());
    }

    return ethBalance;
};

// eslint-disable-next-line no-unused-vars
export const getUserBalanceInEthVault = async (contractAddress, connectedAccount) => {
    let totalBalanceInEther;
    const { accountAddress, chainId } = connectedAccount;

    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';

    const rpcProvider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_MAINNET_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    const isUnderCorrectChain = isProduction ? +chainId === 1 : +chainId === 31337;

    if (contractAddress && isUnderCorrectChain) {
        const signer = await rpcProvider.getSigner(accountAddress);
        const vault = new ethers.Contract(contractAddress, VaultABI, signer);
        const share = await vault.balanceOf(accountAddress);
        const totalBalanceInWei = (await vault.getWithdrawAmountByShares(share)).toString();
        totalBalanceInEther = ethers.utils.formatEther(totalBalanceInWei);
    }

    return totalBalanceInEther;
};

export const getDiamondVaultAum = async (contractAddress) => {
    let totalBalanceInEther;

    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';
    const provider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_MAINNET_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    if (contractAddress) {
        const vault = new ethers.Contract(contractAddress, VaultABI, provider);
        const totalBalanceInWei = (await vault.totalAsset({ from: '0x2546bcd3c84621e976d8185a91a922ae77ecec30' })).toString();
        totalBalanceInEther = ethers.utils.formatEther(totalBalanceInWei);
    }

    return totalBalanceInEther;
};

export const getVaultMaxCapacity = async (contractAddress) => {
    let capacityInEth;

    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';
    const provider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_MAINNET_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    if (contractAddress) {
        const vault = new ethers.Contract(contractAddress, VaultABI, provider);
        const capacityInWei = await (await vault.cap({ from: '0x2546bcd3c84621e976d8185a91a922ae77ecec30' })).toString();
        capacityInEth = ethers.utils.formatEther(capacityInWei);
    }

    return capacityInEth;
};

export const getVaultLockedStatus = async (contractAddress) => {
    const UNLOCKED_STATE = 1;
    let vaultIsUnlocked;

    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';
    const provider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_MAINNET_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    if (contractAddress) {
        const vault = new ethers.Contract(contractAddress, VaultABI, provider);
        vaultIsUnlocked = (await vault.state({ from: '0x2546bcd3c84621e976d8185a91a922ae77ecec30' })) === UNLOCKED_STATE;
    }

    return vaultIsUnlocked;
};

export const depositETHIntoVault = async (ethAmount, provider, accountAddress, vaultAddress) => {
    let res;

    const signer = provider.getSigner(accountAddress);
    const vault = new ethers.Contract(vaultAddress, VaultABI, signer);
    const signerETHBalance = await signer.getBalance();
    const depositAmount = ethers.utils.parseEther(ethAmount);
    const totalBalanceOfVault = await vault.totalAsset();
    const capacityOfVault = await vault.cap();
    const restVolume = capacityOfVault.sub(totalBalanceOfVault);
    const depositAmountLessThanRestVolume = depositAmount.lte(restVolume);

    // Check if deposit amount is greater than current rest volume
    if (!depositAmountLessThanRestVolume) {
        toast.warn('Your deposit amount has exceeded current vault capacity.', {
            isLoading: false,
            autoClose: 5000,
            position: 'top-center',
        });
        // Check if deposit amount is greater than user ETH balance
    } else if (signerETHBalance.lt(depositAmount)) {
        toast.warn('Your ETH is not enough.', {
            isLoading: false,
            autoClose: 5000,
            position: 'top-center',
        });
    } else {
        res = await vault.depositETH({ value: depositAmount });
    }

    return res;
};

export const withdrawETHFromVault = async (ethAmount, provider, accountAddress, contractAddress, isWithdrawAll) => {
    let res;

    const signer = provider.getSigner(accountAddress);
    const vault = new ethers.Contract(contractAddress, VaultABI, signer);

    const totalShare = await vault.balanceOf(await signer.getAddress());
    const signerVaultBalance = await vault.getWithdrawAmountByShares(totalShare);
    const withdrawAmount = ethers.utils.parseEther(ethAmount);
    const withdrawShare = await vault.getSharesByDepositAmount(withdrawAmount.toString());

    if (isWithdrawAll) {
        res = await vault.withdrawETH(totalShare.toString());
    } else if (withdrawAmount.gt(signerVaultBalance)) {
        // Check if withdraw amount is greater than vault balance
        toast.warn('Balance in vault is not enough', {
            isLoading: false,
            autoClose: 5000,
            position: 'top-center',
        });
    } else {
        res = await vault.withdrawETH(withdrawShare.toString());
    }

    return res;
};

export const waitForTransaction = async (provider, hash) => {
    const res = await provider.waitForTransaction(hash);
    return res;
};

// eslint-disable-next-line no-unused-vars
export const getDiamondVaultTxData = async (vaultId, sort) => {
    let url = `${process.env.REACT_APP_GINZA_API_URL}/vault/${vaultId}/vault_transactions?limit=50`;
    if (sort) {
        url += `&sort=${sort}`;
    }
    const options = {
        method: 'get',
        headers: {
            Authorization: process.env.REACT_APP_GINZA_AUTH_KEY,
        },
    };
    const result = await fetch(url, options);
    const resultJson = await result.json();
    // Succeed
    if (result.status === 200) {
        return resultJson;
    }
    // Failed
    throw new Error(resultJson.error);
};

export const getDiamondVaultEstiApy = async (vaultId) => {
    const url = `${process.env.REACT_APP_GINZA_API_URL}/vault/${vaultId}/calculate_apy`;

    const options = {
        method: 'get',
        headers: {
            Authorization: process.env.REACT_APP_GINZA_AUTH_KEY,
        },
    };
    const result = await fetch(url, options);
    const resultJson = await result.json();
    // Succeed
    if (result.status === 200) {
        return resultJson;
    }
    // Failed
    throw new Error(resultJson.error);
};

export const getGinzaSharePrices = async (vaultId) => {
    const url = `${process.env.REACT_APP_GINZA_API_URL}/vault/${vaultId}/share_prices`;

    const options = {
        method: 'get',
        headers: {
            Authorization: process.env.REACT_APP_GINZA_AUTH_KEY,
        },
    };
    const result = await fetch(url, options);
    const resultJson = await result.json();
    // Succeed
    if (result.status === 200) {
        return resultJson.data;
    }
    // Failed
    throw new Error(resultJson.error);
};

export const makerPayPremium = async (ethAmount, provider, accountAddress, optionAddress) => {
    const UNLOCKED_STATE = 1;
    let res;

    const signer = provider.getSigner(accountAddress);
    const optionC = new ethers.Contract(optionAddress, OptionABI, signer);
    const totalPremium = await optionC.totalPremium();
    const isUnlocked = (await optionC.state()) === UNLOCKED_STATE;
    const payAmount = ethers.utils.parseEther(ethAmount);

    if (!isUnlocked) {
        toast.warn('Option is locked', {
            isLoading: false,
            autoClose: 5000,
            position: 'top-center',
        });
    } else if (payAmount.lt(totalPremium)) {
        toast.warn('Your amount is lower than total premium', {
            isLoading: false,
            autoClose: 5000,
            position: 'top-center',
        });
    } else {
        res = await optionC.payPremium(payAmount.toString());
    }

    return res;
};

export const getETHPrice = async () => {
    const url = `${process.env.REACT_APP_COINBASE_URL}/exchange-rates?currency=ETH`;

    const options = {
        method: 'get',
    };
    const result = await fetch(url, options);
    const resultJson = await result.json();
    // Succeed
    if (result.status === 200) {
        return parseFloat(resultJson.data.rates.USD);
    }
    // Failed
    throw new Error(resultJson.error);
};

export const getGinzaRoundsData = async (vaultId) => {
    const url = `${process.env.REACT_APP_GINZA_API_URL}/vault/${vaultId}/rounds`;

    const options = {
        method: 'get',
        headers: {
            Authorization: process.env.REACT_APP_GINZA_AUTH_KEY,
        },
    };
    const result = await fetch(url, options);
    const resultJson = await result.json();
    // Succeed
    if (result.status === 200) {
        return resultJson.data;
    }
    // Failed
    throw new Error(resultJson.error);
};

export const makerApproveWETH = async (ethAmount, provider, accountAddress, optionAddress) => {
    const signer = provider.getSigner(accountAddress);
    const weth = new ethers.Contract('0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', WethABI, signer);
    const res = await weth.connect(signer).approve(optionAddress, ethers.utils.parseEther(ethAmount));

    return res;
};

export const getDiamondTotalPremium = async (optionAddress) => {
    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';

    const provider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_MAINNET_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    const signer = provider.getSigner('0x2546bcd3c84621e976d8185a91a922ae77ecec30');
    const option = new ethers.Contract(optionAddress, OptionABI, signer);
    const totalPremium = await option.totalPremium();
    const totalPremiumInEther = ethers.utils.formatEther(totalPremium).toString();

    return totalPremiumInEther;
};

export const getDiamondMakerAddress = async (optionAddress) => {
    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';

    const provider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_MAINNET_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    const signer = provider.getSigner('0x2546bcd3c84621e976d8185a91a922ae77ecec30');
    const option = new ethers.Contract(optionAddress, OptionABI, signer);
    const address = (await option.options()).maker;

    return address;
};

export const getExpiryTimestamp = async (optionAddress) => {
    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';

    const provider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_MAINNET_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    const signer = provider.getSigner('0x2546bcd3c84621e976d8185a91a922ae77ecec30');
    const option = new ethers.Contract(optionAddress, OptionABI, signer);
    const timestamp = (await option.options()).expiry.toString();
    return timestamp;
};

export const getEstiGasFee = async (maxGas) => {
    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';

    const provider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_MAINNET_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    const gasPrice = await provider.getGasPrice();
    const estiGasFeeInEth = ethers.utils.formatEther(gasPrice.mul(maxGas).toString());
    return estiGasFeeInEth;
};

export const getOptUsdcBalance = async (connectedAccount) => {
    let usdcBalance = '';
    const { accountAddress, chainId } = connectedAccount;

    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';
    const isUnderCorrectChain = isProduction ? +chainId === 10 : +chainId === 31337;

    const rpcProvider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_OPTIMISIM_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    if (isUnderCorrectChain && accountAddress) {
        const usdc = new ethers.Contract(externalAddresses.OPT_USDC, UsdcABI, rpcProvider);
        const balanceOfUsdc = await usdc.balanceOf(accountAddress);
        usdcBalance = ethers.utils.formatUnits(balanceOfUsdc, 6);
    }

    return usdcBalance;
};

export const onDepositBasisTradingVault = async (connectedAccount, amount, proxyAddress, provider) => {
    let res;
    const { accountAddress, chainId } = connectedAccount;

    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';
    const isUnderCorrectChain = isProduction ? +chainId === 10 : +chainId === 31337;

    const rpcProvider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_OPTIMISIM_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    const proxyVault = new ethers.Contract(proxyAddress, Erc4626VaultABI, rpcProvider);
    const usdc = new ethers.Contract(externalAddresses.OPT_USDC, UsdcABI, rpcProvider);
    const depositAmount = ethers.utils.parseUnits(amount, 6);
    const totalBalanceOfVault = await proxyVault.totalAssets({ from: '0x2546bcd3c84621e976d8185a91a922ae77ecec30' });
    const capacityOfVault = await proxyVault.depositLimit({ from: '0x2546bcd3c84621e976d8185a91a922ae77ecec30' });
    const restVolume = capacityOfVault.sub(totalBalanceOfVault);
    const depositAmountLessThanRestVolume = depositAmount.lte(restVolume);

    if (isUnderCorrectChain && accountAddress) {
        const signerUsdcBalance = await usdc.balanceOf(accountAddress);
        if (!depositAmountLessThanRestVolume) {
            toast.warn('Your deposit amount has exceeded current vault capacity.', {
                isLoading: false,
                autoClose: 5000,
                position: 'top-center',
            });
            // Check if deposit amount is greater than user ETH balance
        } else if (signerUsdcBalance.lt(depositAmount)) {
            toast.warn('Your USDC is not enough.', {
                isLoading: false,
                autoClose: 5000,
                position: 'top-center',
            });
        } else {
            const signer = provider.getSigner(accountAddress);
            await usdc.connect(signer).approve(proxyAddress, depositAmount);
            res = await proxyVault.connect(signer).deposit(depositAmount, accountAddress);
        }
    }

    return res;
};

export const onWithdrawBasisTradingVault = async (connectedAccount, amount, proxyAddress, maxLoss, provider) => {
    let res;
    const { accountAddress, chainId } = connectedAccount;
    const withdrawAmount = ethers.utils.parseUnits(amount, 6);

    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';
    const isUnderCorrectChain = isProduction ? +chainId === 10 : +chainId === 31337;

    const rpcProvider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_OPTIMISIM_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    const signer = provider.getSigner(accountAddress);
    const proxyVault = new ethers.Contract(proxyAddress, Erc4626VaultABI, rpcProvider);
    const shareAmount = await proxyVault.connect(signer).balanceOf(accountAddress);
    const assetAmount = await proxyVault.connect(signer).convertToAssets(shareAmount);

    if (isUnderCorrectChain && accountAddress) {
        if (withdrawAmount.gt(assetAmount)) {
            toast.warn('Your balance is not enough.', {
                isLoading: false,
                autoClose: 5000,
                position: 'top-center',
            });
        } else {
            res = await proxyVault
                .connect(signer)
                ['withdraw(uint256,address,address,uint256)'](withdrawAmount, accountAddress, accountAddress, maxLoss);
        }
    }

    return res;
};

export const getUserBalanceInBasisTradingVault = async (proxyAddress, connectedAccount) => {
    let assetAmountInUsdc;
    const { accountAddress, chainId } = connectedAccount;

    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';
    const isUnderCorrectChain = isProduction ? +chainId === 10 : +chainId === 31337;

    const rpcProvider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_OPTIMISIM_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    if (isUnderCorrectChain && accountAddress) {
        const signer = rpcProvider.getSigner(accountAddress);
        const proxyVault = new ethers.Contract(proxyAddress, Erc4626VaultABI, rpcProvider);
        const shareAmount = await proxyVault.connect(signer).balanceOf(accountAddress);
        const assetAmount = await proxyVault.connect(signer).convertToAssets(shareAmount);
        assetAmountInUsdc = ethers.utils.formatUnits(assetAmount, 6);
    }

    return assetAmountInUsdc;
};

export const getOptEstiGasFee = async (maxGas) => {
    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';
    const provider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_OPTIMISIM_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    const gasPrice = await provider.getGasPrice();
    const estiGasFeeInEth = ethers.utils.formatEther(gasPrice.mul(maxGas).toString());

    return estiGasFeeInEth;
};

export const getCapacityOfBasisTrading = async (proxyAddress) => {
    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';
    const provider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_OPTIMISIM_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    const proxyVault = new ethers.Contract(proxyAddress, Erc4626VaultABI, provider);
    const depositLimit = (await proxyVault.depositLimit({ from: '0x2546bcd3c84621e976d8185a91a922ae77ecec30' })).toString();
    const depositLimitInUsdc = ethers.utils.formatUnits(depositLimit, 6);

    return depositLimitInUsdc;
};

export const getTotalAssetInBasisTrading = async (proxyAddress) => {
    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';
    const provider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_OPTIMISIM_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    const proxyVault = new ethers.Contract(proxyAddress, Erc4626VaultABI, provider);
    const totalAsset = (await proxyVault.totalAssets({ from: '0x2546bcd3c84621e976d8185a91a922ae77ecec30' })).toString();
    const totalAssetInUsdc = ethers.utils.formatUnits(totalAsset, 6);

    return totalAssetInUsdc;
};

export const getFeeOfBasisTrading = async (proxyAddress, feeType) => {
    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';
    const provider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_OPTIMISIM_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    const proxyVault = new ethers.Contract(proxyAddress, Erc4626VaultABI, provider);
    const fee = await proxyVault[feeType]({ from: '0x2546bcd3c84621e976d8185a91a922ae77ecec30' });

    return +fee.toString() / 100;
};

export const getFeeOfEthBull = async (vaultAddress, feeType) => {
    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';
    const provider = isProduction
        ? new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_MAINNET_URL)
        : new ethers.providers.JsonRpcProvider(process.env.REACT_APP_DEV_JSONRPC_URL);

    const vault = new ethers.Contract(vaultAddress, VaultABI, provider);
    const fee = await vault[feeType]({ from: '0x2546bcd3c84621e976d8185a91a922ae77ecec30' });

    return +fee.toString() / 100;
};

export const ginzaGetPerpFundingRates = async (baseToken) => {
    let url = `${process.env.REACT_APP_GINZA_API_URL}/feed/funding_rate/${baseToken}`;

    const endTimestamp = +(new Date().getTime() / 1000).toFixed(0);
    const startTimestamp = endTimestamp - 60 * 60 * 24 * 30;

    url += `?start_time=${startTimestamp}&end_time=${endTimestamp}`;

    const options = {
        method: 'get',
        headers: {
            Authorization: process.env.REACT_APP_GINZA_AUTH_KEY,
        },
    };
    const result = await fetch(url, options);
    const resultJson = await result.json();
    // Succeed
    if (result.status === 200) {
        return resultJson.data;
    }
    // Failed
    throw new Error(resultJson.error);
};
