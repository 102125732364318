/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import 'react-multi-carousel/lib/styles.css';
import { Line } from 'react-chartjs-2';
import { PropTypes } from 'prop-types';
import Colors from '../../../styles/Colors';
import { H3, H4 } from '../../../styles/typography';

// eslint-disable-next-line no-unused-vars
const FundingRates = ({ fundingRates }) => {
    const data = fundingRates.map((item) => item.yearlyRate);
    const labels = fundingRates.map((item) => item.timestamp);

    const draggedLine = (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
            const activePoint = chart.tooltip._active[0];
            const { ctx } = chart;
            const { x } = activePoint.element;
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;

            // draw vertical line
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'white';
            ctx.setLineDash([4, 5]);
            ctx.stroke();
            ctx.restore();
        }
    };

    const fundingRateGraph = () => (
        <Line
            plugins={[
                {
                    beforeDraw: draggedLine,
                },
            ]}
            options={{
                layout: {
                    // padding: {
                    //     top: 20,
                    //     left: 15,
                    //     right: 15,
                    //     bottom: 5,
                    // },
                    // autoPadding: true,
                },
                scales: {
                    y: {
                        display: true,
                        // max: 600,
                        // min: 0,
                        grid: {
                            color: 'rgba(266, 266, 266, 0.1)',
                            display: true,
                        },
                        ticks: {
                            color: Colors.gray3,
                            callback(val) {
                                return `${val}%`;
                            },
                        },
                    },
                    x: {
                        display: true,
                        grid: {
                            display: false,
                        },
                        ticks: {
                            color: Colors.gray3,
                            maxRotation: 0,
                            align: 'start',
                            autoSkip: true,
                            autoSkipPadding: 12,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: true,
                        intersect: false,
                        displayColors: false,
                        callbacks: {
                            label(context) {
                                let label = '';

                                if (context.parsed.y !== null) {
                                    label = `${context.parsed.y.toFixed(2)}%`;
                                }

                                return label;
                            },
                        },
                        caretPadding: 6,
                    },
                },
                animation: false,
                responsive: true,
                maintainAspectRatio: false,
                hover: {
                    mode: 'nearest',
                    intersect: false,
                },
            }}
            data={{
                // eslint-disable-next-line max-len
                labels: labels.map((time) => `${new Date(time * 1000).getMonth() + 1}-${new Date(time * 1000).getDate()}`),
                datasets: [
                    {
                        id: 1,
                        data,
                        borderColor: '#00cc98',
                        pointRadius: 6,
                        pointBorderColor: 'rgba(0, 0, 0, 0)',
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointHoverBackgroundColor: '#00cc98',
                        pointHoverBorderColor: '#00cc98',
                    },
                ],
            }}
        />
    );

    return (
        <Container>
            <Title>Perpetual Annualized Funding Rate</Title>
            <Text>
                Funding rates are periodic payments either to traders that are long or short based on the difference between perpetual
                contract markets and spot prices. In other words, traders will either pay or receive funding when trading perpetual
                contracts. In general, when the funding rate is positive, the price of the perpetual contract is higher than the spot price,
                thus, traders who are long should pay for short positions. Conversely, a negative funding rate indicates that perpetual
                prices are below the spot price, which means that traders who are short should pay for long positions.
            </Text>
            <MainContainer>
                <GraphContainer>{fundingRateGraph()}</GraphContainer>
            </MainContainer>
        </Container>
    );
};

FundingRates.propTypes = {
    fundingRates: PropTypes.array,
};

FundingRates.defaultProps = {
    fundingRates: [],
};

export default FundingRates;

const Container = styled.div`
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        max-width: none;
    }
    @media (max-width: 576px) {
        max-width: none;
    }
`;

const Title = styled(H3)`
    color: ${Colors.ivory};
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 40px;
    font-weight: bold;
    font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    @media (max-width: 992px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 24px;
    }
    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 20px;
    }
`;

const Text = styled(H4)`
    color: ${Colors.ivory};
    font-size: 14px;
    line-height: 22px;
    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const MainContainer = styled.div`
    width: 100%;
    height: 300px;
    margin-top: 20px;
    padding: 16px 24px;
    background-color: rgba(0, 0, 0, 0.3);
    border-width: 0;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        height: 300px;
    }
    @media (max-width: 576px) {
        height: 250px;
    }
`;

const GraphContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 12px 0;
    position: relative;
    overflow: hidden;
    @media (max-width: 768px) {
        padding: 0;
    }
`;
