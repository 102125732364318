/* eslint-disable react/forbid-prop-types */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Colors from '../../styles/Colors';

import { H2, H3, H4 } from '../../styles/typography';

const Title = (props) => {
    const { vaultConstData, capacity, totalBalance } = props;
    const {
        title: vaultTitle, subtitle: vaultSubtitle, strategy: vaultStrategy, currency, icon, targetedApy,
    } = vaultConstData;

    return (
        <Container>
            <RowContainer>
                <IconContainer>
                    <VaultIcon
                        src={icon}
                        alt='vault icon'
                    />
                </IconContainer>
                <TextContainer>
                    <TitleText>{vaultTitle}</TitleText>
                    <VaultDesc>{vaultSubtitle}</VaultDesc>
                </TextContainer>
            </RowContainer>
            <DataRowContainer>
                <InfoColumn>
                    <InfoTitle>Targeted APY</InfoTitle>
                    <InfoText>{`${targetedApy ? targetedApy.toLocaleString('en', { maximumFractionDigits: 2 }) : '-'} %`}</InfoText>
                </InfoColumn>
                <InfoColumn>
                    <InfoTitle>AUM / Capacity</InfoTitle>
                    <InfoText>
                        {`${totalBalance !== '' ? parseFloat(totalBalance).toLocaleString('en', { maximumFractionDigits: 6 }) : '-'}`}
                        {' / '}
                        {`${
                            parseFloat(capacity) ? parseFloat(capacity).toLocaleString('en', { maximumFractionDigits: 2 }) : '-'
                        } ${currency}`}
                    </InfoText>
                </InfoColumn>
                <InfoColumn>
                    <InfoTitle>Strategy</InfoTitle>
                    <InfoText>{vaultStrategy}</InfoText>
                </InfoColumn>
            </DataRowContainer>
        </Container>
    );
};

Title.propTypes = {
    vaultConstData: PropTypes.object,
    capacity: PropTypes.string,
    totalBalance: PropTypes.string,
};

Title.defaultProps = {
    vaultConstData: {},
    capacity: '',
    totalBalance: '',
};

export default Title;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`;

const IconContainer = styled.div`
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media (max-width: 768px) {
        height: 48px;
    }
`;

const VaultIcon = styled.img`
    height: 100%;
    object-fit: contain;
`;

const TextContainer = styled.div`
    margin-left: 20px;
    @media (max-width: 768px) {
        margin-left: 12px;
    }
`;

const TitleText = styled(H2)`
    font-size: 46px;
    line-height: 54px;
    color: ${Colors.ivory};
    font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    @media (max-width: 992px) {
        font-size: 40px;
        line-height: 48px;
    }
    @media (max-width: 768px) {
        font-size: 32px;
        line-height: 40px;
    }
    @media (max-width: 576px) {
        font-size: 28px;
        line-height: 36px;
    }
`;

const VaultDesc = styled(H4)`
    color: ${Colors.ivory};
    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const DataRowContainer = styled(RowContainer)`
    width: 100%;
    margin-top: 36px;
    @media (max-width: 992px) {
        margin-top: 24px;
    }
    @media (max-width: 576px) {
        flex-direction: column;
        padding: 0 6px;
        margin-top: 30px;
    }
`;

const InfoColumn = styled.div`
    margin-right: 60px;
    @media (max-width: 992px) {
        margin-right: 45px;
    }
    @media (max-width: 576px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 4px 0;
    }
`;

const InfoTitle = styled(H4)`
    color: ${Colors.lightKhaki};
    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
    @media (max-width: 576px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

const InfoText = styled(H3)`
    margin-top: 8px;
    color: ${Colors.ivory};
    font-weight: bold;
    @media (max-width: 992px) {
        font-size: 20px;
        line-height: 32px;
    }
    @media (max-width: 768px) {
        margin-top: 4px;
        font-size: 16px;
        line-height: 24px;
    }
    @media (max-width: 576px) {
        margin-top: 0px;
        font-size: 14px;
        line-height: 20px;
    }
`;
