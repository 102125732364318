/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Colors from '../../styles/Colors';

const GREEN = '#00cc98';
const PINK = '#ef8888';

const CoveredCallGraph = (props) => {
    const {
        width, height, yLabel, strikePriceLabel, optionData, currMarketPrice,
    } = props;

    const [prices, setPrices] = useState([]);
    const [yAxisRatio, setYAxisRatio] = useState(500);

    const xAxisStartPoint = 20;
    const xAxisLength = 280;
    const yAxisStartPoint = 30;
    const yAxisLength = 150;

    // eslint-disable-next-line no-unsafe-optional-chaining
    useEffect(() => {
        if (optionData[0]?.strikePrice) {
            setYAxisRatio(optionData[0].strikePrice / 5);
        }
    }, []);

    const getProfitInETH = (price) => {
        const totalPremium = optionData.reduce((pre, curr) => pre + curr.subTotalPremium, 0);
        const profit = totalPremium;
        const lossProfit = optionData.reduce(
            (pre, curr) => pre + (price >= curr.strikePrice && ((price - curr.strikePrice) * curr.amount) / price),
            0,
        );
        return profit - lossProfit;
    };

    const maxPrice = optionData[optionData.length - 1] && optionData[optionData.length - 1].strikePrice + 100;
    const minPrice = optionData[0] && optionData[0].strikePrice - 300;

    const getX = (price) => (xAxisLength * (price - minPrice)) / (maxPrice - minPrice) + xAxisStartPoint;
    const getY = (price) => yAxisStartPoint + yAxisLength / 2 - getProfitInETH(price) * yAxisRatio;

    const getPriceList = (options, startPrice) => {
        const priceList = [];

        // Add MinPrice to Price List
        priceList.push(startPrice);

        for (let i = 0; i < options.length; i += 1) {
            // Add Strike Prices to Price List
            priceList.push(options[i].strikePrice);

            const yAxisIsZero = yAxisStartPoint + yAxisLength / 2;
            const currYPoint = getY(options[i].strikePrice);
            const nextYPoint = getY(options[i + 1] ? options[i + 1].strikePrice : options[i].strikePrice + 150);

            // Add Break Point Prices to Price List
            if (currYPoint < yAxisIsZero && nextYPoint > yAxisIsZero) {
                const slope = (nextYPoint - currYPoint)
                    / (getX(options[i + 1] ? options[i + 1].strikePrice : options[i].strikePrice + 150) - getX(options[i].strikePrice));
                const breakPointX = getX(options[i].strikePrice) + (yAxisIsZero - currYPoint) / slope;
                const breakPointPrice = ((breakPointX - xAxisStartPoint) * (maxPrice - minPrice)) / xAxisLength + minPrice;

                priceList.push(breakPointPrice);
            }
        }

        const lastStrikePricePoint = getProfitInETH(options[options.length - 1].strikePrice);
        const strikePriceNextPoint = getProfitInETH(options[options.length - 1].strikePrice + 10);
        const lastSlope = (strikePriceNextPoint - lastStrikePricePoint) / 10;
        const lastStrikePriceY = getY(options[options.length - 1].strikePrice);
        const lastPointY = yAxisStartPoint + yAxisLength;
        const breakPoint = (lastStrikePriceY - lastPointY) / lastSlope;

        priceList.push(options[options.length - 1].strikePrice + breakPoint / yAxisRatio);

        return priceList.sort((a, b) => a - b);
    };

    useEffect(() => {
        if (optionData.length > 0) {
            setPrices(getPriceList(optionData, minPrice));
        }
    }, [optionData]);

    const getLinePoints = (items) => {
        const linePoints = [];
        for (let i = 0; i < items.length - 1; i += 1) {
            linePoints.push({ start: items[i], end: items[i + 1] });
        }

        return linePoints;
    };

    return (
        <ContainerView
            width={width}
            height={height}
        >
            {prices.length > 0 && (
                <svg
                    height={height}
                    width={width}
                    viewBox='0 0 300 220'
                >
                    {/* x axis line */}
                    <line
                        x1={xAxisStartPoint}
                        y1={yAxisStartPoint + yAxisLength / 2}
                        x2={xAxisStartPoint + xAxisLength}
                        y2={yAxisStartPoint + yAxisLength / 2}
                        stroke='white'
                        strokeWidth='1'
                    />
                    <polygon
                        points={`${xAxisStartPoint},${yAxisStartPoint - 2} ${xAxisStartPoint - 5},${yAxisStartPoint + 8} ${
                            xAxisStartPoint + 5
                        },${yAxisStartPoint + 8}`}
                        fill='white'
                        strokeWidth='0.5'
                    />

                    {/* y axis line */}
                    <line
                        x1={xAxisStartPoint}
                        y1={yAxisStartPoint}
                        x2={xAxisStartPoint}
                        y2={yAxisStartPoint + yAxisLength}
                        stroke='white'
                        strokeWidth='1.5'
                    />
                    <LabelText
                        key={Math.random()}
                        x={-60}
                        textAnchor='start'
                        y={yAxisStartPoint - 20}
                        fontSize={10}
                        transform='rotate(-90)'
                    >
                        {yLabel}
                    </LabelText>
                    <polygon
                        points={`${xAxisStartPoint + xAxisLength + 2},${yAxisStartPoint + yAxisLength / 2} ${
                            xAxisStartPoint + xAxisLength - 8
                        },${yAxisStartPoint + yAxisLength / 2 + 5} ${xAxisStartPoint + xAxisLength - 8},${
                            yAxisStartPoint + yAxisLength / 2 - 5
                        }`}
                        fill='white'
                        strokeWidth='0.5'
                    />

                    {/* coverd call lines */}
                    {getLinePoints(prices).map((item) => (
                        <line
                            key={item.start}
                            x1={getX(item.start)}
                            y1={getY(item.start)}
                            x2={getX(item.end)}
                            y2={getY(item.end)}
                            stroke={getY(item.start) <= yAxisStartPoint + yAxisLength / 2 ? GREEN : PINK}
                            strokeWidth='2'
                        />
                    ))}

                    {/* strike price lines */}
                    {optionData.map((item) => (
                        <StrokeLine
                            key={item.strikePrice}
                            x1={getX(item.strikePrice)}
                            y1={yAxisStartPoint}
                            x2={getX(item.strikePrice)}
                            y2={yAxisStartPoint + yAxisLength}
                            strokeWidth='1'
                            strokeDasharray='7, 7'
                        />
                    ))}

                    {/* Market Price */}
                    {currMarketPrice >= minPrice && currMarketPrice <= maxPrice && (
                        <>
                            <MarketPriceLine
                                x1={getX(currMarketPrice)}
                                y1={yAxisStartPoint}
                                x2={getX(currMarketPrice)}
                                y2={yAxisStartPoint + yAxisLength}
                                strokeWidth='1'
                                // strokeDasharray='3, 5'
                            />
                            <MarketPriceLabelText
                                x={getX(currMarketPrice)}
                                textAnchor='middle'
                                y={yAxisStartPoint - 10}
                                fontSize={10}
                                fontStyle='italic'
                            >
                                Current Price
                            </MarketPriceLabelText>
                            <circle
                                key={Math.random()}
                                cx={getX(currMarketPrice)}
                                cy={getY(currMarketPrice)}
                                fill='white'
                                stroke={Colors.lightKhaki}
                                strokeWidth='1.5'
                                r='5'
                            />
                        </>
                    )}

                    {currMarketPrice < minPrice && (
                        <MarketPriceLabelText
                            x={xAxisStartPoint}
                            textAnchor='start'
                            y={yAxisStartPoint - 10}
                            fontSize={10}
                            fontStyle='italic'
                        >
                            {`Current Price < $${minPrice.toLocaleString()}`}
                        </MarketPriceLabelText>
                    )}

                    {currMarketPrice > maxPrice && (
                        <MarketPriceLabelText
                            x={xAxisStartPoint + xAxisLength}
                            textAnchor='end'
                            y={yAxisStartPoint - 10}
                            fontSize={10}
                            fontStyle='italic'
                        >
                            {`Current Price > $${maxPrice.toLocaleString()}`}
                        </MarketPriceLabelText>
                    )}

                    {/* x axis label */}
                    <rect
                        x={xAxisStartPoint + xAxisLength + 2}
                        y={yAxisStartPoint}
                        width='150'
                        height={yAxisStartPoint + yAxisLength}
                        style={{ fill: Colors.midNightGreen }}
                    />

                    <rect
                        x={xAxisStartPoint + xAxisLength + 2}
                        y={yAxisStartPoint}
                        width='150'
                        height={yAxisStartPoint + yAxisLength}
                        style={{ fill: 'rgba(0, 0, 0, 0.3)' }}
                    />

                    <LabelText
                        key={Math.random()}
                        x={xAxisStartPoint + xAxisLength + 10}
                        textAnchor='start'
                        y={yAxisStartPoint + yAxisLength / 2 - 4}
                        fontSize={10}
                        fill='red'
                    >
                        ETH
                    </LabelText>

                    <LabelText
                        key={Math.random()}
                        x={xAxisStartPoint + xAxisLength + 10}
                        textAnchor='start'
                        y={yAxisStartPoint + yAxisLength / 2 + 10}
                        fontSize={10}
                        fill='red'
                    >
                        Price
                    </LabelText>

                    {/* strike price labels */}
                    <rect
                        x={xAxisStartPoint}
                        y={yAxisStartPoint + yAxisLength}
                        width={xAxisStartPoint + xAxisLength}
                        height='150'
                        style={{ fill: Colors.midNightGreen }}
                    />

                    <rect
                        x={xAxisStartPoint}
                        y={yAxisStartPoint + yAxisLength}
                        width={xAxisStartPoint + xAxisLength}
                        height='150'
                        style={{ fill: 'rgba(0, 0, 0, 0.3)' }}
                    />

                    <PriceLabelText
                        key={Math.random()}
                        x={xAxisStartPoint + 10}
                        textAnchor='start'
                        y={yAxisStartPoint + yAxisLength + 25}
                        fontSize={12}
                        fontStyle='italic'
                    >
                        {strikePriceLabel}
                    </PriceLabelText>

                    {optionData.map((item) => (
                        <PriceLabelText
                            key={Math.random()}
                            x={getX(item.strikePrice)}
                            textAnchor='middle'
                            y={yAxisStartPoint + yAxisLength + 25}
                            fontSize={12}
                            fontStyle='italic'
                        >
                            {`$${item.strikePrice.toLocaleString()}`}
                        </PriceLabelText>
                    ))}
                </svg>
            )}
        </ContainerView>
    );
};

CoveredCallGraph.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    yLabel: PropTypes.string,
    strikePriceLabel: PropTypes.string,
    optionData: PropTypes.array,
    currMarketPrice: PropTypes.number,
};

CoveredCallGraph.defaultProps = {
    yLabel: '',
    strikePriceLabel: '',
    optionData: [],
    currMarketPrice: 0,
};

export default CoveredCallGraph;

const ContainerView = styled.div`
    align-items: center;
    justify-content: center;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    border-radius: 16px;
`;

const LabelText = styled.text.attrs(() => ({
    fill: Colors.gray2,
}))``;

const PriceLabelText = styled.text.attrs(() => ({
    fill: Colors.gray2,
}))``;

// const ProfitLabelText = styled.text.attrs(() => ({
//     fill: '#00cc98',
//     fontWeight: 'bold',
// }))``;

const StrokeLine = styled.line.attrs(() => ({
    stroke: 'white',
}))``;

const MarketPriceLine = styled.line.attrs(() => ({
    stroke: Colors.lightKhaki,
}))``;

const MarketPriceLabelText = styled.text.attrs(() => ({
    fill: Colors.lightKhaki,
}))``;

// const MinPriceLabelText = styled.text.attrs(() => ({
//     fill: '#8C8C8C',
// }))``;
