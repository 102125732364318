/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Colors from '../../../styles/Colors';

import { H3, H4 } from '../../../styles/typography';
import { getFeeOfBasisTrading } from '../../../utils/diamond';

const FeeStructure = (props) => {
    const { vaultConstData } = props;
    const { contractAddress: proxyAddress } = vaultConstData;

    const [managementFee, setManagementFee] = useState(0);
    const [performanceFee, setPerformanceFee] = useState(0);

    const getFee = async () => {
        const mFee = await getFeeOfBasisTrading(proxyAddress, 'managementFee');
        setManagementFee(mFee);
        const pFee = await getFeeOfBasisTrading(proxyAddress, 'performanceFee');
        setPerformanceFee(pFee);
    };

    useEffect(() => {
        if (proxyAddress) {
            getFee();
        }
    }, [proxyAddress]);

    return (
        <Container>
            <Title>Fee Structure</Title>
            <FeeText>{`The vault fee structure consists of a ${managementFee}% annualized management fee and a ${performanceFee}% performance fee.`}</FeeText>
        </Container>
    );
};

FeeStructure.propTypes = {
    vaultConstData: PropTypes.object,
};

FeeStructure.defaultProps = {
    vaultConstData: {},
};

export default FeeStructure;

const Container = styled.div`
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        max-width: none;
    }
`;

const Title = styled(H3)`
    color: ${Colors.ivory};
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 40px;
    font-weight: bold;
    font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    @media (max-width: 992px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 24px;
    }
    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 20px;
    }
`;

const FeeText = styled(H4)`
    color: ${Colors.ivory};
    font-size: 14px;
    line-height: 22px;
    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;
