/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../../styles/Colors';

import { H3, H4 } from '../../../styles/typography';
import arrow from '../../../images/arrow.png';
import link from '../../../images/link.png';

const TX_NUM_PER_PAGE = 5;

const Transaction = (props) => {
    const { vaultConstData, txDataDesc } = props;
    const { currency } = vaultConstData;

    const [totalPage, setTotalPage] = useState(1);
    const [currPage, setCurrPage] = useState(1);

    useEffect(() => {
        if (txDataDesc.rows?.length > 0) {
            const pageNum = Math.ceil(txDataDesc.rows.length / TX_NUM_PER_PAGE);
            setTotalPage(pageNum);
        }
    }, [txDataDesc]);

    const handlePrePageOnClick = () => {
        if (currPage > 1) {
            setCurrPage(currPage - 1);
        }
    };

    const handleNextPageOnClick = () => {
        if (currPage < totalPage) {
            setCurrPage(currPage + 1);
        }
    };

    return (
        <Container>
            <Title>Vault Transactions</Title>
            <TransactionHisContainer>
                <HeaderRow>
                    <Column />
                    <Column>
                        <HeaderText>Option</HeaderText>
                    </Column>
                    <ColumnAlignedRight>
                        <HeaderText>Quantity</HeaderText>
                    </ColumnAlignedRight>
                    <ColumnAlignedRight>
                        <HeaderText>PnL</HeaderText>
                    </ColumnAlignedRight>
                    <IconColumn />
                </HeaderRow>
                {txDataDesc?.total_rows > 0
                    && txDataDesc.rows.slice(TX_NUM_PER_PAGE * (currPage - 1), TX_NUM_PER_PAGE * currPage).map((tx) => (
                        <BodyRow key={Math.random()}>
                            <ActionColumn>
                                <ActionRow>
                                    <Icon
                                        src={arrow}
                                        alt='arrow'
                                        action='Sold Option'
                                    />
                                    <TitleContainer>
                                        <ActionText>Sold Option</ActionText>
                                        <SubText>{tx.time.slice(0, 10)}</SubText>
                                    </TitleContainer>
                                </ActionRow>
                            </ActionColumn>
                            <Column>
                                <LabelText>Option</LabelText>
                                <InfoColumn>
                                    <MainText>{tx.data.name}</MainText>
                                    <SubText>{`Strike Price: ${parseFloat(tx.data.strikePrice).toLocaleString('en', {})}`}</SubText>
                                </InfoColumn>
                            </Column>
                            <ColumnAlignedRight>
                                <LabelText>Quantity</LabelText>
                                <InfoColumn>
                                    <MainText>
                                        {parseFloat(tx.data.quantity).toLocaleString('en', {
                                            maximumFractionDigits: 6,
                                            minimumFractionDigits: 2,
                                        })}
                                    </MainText>
                                </InfoColumn>
                            </ColumnAlignedRight>
                            <ColumnAlignedRight>
                                <LabelText>PnL</LabelText>
                                <InfoColumn>
                                    <MainText>
                                        {`${parseFloat(tx.data.yield).toLocaleString('en', {
                                            maximumFractionDigits: 6,
                                            minimumFractionDigits: 2,
                                        })} ${currency}`}
                                    </MainText>
                                </InfoColumn>
                            </ColumnAlignedRight>
                            <IconColumn>
                                <a
                                    target='_blank'
                                    href={`${process.env.REACT_APP_ETHERSCAN}${tx.data.txHash}`}
                                    rel='noreferrer'
                                >
                                    <LinkIcon
                                        src={link}
                                        alt='link'
                                        action='Sold Option'
                                    />
                                </a>
                            </IconColumn>
                        </BodyRow>
                    ))}
            </TransactionHisContainer>
            <PaginationContainer>
                <PaginationBtn
                    onClick={handlePrePageOnClick}
                    disabled={!(currPage > 1)}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </PaginationBtn>
                <PageNumber>{`${currPage} / ${totalPage}`}</PageNumber>
                <PaginationBtn
                    onClick={handleNextPageOnClick}
                    disabled={!(currPage < totalPage)}
                >
                    <FontAwesomeIcon icon={faChevronRight} />
                </PaginationBtn>
            </PaginationContainer>
        </Container>
    );
};

Transaction.propTypes = {
    vaultConstData: PropTypes.object,
    txDataDesc: PropTypes.object,
};

Transaction.defaultProps = {
    vaultConstData: {},
    txDataDesc: {},
};

export default Transaction;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Title = styled(H3)`
    color: ${Colors.ivory};
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 40px;
    font-weight: bold;
    font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    @media (max-width: 992px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 24px;
    }
    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 20px;
    }
`;

const TransactionHisContainer = styled.div`
    width: 100%;
    margin-top: 32px;
    @media (max-width: 992px) {
        margin-top: 24px;
    }
    @media (max-width: 768px) {
        margin-top: 18px;
    }
`;

const Row = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1.2fr 1fr 1fr 1fr 40px;
    padding: 16px 16px;
    align-items: center;
    @media (max-width: 576px) {
        grid-template-columns: 1fr;
    }
`;

const HeaderRow = styled(Row)`
    @media (max-width: 576px) {
        display: none;
    }
`;

const BodyRow = styled(Row)`
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    margin-bottom: 18px;
`;

const Column = styled.div`
    flex: 1 1 100px;
    padding: 0 4px;
    @media (max-width: 576px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 6px 0px;
    }
`;

const ActionColumn = styled(Column)`
    @media (max-width: 576px) {
        border-bottom: 1px solid ${Colors.gray5};
        padding: 6px 0px 8px 0px;
        margin-bottom: 6px;
    }
`;

const IconColumn = styled.div`
    flex: 0 0 80px;
    margin: auto;
    padding: 0 4px 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 576px) {
        display: none;
    }
`;

const ColumnAlignedRight = styled(Column)`
    text-align: right;
`;

const HeaderText = styled(H4)`
    color: ${Colors.ivory};
    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const ActionRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const Icon = styled.img`
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 14px;
    transform: ${(props) => (props.action === 'Sold Option' ? 'rotate(-90deg)' : 'rotate(90deg)')};
    @media (max-width: 576px) {
        /* margin-right: 8px;
        width: 20px;
        height: 20px; */
        display: none;
    }
`;

const LinkIcon = styled.img`
    width: 14px;
    height: 14px;
    object-fit: contain;
    transform: ${(props) => (props.action === 'Sold Option' ? 'rotate(-90deg)' : 'rotate(90deg)')};
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    @media (max-width: 768px) {
        width: 10px;
        height: 10px;
    }
`;

const MainText = styled(H4)`
    color: ${Colors.ivory};
    @media (max-width: 992px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const ActionText = styled(MainText)`
    @media (max-width: 576px) {
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
    }
`;

const SubText = styled(H4)`
    font-size: 14px;
    line-height: 22px;
    margin-top: 4px;
    color: ${Colors.gray4};
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const LabelText = styled(H4)`
    display: none;
    @media (max-width: 576px) {
        display: flex;
        font-size: 12px;
        line-height: 16px;
        color: ${Colors.ivory};
    }
`;

const InfoColumn = styled.div`
    @media (max-width: 576px) {
        text-align: right;
    }
`;

const TitleContainer = styled.div`
    @media (max-width: 576px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

const PaginationContainer = styled.div`
    margin: 32px auto 0 auto;
    padding: 4px 16px;
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    @media (max-width: 992px) {
        padding: 2px 12px;
    }
    @media (max-width: 768px) {
        padding: 2px 8px;
        margin: 20px auto 0 auto;
    }
`;

const PageNumber = styled(H4)`
    padding: 8px;
    margin: 0 6px;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.gray3};
    @media (max-width: 992px) {
        font-size: 16px;
        line-height: 22px;
    }
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
        margin: 0 4px;
    }
`;

const PaginationBtn = styled.button`
    padding: 8px;
    margin: 2px 6px 0px 6px;
    font-size: 18px;
    line-height: 24px;
    color: ${(props) => (props.disabled ? Colors.gray6 : Colors.gray2)};
    :hover {
        color: ${(props) => (props.disabled ? Colors.gray6 : Colors.ivory)};
    }
    @media (max-width: 992px) {
        font-size: 16px;
        line-height: 22px;
        margin: 2px 4px 0px 4px;
    }
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
        margin: 2px 2px 0px 2px;
    }
`;
