import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../../styles/Colors';
import Button from './Button';

const blockchains = [
    { label: 'Ethereum', chainId: 1 },
    { label: 'Optimisim', chainId: 10 },
];

const SelectNetworkModal = (props) => {
    const {
        onClickNext, selectChainId, setSelectChainId, handleOpenOptInfoModal,
    } = props;

    const { isOpen, handleClose } = props;

    const location = useLocation();
    const { pathname } = location;

    const getIsDisabled = (chainId, path) => {
        let isDisabled;
        if (path === '/vaults/ETH-Bull' && chainId !== 1) {
            isDisabled = true;
        } else if (path === '/vaults/ETH-Cash-Carry' && chainId !== 10) {
            isDisabled = true;
        } else {
            isDisabled = false;
        }
        return isDisabled;
    };

    useEffect(() => {
        if (pathname === '/vaults/ETH-Bull') {
            setSelectChainId(1);
        } else if (pathname === '/vaults/ETH-Cash-Carry') {
            setSelectChainId(10);
        }
    }, [pathname]);

    return (
        <WalletModal
            show={isOpen}
            onHide={handleClose}
        >
            <ModalHeader
                closeButton
                closeVariant='white'
            >
                <ModalTitle>Select A Blockchain</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <NetworkContainer>
                    {blockchains.map((blockchain) => {
                        const isDisabled = getIsDisabled(+blockchain.chainId, pathname);
                        return (
                            <NetworkItem
                                key={blockchain.chainId}
                                isSelected={selectChainId === blockchain.chainId}
                                onClick={() => !isDisabled && setSelectChainId(blockchain.chainId)}
                                disabled={isDisabled}
                            >
                                <h3>{blockchain.label}</h3>
                                {blockchain.chainId === 10 && (
                                    <Icon
                                        icon={faInfoCircle}
                                        onClick={() => {
                                            handleOpenOptInfoModal();
                                        }}
                                    />
                                )}
                            </NetworkItem>
                        );
                    })}
                </NetworkContainer>
                <Button
                    btnType='primary'
                    btnText='Next'
                    onClick={onClickNext}
                />
            </ModalBody>
        </WalletModal>
    );
};

SelectNetworkModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onClickNext: PropTypes.func.isRequired,
    selectChainId: PropTypes.number.isRequired,
    setSelectChainId: PropTypes.func.isRequired,
    handleOpenOptInfoModal: PropTypes.func,
};

SelectNetworkModal.defaultProps = { handleOpenOptInfoModal: () => {} };

export default SelectNetworkModal;

const WalletModal = styled(Modal)`
    .modal-content {
        margin: 20vh auto auto auto;
        width: 90%;
        max-width: 350px;
        border-radius: 20px;
        border: 1px solid ${Colors.gray5};
        background-color: ${Colors.midNightGreen};
        color: ${Colors.ivory};
        padding: 20px 12px 24px 12px;
    }
    backdrop-filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.3);
`;

const ModalHeader = styled(Modal.Header)`
    border-width: 0;
    color: ${Colors.ivory};
`;

const ModalTitle = styled(Modal.Title)`
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
`;

const ModalBody = styled(Modal.Body)``;

const NetworkContainer = styled.div`
    margin-bottom: 30px;
`;

const NetworkItem = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    padding: 16px 18px;
    border: 1px solid ${Colors.midNightGreen};
    border-radius: 10px;
    border: ${(props) => `1px solid ${props.isSelected ? Colors.lightKhaki : Colors.ivory}`};
    color: ${Colors.ivory};
    box-shadow: ${(props) => (props.isSelected ? `0 0 2px ${Colors.lightKhaki}` : 'none')};
    margin-bottom: 20px;
    opacity: ${(props) => props.disabled && 0.3};
    :hover {
        background-color: ${(props) => !props.disabled && 'rgba(0, 0, 0, 0.1)'};
    }
`;

const Icon = styled(FontAwesomeIcon)`
    margin-left: 8px;
    :hover {
        color: ${Colors.lightKhaki};
    }
`;
