import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import styled from 'styled-components';
import { ToastContainer, Flip } from 'react-toastify';
import ReactGA from 'react-ga4';
import { GlobalStyle, ResetStyle } from './styles/globalStyle';
import Colors from './styles/Colors';
import 'react-toastify/dist/ReactToastify.css';

import Layout from './components/layout/layout';
import VaultsPage from './pages/VaultsPage';
import BasisTradingVault from './pages/VaultInfoPage/BasisTradingVault';
import EthBullVault from './pages/VaultInfoPage/EthBullVault';
// import StakingPage from './pages/StakingPage';
import NoMatchPage from './pages/NoMatchPage';
import NewPage from './pages/NewPage';

ReactGA.initialize('G-2XXJ4779V6');

const App = () => {
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
    }, []);

    return (
        <Router>
            <ResetStyle />
            <GlobalStyle />
            <Container>
                <Layout>
                    <Routes>
                        <Route
                            exact
                            path='/'
                            element={<VaultsPage />}
                        />
                        <Route
                            exact
                            path='/vaults/ETH-Cash-Carry'
                            element={<BasisTradingVault />}
                        />
                        <Route
                            exact
                            path='/vaults/ETH-Bull'
                            element={<EthBullVault />}
                        />
                        {/* <Route
                        exact
                        path='/vaults/ETH-Bull-2x'
                        element={<VaultInfoPage />}
                    /> */}
                        {/* <Route
                        exact
                        path='/staking'
                        element={<StakingPage />}
                    /> */}
                        <Route
                            path='/new'
                            element={<NewPage />}
                        />
                        <Route
                            path='*'
                            element={<NoMatchPage />}
                        />
                    </Routes>
                </Layout>
                <StyledContainer
                    position='bottom-left'
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover
                    limit={1}
                    transition={Flip}
                />
            </Container>
        </Router>
    );
};

export default App;

const Container = styled.div`
    width: 100vw;
    min-height: 100vh;
    background-color: ${Colors.midNightGreen};
`;

const StyledContainer = styled(ToastContainer).attrs({})`
    .Toastify__toast-container {
    }
    .Toastify__toast {
        background-color: ${Colors.gray6};
        color: ${Colors.ivory};
    }
    .Toastify__toast-body {
        font-size: 16px;
        line-height: 22px;
    }
    @media (min-width: 768px) {
        width: 70%;
        max-width: 400px;
    }
`;
