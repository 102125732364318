import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../../styles/Colors';

import Title from '../TitleComponent';
import ActionColumn from './ActionColumn';
import Performance from '../OptPerformanceComponent';
import Strategy from './Strategy';
import FeeStructure from './FeeStructure';
import Disclaimer from '../DisclaimerComponent';
import FundingRates from './FundingRates';

import {
    getVaultApy,
    getBasisTradingVaultCapacity,
    getBasisTradingAum,
    setActiveNetworkChainId,
} from '../../../redux/actions/actionCreater';
import { vaultConstData } from '../../../utils/constant/vaults';
import { getGinzaSharePrices, ginzaGetPerpFundingRates } from '../../../utils/diamond';
import { perpVaultDisclaimers } from '../../../utils/constant/disclaimer';
import { H4 } from '../../../styles/typography';
import { openNewTab } from '../../../utils/common';

const OPT_BRIDGE = 'https://app.optimism.io/bridge';

const VaultInfoPage = () => {
    const vaultCapacities = useSelector((state) => state.vault.vaultCapacities);
    const vaultTotalBalances = useSelector((state) => state.vault.vaultTotalBalances);
    const vaultUserBalances = useSelector((state) => state.vault.vaultUserBalances);
    const vaultIsUnlocked = useSelector((state) => state.vault.vaultIsUnlocked);
    const vaultApys = useSelector((state) => state.vault.vaultApys);
    const vaultTokenPriceDiffs = useSelector((state) => state.vault.vaultTokenPriceDiffs);

    const [matchedVaultConstData, setMatchedVaultConstData] = useState({});
    const [sharePrices, setSharePrices] = useState([]);
    const [fundingRates, setFundingRates] = useState([]);

    const dispatch = useDispatch();
    const location = useLocation();

    const pathName = location.pathname;

    const getSharePrices = async (id) => {
        const _sharePrices = await getGinzaSharePrices(id);
        setSharePrices(_sharePrices);
    };

    const getFundingRates = async () => {
        const ETH_TOKEN = '0x8C835DFaA34e2AE61775e80EE29E2c724c6AE2BB';
        const rates = await ginzaGetPerpFundingRates(ETH_TOKEN);
        const parsedRates = rates.map((item) => {
            const yearlyRate = +item.dailyFundingRate * 365 * 100;
            const _item = item;
            _item.yearlyRate = yearlyRate;
            return _item;
        });
        setFundingRates(parsedRates);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getFundingRates();
    }, []);

    useEffect(() => {
        const data = vaultConstData.filter((vault) => pathName === vault.path)?.[0];
        setMatchedVaultConstData(data);
    }, []);

    // Get Vault Info From Blockchain
    useEffect(() => {
        const { contractAddress, id } = matchedVaultConstData;
        if (contractAddress) {
            dispatch(getBasisTradingAum(contractAddress, id));
            dispatch(getBasisTradingVaultCapacity(contractAddress, id));
        }
    }, [matchedVaultConstData]);

    // Get Vault Info from Ginza
    useEffect(() => {
        const { ginzaId, id: vaultId, chainId } = matchedVaultConstData;
        if (chainId) {
            dispatch(setActiveNetworkChainId(chainId.toString()));
        }
        if (ginzaId) {
            dispatch(getVaultApy(ginzaId, vaultId));
            getSharePrices(ginzaId);
        }
    }, [matchedVaultConstData]);

    return (
        <Container>
            <Row>
                <Title
                    vaultConstData={matchedVaultConstData}
                    capacity={vaultCapacities[matchedVaultConstData.id]}
                    totalBalance={vaultTotalBalances[matchedVaultConstData.id]}
                    vaultApy={vaultApys[matchedVaultConstData.id]}
                />
            </Row>
            <Row>
                <LeftContainer>
                    <Performance
                        vaultApy={vaultApys[matchedVaultConstData.id]}
                        tokenPriceDiff={vaultTokenPriceDiffs[matchedVaultConstData.id]}
                        vaultRound={sharePrices}
                    />
                    <Strategy fundingRates={fundingRates} />
                    <FundingRates fundingRates={fundingRates} />
                    <FeeStructure vaultConstData={matchedVaultConstData} />
                </LeftContainer>
                <RightContainer>
                    <ActionContainer>
                        <ActionColumn
                            vaultConstData={matchedVaultConstData}
                            userBalance={vaultUserBalances[matchedVaultConstData.id]}
                            isUnlocked={vaultIsUnlocked[matchedVaultConstData.id]}
                        />
                    </ActionContainer>
                    <BridgeBtn onClick={() => openNewTab(OPT_BRIDGE)}>
                        <div>
                            <BridgeBtnText>Optimisim token Bridge</BridgeBtnText>
                            <BridgeBtnSubText>Deposit tokens to the Optimisim network.</BridgeBtnSubText>
                        </div>
                        <BridgeBtnIcon icon={faArrowRight} />
                    </BridgeBtn>
                </RightContainer>
            </Row>
            <Section>
                <Disclaimer disclaimerList={perpVaultDisclaimers} />
            </Section>
        </Container>
    );
};

export default VaultInfoPage;

const Container = styled.div`
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    min-height: calc(100vh - 150px);
    background-color: ${Colors.midNightGreen};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 24px 80px 24px;
    @media (max-width: 768px) {
        padding: 30px 16px 60px 16px;
    }
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const Section = styled(Row)`
    margin-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        margin-top: 70px;
    }
`;

const LeftContainer = styled.div`
    width: calc(100% - 350px);
    margin-top: 60px;
    display: grid;
    grid-gap: 60px;
    overflow: hidden;
    @media (max-width: 768px) {
        width: 100%;
        max-width: none;
    }
`;

const RightContainer = styled.div`
    width: 350px;
    margin-left: 24px;
    margin-top: 70px;
    @media (max-width: 768px) {
        width: 100%;
        margin-left: 0px;
        margin-top: 40px;
        order: -1;
    }
`;

const ActionContainer = styled.div`
    border: 1px solid ${Colors.lightKhaki};
    border-radius: 16px;
    padding: 24px;
`;

const BridgeBtn = styled.button`
    width: 100%;
    margin-top: 24px;
    padding: 12px 24px;
    border: 1px solid ${Colors.lightKhaki};
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${Colors.ivory};
    :hover {
        /* border: 1px solid ${Colors.lightKhaki}; */
        h4,
        svg {
            color: ${Colors.lightKhaki};
        }
    }
`;

const BridgeBtnIcon = styled(FontAwesomeIcon)`
    color: ${Colors.ivory};
`;

const BridgeBtnText = styled(H4)`
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    text-align: left;
    color: ${Colors.ivory};
`;

const BridgeBtnSubText = styled(BridgeBtnText)`
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
`;
