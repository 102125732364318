import { combineReducers } from 'redux';
import memberReducer from './memberReducer';
import vaultReducer from './vaultReducer';
import modalReducer from './modalReducer';

const allReducers = combineReducers({
    member: memberReducer,
    vault: vaultReducer,
    modal: modalReducer,
});

export default allReducers;
