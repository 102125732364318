import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Colors from '../../styles/Colors';
import VaultCard from './VaultCard';
import background from '../../images/background.png';
import { vaultConstData } from '../../utils/constant/vaults';

const NewPage = () => {
    const vaultApys = useSelector((state) => state.vault.vaultApys);

    const newVaults = vaultConstData.filter((vault) => vault.type === 'newVault');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <BackgroundImage
                src={background}
                alt='background image'
            />
            <CategoryContainer>
                <VaultsContainer>
                    {newVaults.map((vault) => (
                        <VaultCard
                            key={vault.id}
                            vaultConstData={vault}
                            totalBalance=''
                            userBalance=''
                            vaultApy={vaultApys[vault.id]}
                        />
                    ))}
                </VaultsContainer>
            </CategoryContainer>
        </Container>
    );
};

export default NewPage;

const Container = styled.div`
    width: 100%;
    background-color: ${Colors.midNightGreen};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0 80px 0;
    position: relative;
    overflow: hidden;
    @media (max-width: 992px) {
        padding: 24px 0;
    }
`;

const BackgroundImage = styled.img`
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
`;

const CategoryContainer = styled.div`
    width: 100%;
    max-width: 1020px;
    margin-top: 72px;
    position: relative;
    @media (max-width: 992px) {
        width: 90%;
        margin-top: 60px;
    }
`;

const VaultsContainer = styled.div`
    display: flex;
    grid-gap: 28px;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 1200px) {
        grid-gap: 32px;
        justify-content: center;
    }
    @media (max-width: 992px) {
        grid-gap: 18px;
    }
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;
