import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend,
} from 'chart.js';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import Colors from '../../styles/Colors';

const Graph = (props) => {
    const { priceType } = props;

    const getGradient = (context, type) => {
        let width;
        let height;
        let gradient;
        const { chart } = context;
        const { ctx, chartArea } = chart;
        if (chartArea) {
            const chartWidth = chartArea.right - chartArea.left;
            const chartHeight = chartArea.bottom - chartArea.top;
            if (!gradient || width !== chartWidth || height !== chartHeight) {
                // Create the gradient because this is either the first render
                // or the size of the chart has changed
                width = chartWidth;
                height = chartHeight;
                gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                if (type === 'positive') {
                    gradient.addColorStop(0, '#013d4591');
                    gradient.addColorStop(1, '#00cc98d1');
                } else {
                    gradient.addColorStop(0, '#ef8888e0');
                    gradient.addColorStop(1, '#013d4591');
                }
            }
        }

        return gradient;
    };

    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

    // const draggedLine = (chart) => {
    //     if (chart.tooltip._active && chart.tooltip._active.length) {
    //         const positionZero = chart.scales.y.getPixelForValue(0);
    //         // const positionFirstPoint = chart.scales.y.getPixelForValue(2);
    //         const activePoint = chart.tooltip._active[0];
    //         const { ctx } = chart;
    //         const { x, y } = activePoint.element;
    //         const topY = chart.scales.y.top;
    //         const bottomY = chart.scales.y.bottom;

    //         // if (y === positionFirstPoint) {
    //         //     setSituation(1);
    //         // } else if (y > positionFirstPoint && y <= positionZero) {
    //         //     setSituation(2);
    //         // } else if (y > positionZero) {
    //         //     setSituation(3);
    //         // }

    //         // draw vertical line
    //         ctx.save();
    //         ctx.beginPath();
    //         ctx.moveTo(x, topY);
    //         ctx.lineTo(x, bottomY);
    //         ctx.lineWidth = 1;
    //         ctx.strokeStyle = y > positionZero ? '#ef8888' : '#00cc98';
    //         ctx.setLineDash([4, 5]);
    //         ctx.stroke();
    //         ctx.restore();
    //     }
    // };

    const strikePriceLine = (chart) => {
        const { ctx } = chart;
        const topY = chart.scales.y.top;
        const bottomY = chart.scales.y.bottom - 15;
        const breakpoint = chart._metasets[0].data[2];

        const { x } = breakpoint;
        // draw vertical line
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1.5;
        ctx.strokeStyle = 'white';
        ctx.setLineDash([7, 9]);
        ctx.stroke();

        ctx.textAlign = 'center';
        ctx.font = '12px Arial';
        ctx.fillStyle = 'white';
        ctx.fillText('Strike Price', x, bottomY + 15);

        ctx.restore();
    };

    const currentPriceLine = (chart) => {
        const { ctx } = chart;
        const topY = chart.scales.y.top;
        const bottomY = chart.scales.y.bottom - 15;

        const belowPoint = chart._metasets[1].data[1];
        const withinPoint = chart._metasets[1].data[3];
        const abovePoint = chart._metasets[1].data[5];

        let targetPoint;
        switch (priceType) {
            case 'below':
                targetPoint = belowPoint;
                break;
            case 'within':
                targetPoint = withinPoint;
                break;
            case 'above':
                targetPoint = abovePoint;
                break;
            default:
                break;
        }

        const { x } = targetPoint;
        // draw vertical line
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY + 15);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = Colors.lightKhaki;
        // ctx.setLineDash([7, 9]);
        ctx.stroke();

        ctx.textAlign = 'center';
        ctx.font = 'italic 12px Arial';
        ctx.fillStyle = Colors.lightKhaki;
        ctx.fillText('ETH Price', x + 2, topY + 10);

        ctx.restore();
    };

    const yAxisTriangle = (chart) => {
        const { ctx } = chart;
        const topY = chart.scales.y.top;
        const startPoint = chart._metasets[0].data[0];

        const { x } = startPoint;
        // draw vertical line
        ctx.save();

        ctx.beginPath();
        ctx.moveTo(x, topY - 2);
        ctx.lineTo(x - 5, topY + 8);
        ctx.lineTo(x + 5, topY + 8);
        ctx.fillStyle = 'white';

        ctx.fill();

        ctx.restore();
    };

    const xAxisTriangle = (chart) => {
        const { ctx } = chart;
        const breakpoint = chart._metasets[0].data[4];
        const lastPoint = chart._metasets[1].data[chart._metasets[1].data.length - 1];

        const { y } = breakpoint;
        const { x } = lastPoint;
        // draw vertical line
        ctx.save();

        ctx.beginPath();
        ctx.moveTo(x + 2, y);
        ctx.lineTo(x - 8, y + 5);
        ctx.lineTo(x - 8, y - 5);
        ctx.fillStyle = 'white';

        ctx.fill();

        ctx.restore();
    };

    return (
        <Container>
            <Line
                plugins={[
                    // {
                    //     afterDraw: draggedLine,
                    // },
                    { afterDraw: strikePriceLine },
                    { afterDraw: currentPriceLine },
                    { afterDraw: yAxisTriangle },
                    { afterDraw: xAxisTriangle },
                ]}
                options={{
                    color: 'white',
                    scales: {
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Profit',
                                color: 'white',
                                align: 'end',
                                font: {
                                    size: 11,
                                },
                            },
                            grid: { borderColor: 'white', display: false },
                            ticks: { color: 'white', display: false },
                            grace: 2,
                            markerEnd: 'url(#triangle)',
                        },
                        x: {
                            title: {
                                display: priceType !== 'above',
                                text: ['ETH Spot Price', 'at Contract', 'Expiration'],
                                color: 'white',
                                align: 'end',
                                position: 'top',
                                padding: {
                                    top: -52,
                                    right: -20,
                                },
                                font: {
                                    size: 10,
                                },
                            },
                            grid: { borderColor: 'white', display: false },
                            ticks: { color: 'white', display: false },
                            position: {
                                y: 0,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                        },
                    },
                    animation: false,
                    responsive: true,
                }}
                data={{
                    labels: ['1', '2', '3', '4', '5', '6', '7'],
                    datasets: [
                        {
                            id: 1,
                            label: 'hi',
                            data: [2, 2, 2, 1, 0],
                            borderColor: '#00cc98',
                            pointBackgroundColor: ['#00cc98', '#00cc98', 'white', '#00cc98', '#00cc98'],
                            pointRadius: [0, 0, 4, 0, 0],
                            pointBorderWidth: 2,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 2,
                            backgroundColor: '#00cc98',
                            fill:
                                priceType !== 'above'
                                    ? { above: (context) => getGradient(context, 'positive'), target: { value: 0 } }
                                    : false,
                        },
                        {
                            id: 1,
                            label: 'hi',
                            data: [2, 2, 2, 1, 0, -1, -2],
                            borderColor: '#ef8888',
                            pointBackgroundColor: '#ef8888',
                            pointRadius: 0,
                            pointBorderWidth: 2,
                            pointHoverRadius: 0,
                            pointHoverBackgroundColor: '#ef8888',
                            pointHoverBorderColor: '#ef8888',
                            pointHoverBorderWidth: 2,
                            fill:
                                priceType === 'above'
                                    ? { below: (context) => getGradient(context, 'negative'), target: { value: 0 } }
                                    : false,
                        },
                    ],
                }}
            />
        </Container>
    );
};

Graph.propTypes = {
    // setSituation: PropTypes.func.isRequired,
    priceType: PropTypes.string,
};

Graph.defaultProps = {
    // setSituation: PropTypes.func.isRequired,
    priceType: '',
};

export default Graph;

const Container = styled.div`
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 992px) {
        width: 250px;
    }
    @media (max-width: 768px) {
        width: 240px;
    }
`;
