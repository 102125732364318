export const production = {
    // ETH BULL VAULT
    ETHBULL_VAULT_ADDRESS: '0xad48a8261b0690c71b70115035eb14afd9a43242',
    ETHBULL_OPTION_ADDRESS: '0xC3afd92F985B5805Ad7070612499F16F2f6aC98c',
    ETHBULL_VAULT_ID: '8caf53b4-598c-4d16-b959-30ef4eac8529',

    // BASIS TRADING VAULT
    BASISTRADING_PROXY_ADDRESS: '0xD576bE0d3CC1c0184d1ea3F1778A4A9Dec523859',
    BASISTRADING_VAULT_ID: '2bd5be03-9f0a-4252-a71f-6aa5ace6de10',
};

export const develop = {
    // ETH BULL VAULT
    ETHBULL_VAULT_ADDRESS: '',
    ETHBULL_OPTION_ADDRESS: '',
    ETHBULL_VAULT_ID: '8caf53b4-598c-4d16-b959-30ef4eac8529',

    // BASIS TRADING VAULT
    BASISTRADING_PROXY_ADDRESS: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
    BASISTRADING_VAULT_ID: '2bd5be03-9f0a-4252-a71f-6aa5ace6de10',
};
