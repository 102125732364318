import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import WalletConnect from '@walletconnect/client';
import QRCodeModal from '@walletconnect/qrcode-modal';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../styles/Colors';
import SelectWalletModal from '../common/SelectWalletModal';
import SelectNetworkModal from '../common/SelectNetworkModal';
import Contacts from '../common/Contacts';
import {
    setBrowserProvider,
    setConnectedAccount,
    setUserMainEthBalance,
    setSelectWalletModalStatus,
    setSelectNetworkModalStatus,
    setUserOptUsdcBalance,
    setOptInfoModalStatus,
} from '../../redux/actions/actionCreater';
import useOutsideClick from '../../utils/hooks/useOutSideClick';

import menu from '../../images/menu.png';
import cross from '../../images/cross.png';
import eth from '../../images/eth.png';
import op from '../../images/op.png';
import logo from '../../images/logo.png';
import OptInfoModal from '../common/OptInfoModal';
import { H4 } from '../../styles/typography';

const WALLETCONNECT_BRIDGE_URL = process.env.REACT_APP_WALLETCONNECT_BRIDGE_URL;
const DOCS_URL = process.env.REACT_APP_DOCS_URL;

const navItems = [
    {
        label: 'DOCS',
        value: DOCS_URL,
        disabled: false,
        external: true,
    },
    {
        label: 'VAULTS',
        value: '',
        disabled: false,
        external: false,
    },
    {
        label: 'NEW',
        value: 'new',
        disabled: false,
        external: false,
    },
    {
        label: 'STAKING',
        value: 'staking',
        disabled: true,
        external: false,
    },
];

const networks = {
    1: { label: 'Ethereum', icon: eth, hex: '0x1' },
    10: { label: 'Optimisim', icon: op, hex: '0xA' },
    31337: { label: 'Test', icon: eth, hex: '0x7A69' },
};

const Header = () => {
    const connectedAccount = useSelector((state) => state.member.connectedAccount);
    const accountAddress = useSelector((state) => state.member.connectedAccount.accountAddress);
    const currChainId = useSelector((state) => state.member.connectedAccount.chainId);
    const selectWalletModalIsOpen = useSelector((state) => state.modal.selectWalletModalIsOpen);
    const selectNetworkModalIsOpen = useSelector((state) => state.modal.selectNetworkModalIsOpen);
    const optInfoModalIsOpen = useSelector((state) => state.modal.optInfoModalIsOpen);

    const [navIsOpen, setNavIsOpen] = useState(false);
    const [selectChainId, setSelectChainId] = useState(1);
    const [selectMethod, setSelectMethod] = useState('MetaMask');
    const [disconnectIsShow, setDisconnectIsShow] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const ref = useRef();

    const isDappBrowser = Boolean(window.ethereum);

    useEffect(() => {
        if (location.pathname === '/' && currChainId) {
            setSelectChainId(+currChainId);
        }
    }, [currChainId, location]);

    const setSelectModalIsOpen = async (status) => {
        dispatch(setSelectWalletModalStatus({ status }));
    };

    const setSelectChainModalIsOpen = async (status) => {
        dispatch(setSelectNetworkModalStatus({ status }));
    };

    const setOptInfoModalIsOpen = async (status) => {
        dispatch(setOptInfoModalStatus({ status }));
    };

    const setConnectedWallet = async (address, chainIdString, connectSource) => {
        dispatch(setConnectedAccount({ accountAddress: address, chainId: chainIdString, connectOption: connectSource }));
    };

    const handleMetaMaskAccountChange = (accounts) => {
        if (!accounts[0]) {
            setConnectedWallet('', window.ethereum.networkVersion, '');
        } else if (accounts[0] && accounts[0] !== accountAddress) {
            setConnectedWallet(accounts[0], window.ethereum.networkVersion, 'MetaMask');
        }
    };

    const getCurrentAccount = () => {
        window.ethereum
            .request({ method: 'eth_accounts' })
            .then(handleMetaMaskAccountChange)
            .catch((err) => {
                // eslint-disable-next-line
                console.error(err);
            });
    };

    // Detect Account / Network Changes
    if (isDappBrowser) {
        window.ethereum.on('accountsChanged', () => {
            getCurrentAccount();
        });

        window.ethereum.on('chainChanged', () => {
            getCurrentAccount();
        });
    }

    useEffect(() => {
        if (isDappBrowser) {
            getCurrentAccount();
        }
    }, []);

    /*
     * Handle MetaMask
     */

    const connectMetaMask = async (targetChainId) => {
        const chainIdInHex = networks[+targetChainId].hex;
        if (isDappBrowser) {
            // Check if user is under correct network
            if (targetChainId === currChainId) {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                setConnectedWallet(accounts[0], window.ethereum.networkVersion, 'MetaMask');
                setSelectModalIsOpen(false);
                if (connector.connected) {
                    await connector.killSession();
                }
            } else {
                try {
                    // Check if the chain to connect to is installed
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: chainIdInHex }], // chainId must be in hexadecimal numbers
                    });
                    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                    setConnectedWallet(accounts[0], window.ethereum.networkVersion, 'MetaMask');
                    setSelectModalIsOpen(false);
                    // Kill WalletConnect connect session
                    if (connector.connected) {
                        await connector.killSession();
                    }
                } catch (error) {
                    if (error.code === 4902) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [
                                    {
                                        chainId: chainIdInHex,
                                        rpcUrls: ['https://mainnet.optimism.io'],
                                        chainName: 'Optimistic Ethereum',
                                        nativeCurrency: {
                                            symbol: 'ETH',
                                            decimals: 18,
                                        },
                                        blockExplorerUrls: ['https://optimistic.etherscan.io'],
                                    },
                                ],
                            });
                        } catch (addError) {
                            console.error(addError);
                        }
                    }
                    console.error(error);
                }
            }
        }
    };

    /*
     * Handle WalletConnect
     */

    const connector = new WalletConnect({
        bridge: WALLETCONNECT_BRIDGE_URL,
        qrcodeModal: QRCodeModal,
    });

    const connectWalletConnect = async () => {
        if (!connector.connected) {
            connector.createSession();
        } else {
            await connector.killSession();
            connector.createSession();
        }
    };

    connector.on('connect', (error, payload) => {
        if (error) {
            throw error;
        }
        const { accounts, chainId: walletConnectChainId } = payload.params[0];

        // Check if the network WalletConncet connect to is same as the target blockchain
        if (walletConnectChainId === selectChainId) {
            setConnectedWallet(accounts[0], walletConnectChainId, 'WalletConnect');
        } else {
            setConnectedWallet('', '', '');
        }
        setSelectModalIsOpen(false);
    });

    connector.on('session_update', (error, payload) => {
        if (error) {
            throw error;
        }

        // Get updated accounts and chainId
        const { accounts, chainId: walletConnectChainId } = payload.params[0];
        setConnectedWallet(accounts[0], walletConnectChainId, 'WalletConnect');
        setSelectModalIsOpen(false);
    });

    connector.on('disconnect', () => {
        window.location.reload();
    });

    useEffect(() => {
        if (connectedAccount.accountAddress) {
            dispatch(setBrowserProvider(connectedAccount));
            dispatch(setUserMainEthBalance(connectedAccount, 'main', 'eth'));
            dispatch(setUserOptUsdcBalance(connectedAccount, 'opt', 'usdc'));
        } else {
            dispatch(setBrowserProvider());
        }
    }, [connectedAccount]);

    const handleNavOnClick = (nav) => {
        if (nav.external) {
            const newWindow = window.open(nav.value, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
            if (navIsOpen) {
                setNavIsOpen(false);
            }
        } else {
            navigate(`/${nav.value}`);
            setNavIsOpen(false);
        }
    };

    useOutsideClick(ref, () => setDisconnectIsShow(false));

    const getSlicedAddr = (address) => `${address.slice(0, 6)}...${address.slice(address.length - 4, address.length)}`;

    return (
        <Container>
            <MainHeader>
                <FixedContainer>
                    <LogoContainer onClick={() => navigate('/')}>
                        <LogoImage
                            src={logo}
                            alt='coinomo logo'
                        />
                    </LogoContainer>
                </FixedContainer>
                <Menu onClick={() => setNavIsOpen(!navIsOpen)}>
                    <MenuImage
                        src={menu}
                        alt='menu icon'
                    />
                </Menu>
                <FlexContainer open={navIsOpen}>
                    <Close
                        isShow={navIsOpen}
                        onClick={() => setNavIsOpen(!navIsOpen)}
                    >
                        <CloseImage
                            src={cross}
                            alt='close icon'
                        />
                    </Close>
                    <Navs>
                        <NavList>
                            {navItems.map((nav) => (
                                <Nav
                                    isActive={location.pathname === `/${nav.value}`}
                                    onClick={!nav.disabled ? () => handleNavOnClick(nav) : null}
                                    key={nav.value}
                                    disabled={nav.disabled}
                                >
                                    {nav.label}
                                </Nav>
                            ))}
                        </NavList>
                    </Navs>
                    <ButtonContainer>
                        {!!accountAddress && (
                            <EthContainer onClick={() => setSelectChainModalIsOpen(true)}>
                                <EthImage
                                    src={networks[currChainId]?.icon}
                                    alt='eth icon'
                                />
                                <EthText>{networks[currChainId]?.label}</EthText>
                            </EthContainer>
                        )}
                        <ConnectWalletBtn
                            ref={ref}
                            onClick={!accountAddress ? () => setSelectChainModalIsOpen(true) : () => setDisconnectIsShow(!disconnectIsShow)}
                        >
                            {accountAddress ? (
                                <AddressRowContainer>
                                    <AddressText>{getSlicedAddr(accountAddress)}</AddressText>
                                    <DownIcon icon={faAngleDown} />
                                </AddressRowContainer>
                            ) : (
                                'CONNECT WALLET'
                            )}
                        </ConnectWalletBtn>
                        <DisconnectContainer isShow={disconnectIsShow}>
                            <DisconnectBtn
                                onClick={() => {
                                    setDisconnectIsShow(false);
                                    setConnectedWallet('', '', '');
                                }}
                            >
                                Disconnect
                            </DisconnectBtn>
                        </DisconnectContainer>
                    </ButtonContainer>
                    <ContactsContainer>
                        <Contacts />
                    </ContactsContainer>
                </FlexContainer>
            </MainHeader>
            <SelectNetworkModal
                isOpen={selectNetworkModalIsOpen}
                handleClose={() => setSelectChainModalIsOpen(false)}
                onClickNext={() => {
                    setSelectModalIsOpen(true);
                    setSelectChainModalIsOpen(false);
                }}
                selectChainId={selectChainId}
                setSelectChainId={setSelectChainId}
                handleOpenOptInfoModal={() => {
                    setSelectChainModalIsOpen(false);
                    setOptInfoModalIsOpen(true);
                }}
            />
            <SelectWalletModal
                isOpen={selectWalletModalIsOpen}
                handleClose={() => setSelectModalIsOpen(false)}
                selectMethod={selectMethod}
                setSelectMethod={setSelectMethod}
                connectToMetaMask={() => connectMetaMask(selectChainId)}
                connectToWalletConnect={() => connectWalletConnect()}
            />
            <OptInfoModal
                isOpen={optInfoModalIsOpen}
                handleClose={() => {
                    setOptInfoModalIsOpen(false);
                    setSelectChainModalIsOpen(true);
                }}
            />
        </Container>
    );
};

export default Header;

const Container = styled.div`
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    font-family: Iceberg, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
`;

const MainHeader = styled.header`
    width: 100%;
    height: 90px;
    padding: 0 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    backdrop-filter: blur(100px);
    transition: all 300ms ease-in-out;
    @media (max-width: 1200px) {
        height: 70px;
    }
`;

const FixedContainer = styled.div`
    flex: 0 1 350px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const FlexContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 1200px) {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: ${(props) => (props.open ? '100vh' : '0px')};
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        background-color: ${Colors.midNightGreen};
        transition: all 350ms ease-in-out;
    }
`;

const Navs = styled.nav`
    flex: 1 0 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1200px) {
        width: 100%;
        flex: initial;
    }
`;

const ButtonContainer = styled.div`
    flex: 0 1 350px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    @media (max-width: 1200px) {
        flex: initial;
        flex-direction: column;
    }
`;

const ConnectWalletBtn = styled.button`
    width: 190px;
    min-height: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    cursor: default;
    color: ${Colors.lightKhaki};
    padding: 0px 12px;
    border: 1px solid ${Colors.lightKhaki};
    border-radius: 8px;
    @media (max-width: 1200px) {
        margin: 30px 0 30px 0;
        width: 180px;
    }
`;

const DisconnectContainer = styled.div`
    width: 190px;
    max-height: ${(props) => (props.isShow ? '300px' : '0px')};
    position: absolute;
    top: calc(100% + 12px);
    right: 0px;
    overflow-y: hidden;
    transition: 300ms ease-in-out all;
    @media (max-width: 1200px) {
        width: 180px;

        position: static;
    }
`;

const DisconnectBtn = styled.button`
    width: 100%;
    padding: 8px 12px;
    border: 1px solid ${Colors.ivory};
    border-radius: 8px;
    background-color: rgb(12, 75, 83);
    color: ${Colors.lightKhaki};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    @media (max-width: 1200px) {
        background-color: inherit;
        border: none;
        font-size: 20px;
        line-height: 30px;
        margin: 12px 0 20px 0;
    }
    :hover {
        color: ${Colors.ivory};
    }
`;

const NavList = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media (max-width: 1200px) {
        width: 100%;
        flex-direction: column;
    }
`;

const Nav = styled.li`
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    padding: 0 18px;
    cursor: ${(props) => (props.disabled ? 'inherit' : 'pointer')};
    color: ${(props) => (props.isActive ? `${Colors.ivory}` : `${Colors.lightKhaki}`)};
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    :hover {
        color: ${(props) => (props.disabled ? Colors.lightKhaki : Colors.ivory)};
    }
    @media (max-width: 1200px) {
        padding: 16px 0;
        width: 100%;
        text-align: center;
    }
`;

const Menu = styled.div`
    display: none;
    @media (max-width: 1200px) {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
`;

const Close = styled.div`
    display: none;
    @media (max-width: 1200px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        padding: 20px;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
`;

const LogoImage = styled.img`
    width: 188px;
    height: 44px;
    @media (max-width: 1200px) {
        width: 157px;
        height: 36px;
    }
`;

const EthContainer = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0px 16px 0px 12px;
    min-height: 40px;
    border: 1px solid rgba(220, 220, 220, 0.3);
    border-radius: 8px;
    :hover {
        background-color: rgba(220, 220, 220, 0.1);
    }
    @media (max-width: 1200px) {
        margin-right: 0px;
        margin-top: 12px;
        padding: 4px 0;
        justify-content: center;
        width: 180px;
    }
`;

const EthImage = styled.img`
    width: 24px;
    height: 20px;
    object-fit: contain;
`;

const EthText = styled.h2`
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    color: ${Colors.lightKhaki};
    margin-left: 4px;
`;

const MenuImage = styled.img`
    width: 36px;
    height: 36px;
    object-fit: contain;
`;

const CloseImage = styled.img`
    width: 36px;
    height: 36px;
    object-fit: contain;
`;

const ContactsContainer = styled.div`
    display: none;
    @media (max-width: 1200px) {
        flex-direction: row;
        justify-content: center;
        display: flex;
        margin-top: 16px;
    }
`;

const AddressRowContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const AddressText = styled(H4)`
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: ${Colors.lightKhaki};
`;

const DownIcon = styled(FontAwesomeIcon)`
    font-size: 20px;
    margin-left: 12px;
`;
