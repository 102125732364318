import {
    SET_SELECT_WALLET_MODAL_STATUS,
    SET_CONNECTED_WALLET_MODAL_STATUS,
    SET_SELECT_NETWORK_MODAL_STATUS,
    SET_ACTIVE_NETWORK_CHAINID,
    SET_OPT_INFO_MODAL_STATUS,
} from '../actions/actionType';

const initialState = {
    selectWalletModalIsOpen: false,
    connectedWalletModalIsOpen: false,
    selectNetworkModalIsOpen: false,
    activeNetworkId: '',
    optInfoModalIsOpen: false,
};

// eslint-disable-next-line default-param-last
const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECT_WALLET_MODAL_STATUS: {
            const { status } = action.payload;
            return {
                ...state,
                selectWalletModalIsOpen: status,
            };
        }
        case SET_CONNECTED_WALLET_MODAL_STATUS: {
            const { status } = action.payload;
            return {
                ...state,
                connectedWalletModalIsOpen: status,
            };
        }
        case SET_SELECT_NETWORK_MODAL_STATUS: {
            const { status } = action.payload;
            return {
                ...state,
                selectNetworkModalIsOpen: status,
            };
        }
        case SET_ACTIVE_NETWORK_CHAINID: {
            return {
                ...state,
                activeNetworkId: action.payload,
            };
        }
        case SET_OPT_INFO_MODAL_STATUS: {
            const { status } = action.payload;
            return {
                ...state,
                optInfoModalIsOpen: status,
            };
        }
        default:
            return state;
    }
};

export default modalReducer;
