import {
    VAULT_DEPOSIT_START,
    VAULT_DEPOSIT_SUCCEED,
    VAULT_DEPOSIT_FAILED,
    VAULT_WITHDRAW_START,
    VAULT_WITHDRAW_SUCCEED,
    VAULT_WITHDRAW_FAILED,
    RESET_DEPOSIT_HASH,
    RESET_WITHDRAW_HASH,
    GET_VAULT_CAPACITY,
    GET_VAULT_TOTAL_BALANCE,
    GET_VAULT_USER_BALANCE,
    GET_VAULT_TX_DATA_DESC,
    GET_VAULT_TX_DATA_ASC,
    GET_VAULT_STATUS,
    OPTION_PAY_PREMIUM_START,
    OPTION_PAY_PREMIUM_SUCCEED,
    OPTION_PAY_PREMIUM_FAILED,
    GET_VAULT_ESTI_APY,
    GET_VAULT_TOKEN_PRICE,
    GET_VAULT_ROUND_DATA,
    MAKER_APPROVE_WETH_START,
    MAKER_APPROVE_WETH_SUCCEED,
    MAKER_APPROVE_WETH_FAILED,
} from '../actions/actionType';

const initialState = {
    vaultDeposit: {
        isProcessing: false,
        isFinished: false,
        errorMsg: '',
    },
    vaultWithdraw: {
        isProcessing: false,
        isFinished: false,
        errorMsg: '',
    },
    depositHash: '',
    withdrawHash: '',
    approveWETHHash: '',
    vaultUserBalances: {},
    vaultTotalBalances: {},
    vaultCapacities: {},
    vaultTxDataDescs: {},
    vaultTxDataAscs: {},
    vaultIsUnlocked: {},
    optionPayPremium: {
        isProcessing: false,
        isFinished: false,
        errorMsg: '',
    },
    vaultApys: {},
    vaultTokenPriceDiffs: {},
    payPremiumHash: '',
    vaultRounds: {},
    approveWETH: {
        isProcessing: false,
        isFinished: false,
        errorMsg: '',
    },
};

// eslint-disable-next-line default-param-last
const vaultReducer = (state = initialState, action) => {
    switch (action.type) {
        case VAULT_DEPOSIT_START: {
            return {
                ...state,
                vaultDeposit: {
                    isProcessing: true,
                    isFinished: false,
                    errorMsg: '',
                },
            };
        }
        case VAULT_DEPOSIT_SUCCEED: {
            const { hash } = action.payload;
            return {
                ...state,
                vaultDeposit: {
                    isProcessing: false,
                    isFinished: true,
                    errorMsg: '',
                },
                depositHash: hash,
            };
        }
        case VAULT_DEPOSIT_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                vaultDeposit: {
                    isProcessing: false,
                    isFinished: false,
                    errorMsg: error,
                },
            };
        }
        case VAULT_WITHDRAW_START: {
            return {
                ...state,
                vaultWithdraw: {
                    isProcessing: true,
                    isFinished: false,
                    errorMsg: '',
                },
            };
        }
        case VAULT_WITHDRAW_SUCCEED: {
            const { hash } = action.payload;
            return {
                ...state,
                vaultWithdraw: {
                    ...state.vaultWithdraw,
                    isProcessing: false,
                    isFinished: true,
                    errorMsg: '',
                },
                withdrawHash: hash,
            };
        }
        case VAULT_WITHDRAW_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                vaultWithdraw: {
                    ...state.vaultWithdraw,
                    isProcessing: false,
                    isFinished: false,
                    errorMsg: error,
                },
            };
        }
        case GET_VAULT_TOTAL_BALANCE: {
            const { balance, id } = action.payload;
            return {
                ...state,
                vaultTotalBalances: {
                    ...state.vaultTotalBalances,
                    [id]: balance,
                },
            };
        }
        case GET_VAULT_USER_BALANCE: {
            const { balance, id } = action.payload;
            return {
                ...state,
                vaultUserBalances: {
                    ...state.vaultUserBalances,
                    [id]: balance,
                },
            };
        }
        case RESET_DEPOSIT_HASH: {
            return {
                ...state,
                depositHash: '',
            };
        }
        case RESET_WITHDRAW_HASH: {
            return {
                ...state,
                withdrawHash: '',
            };
        }
        case GET_VAULT_CAPACITY: {
            const { capacity, id } = action.payload;
            return {
                ...state,
                vaultCapacities: {
                    ...state.vaultCapacities,
                    [id]: capacity,
                },
            };
        }
        case GET_VAULT_TX_DATA_DESC: {
            const { txData, id } = action.payload;
            return {
                ...state,
                vaultTxDataDescs: {
                    ...state.vaultTxDataDescs,
                    [id]: txData,
                },
            };
        }
        case GET_VAULT_TX_DATA_ASC: {
            const { txData, id } = action.payload;
            return {
                ...state,
                vaultTxDataAscs: {
                    ...state.vaultTxDataAscs,
                    [id]: txData,
                },
            };
        }
        case GET_VAULT_STATUS: {
            const { isUnlocked, id } = action.payload;
            return {
                ...state,
                vaultIsUnlocked: {
                    ...state.vaultIsUnlocked,
                    [id]: isUnlocked,
                },
            };
        }
        case OPTION_PAY_PREMIUM_START: {
            return {
                ...state,
                optionPayPremium: {
                    isProcessing: true,
                    isFinished: false,
                    errorMsg: '',
                },
            };
        }
        case OPTION_PAY_PREMIUM_SUCCEED: {
            const { hash } = action.payload;
            return {
                ...state,
                optionPayPremium: {
                    isProcessing: false,
                    isFinished: true,
                    errorMsg: '',
                },
                payPremiumHash: hash,
            };
        }
        case OPTION_PAY_PREMIUM_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                optionPayPremium: {
                    isProcessing: false,
                    isFinished: false,
                    errorMsg: error,
                },
            };
        }
        case GET_VAULT_ESTI_APY: {
            const { data, id } = action.payload;
            const { estimateAPY } = data;
            return {
                ...state,
                vaultApys: {
                    ...state.vaultApys,
                    [id]: parseFloat(estimateAPY),
                },
            };
        }
        case GET_VAULT_TOKEN_PRICE: {
            const { data, id } = action.payload;
            const { currentTokenPrice, originalTokenPrice } = data;
            return {
                ...state,
                vaultTokenPriceDiffs: {
                    ...state.vaultApys,
                    [id]: (+currentTokenPrice - +originalTokenPrice) / +originalTokenPrice,
                },
            };
        }
        case GET_VAULT_ROUND_DATA: {
            const { data, id } = action.payload;
            return {
                ...state,
                vaultRounds: {
                    ...state.vaultRounds,
                    [id]: data,
                },
            };
        }
        case MAKER_APPROVE_WETH_START: {
            return {
                ...state,
                approveWETH: {
                    isProcessing: true,
                    isFinished: false,
                    errorMsg: '',
                },
            };
        }
        case MAKER_APPROVE_WETH_SUCCEED: {
            const { hash } = action.payload;
            return {
                ...state,
                approveWETH: {
                    isProcessing: false,
                    isFinished: true,
                    errorMsg: '',
                },
                approveWETHHash: hash,
            };
        }
        case MAKER_APPROVE_WETH_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                approveWETH: {
                    isProcessing: false,
                    isFinished: false,
                    errorMsg: error,
                },
            };
        }

        default:
            return state;
    }
};

export default vaultReducer;
