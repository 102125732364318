/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { alertText } from '../../../components/common/Toast';
import Button from '../../../components/common/Button';
import Colors from '../../../styles/Colors';
import { H3 } from '../../../styles/typography';

import { waitForTransaction, getDiamondTotalPremium, getDiamondMakerAddress } from '../../../utils/diamond';
import { onApproveWETH, onPayPremium, setSelectWalletModalStatus } from '../../../redux/actions/actionCreater';

const PayPremium = (props) => {
    const { vaultConstData } = props;
    const { optionAddress } = vaultConstData;

    const provider = useSelector((state) => state.member.provider);
    const chainId = useSelector((state) => state.member.connectedAccount.chainId);
    const accountAddress = useSelector((state) => state.member.connectedAccount.accountAddress);
    const payPremiumHash = useSelector((state) => state.vault.payPremiumHash);
    const payPremiumIsProcessing = useSelector((state) => state.vault.optionPayPremium.isProcessing);
    const approveWETHHash = useSelector((state) => state.vault.approveWETHHash);
    const approveWETHIsProcessing = useSelector((state) => state.vault.approveWETH.isProcessing);

    const [totalPremiumAmount, setTotalPremiumAmount] = useState('0');
    const [ethAmount, setEthAmount] = useState('');
    const [makerAddress, setMakerAddress] = useState('');

    const dispatch = useDispatch();

    const isProduction = process.env.REACT_APP_NODE_ENV === 'PRODUCTION';
    const isUnderCorrectNetwork = (isProduction && chainId === 1) || (!isProduction && chainId === 31337);

    useEffect(() => {
        if (optionAddress) {
            getTotalPremium(optionAddress);
            getMakerAddress(optionAddress);
        }
    }, [optionAddress]);

    useEffect(() => {
        if (payPremiumHash) {
            showPendingAlert(payPremiumHash, 'Pay Premium Succeed!');
        }
    }, [payPremiumHash]);

    useEffect(() => {
        if (approveWETHHash) {
            showPendingAlert(approveWETHHash, 'Approve WETH Succeed!');
        }
    }, [approveWETHHash]);

    const isValidNumber = (n) => !Number.isNaN(parseFloat(n)) && !Number.isNaN(n - 0) && parseFloat(n) > 0;

    const handleOnClick = async () => {
        const amount = parseFloat(ethAmount).toString();
        await dispatch(onPayPremium(amount, provider, accountAddress, optionAddress));
        setEthAmount('');
    };

    const handleApproveOnClick = async () => {
        const amount = parseFloat(ethAmount).toString();
        await dispatch(onApproveWETH(amount, provider, accountAddress, optionAddress));
        setEthAmount('');
    };

    const getTotalPremium = async (optAddress) => {
        const premium = await getDiamondTotalPremium(optAddress);
        setTotalPremiumAmount(premium);
        return premium;
    };

    const getMakerAddress = async (optAddress) => {
        const address = await getDiamondMakerAddress(optAddress);
        setMakerAddress(address);
        return address;
    };

    const showPendingAlert = async (hash, finishedText) => {
        const id = toast.loading(alertText(`Transaction hash ${hash} is processing...`));
        await waitForTransaction(provider, hash);
        toast.update(id, {
            render: alertText(finishedText),
            type: 'success',
            isLoading: false,
            autoClose: 5000,
        });
    };

    const handleConnectWallet = async () => {
        dispatch(setSelectWalletModalStatus({ status: true }));
    };

    return (
        <Container hidden={!accountAddress || accountAddress.toUpperCase() !== makerAddress.toUpperCase()}>
            <Title>Pay Premium</Title>
            <ActionContainer>
                <LeftContainer>
                    <InputContainer>
                        <Input
                            type='number'
                            placeholder={totalPremiumAmount}
                            value={ethAmount}
                            onChange={(e) => {
                                setEthAmount(e.target.value);
                            }}
                        />
                    </InputContainer>
                    <InputUnit>WETH</InputUnit>
                </LeftContainer>

                {accountAddress ? (
                    <RightContainer>
                        <Button
                            onClick={handleApproveOnClick}
                            disabled={!isUnderCorrectNetwork || !isValidNumber(ethAmount) || approveWETHIsProcessing}
                            btnType='secondary'
                            btnText={approveWETHIsProcessing ? 'Processing...' : 'Approve WETH'}
                        />
                        <Button
                            onClick={handleOnClick}
                            disabled={!isUnderCorrectNetwork || !isValidNumber(ethAmount) || payPremiumIsProcessing}
                            btnType='primary'
                            btnText={payPremiumIsProcessing ? 'Processing...' : 'Pay Premium'}
                        />
                    </RightContainer>
                ) : (
                    <RightContainer>
                        <Button
                            onClick={handleConnectWallet}
                            btnType='primary'
                            btnText='Connect Wallet'
                            disabled={false}
                        />
                    </RightContainer>
                )}
            </ActionContainer>
        </Container>
    );
};

PayPremium.propTypes = {
    vaultConstData: PropTypes.object,
};

PayPremium.defaultProps = {
    vaultConstData: {},
};

export default PayPremium;

const Container = styled.div`
    width: 100%;
    display: ${(props) => (props.hidden ? 'none' : 'flex')};
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Title = styled(H3)`
    color: ${Colors.ivory};
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 40px;
    font-weight: bold;
    font-family: TT Firs Neue, Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    @media (max-width: 992px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 24px;
    }
    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 20px;
    }
`;
const ActionContainer = styled.div`
    width: 100%;
    padding: 24px;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 8px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const RightContainer = styled(LeftContainer)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    @media (max-width: 768px) {
        margin-top: 18px;
        width: 100%;
    }
`;

const InputContainer = styled.div`
    width: 100%;
    max-width: 300px;
    padding: 4px;
    background-color: ${Colors.gray3};
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 768px) {
        padding: 2px 4px;
        max-width: none;
    }
`;

const Input = styled.input`
    width: 100%;
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    padding: 4px 8px;
    background-color: inherit;
    border: none;
    :focus {
        border: none;
        outline: none;
    }
    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 20px;
        padding: 6px 8px;
    }
`;

const InputUnit = styled(H3)`
    color: ${Colors.ivory};
    margin-left: 12px;
    @media (max-width: 768px) {
        margin-left: 8px;
        font-size: 16px;
        line-height: 28px;
    }
`;
