import usdc from '../../images/vault_usdc.png';
import eth from '../../images/vault_eth.png';
import eth2x from '../../images/vault_ethx2.png';

import { production, develop } from './diamondAddresses';

const isProduction = process.env.REACT_APP_NODE_ENV !== 'DEVELOP';

// eslint-disable-next-line import/prefer-default-export
export const vaultConstData = [
    {
        id: 'basisTradingVault',
        title: 'ETH Cash & Carry',
        description: 'Vault for investors interested in near delta-neutral strategy',
        subtitle: 'Vault for investors interested in near delta-neutral strategy',
        strategy: 'Basis Trading',
        path: '/vaults/ETH-Cash-Carry',
        disabled: false,
        contractAddress: isProduction ? production.BASISTRADING_PROXY_ADDRESS : develop.BASISTRADING_PROXY_ADDRESS,
        optionAddress: '',
        ginzaId: isProduction ? production.BASISTRADING_VAULT_ID : develop.BASISTRADING_VAULT_ID,
        currency: 'USDC',
        icon: usdc,
        estiMaxDepositGas: 170913,
        chainId: 10,
        type: 'lpVault',
        metrics: {
            marketType: 'Neutral',
            leverage: 'None',
            downProtection: 'Yes, max drawdown 3%',
            termDuration: 'One Week',
            riskProfile: 'Moderate & Balanced',
        },
        targetedApy: 15,
    },
    {
        id: 'ethVault',
        title: 'ETH Bull',
        description: 'Deposit ETH and earn premium from ETH option covered call strategy',
        subtitle: 'Vault for ETH hodlers. Earn yields from ETH option strategies.',
        strategy: 'Covered Call',
        path: '/vaults/ETH-Bull',
        disabled: false,
        contractAddress: isProduction ? production.ETHBULL_VAULT_ADDRESS : develop.ETHBULL_VAULT_ADDRESS,
        optionAddress: isProduction ? production.ETHBULL_OPTION_ADDRESS : develop.ETHBULL_OPTION_ADDRESS,
        ginzaId: isProduction ? production.ETHBULL_VAULT_ID : develop.ETHBULL_VAULT_ID,
        currency: 'ETH',
        icon: eth,
        estiMaxDepositGas: 170913,
        chainId: 1,
        type: 'ethVault',
        metrics: {
            marketType: 'Down Market',
            leverage: 'None',
            downProtection: 'No',
            termDuration: 'One Week',
            riskProfile: 'High & Aggressive',
        },
        targetedApy: 30,
    },
    {
        id: 'eth2xVault',
        title: 'ETH Bull 2X',
        description: 'Deposit ETH and open a 2X position with liqudity mining rewards',
        subtitle: '',
        strategy: '-',
        path: '/vaults/ETH-Bull-2x',
        disabled: true,
        contractAddress: '',
        optionAddress: '',
        ginzaId: '',
        currency: 'ETH',
        icon: eth2x,
        estiMaxDepositGas: 0,
        chainId: 1,
        type: 'ethVault',
        metrics: {
            marketType: '',
            leverage: '',
            downProtection: '',
            termDuration: '',
            riskProfile: '',
        },
        targetedApy: 0,
    },
    // {
    //     id: 'usdcMarketNeutralVault',
    //     title: 'USDC Market Neutral',
    //     description: 'Vault for liquidity provider who wants a delta neutral strategy',
    //     subtitle: 'Vault for liquidity provider who wants a delta neutral strategy',
    //     strategy: 'Hedged Liquidity Provision ',
    //     path: '/vaults/USDC-Market-Neutral',
    //     disabled: true,
    //     contractAddress: '',
    //     optionAddress: '',
    //     ginzaId: '',
    //     currency: 'USDC',
    //     icon: usdc,
    //     estiMaxDepositGas: 0,
    //     chainId: 10,
    //     type: 'lpVault',
    // },
    {
        id: 'ethSyntheticCallVault',
        title: 'ETH Synthetic Call',
        description: 'Vault for USDC holder, who wants to achieve returns based on the ETH market',
        subtitle: 'Vault for USDC holder, who wants to achieve returns based on the ETH market',
        strategy: 'Synthetic Covered Call',
        path: '/vaults/ETH-Synthetic-Call',
        disabled: true,
        contractAddress: '',
        optionAddress: '',
        ginzaId: '',
        currency: 'USDC',
        icon: eth,
        estiMaxDepositGas: 0,
        chainId: 10,
        type: 'lpVault',
        metrics: {
            marketType: '',
            leverage: '',
            downProtection: '',
            termDuration: '',
            riskProfile: '',
        },
        targetedApy: 0,
    },
    {
        id: 'activeLiquidityVault',
        title: 'Active Liquidity',
        description: 'Vault for USDC holder, who don’t mind market volatility and want to earn maximum fees for liquidity provision',
        subtitle: 'Vault for USDC holder, who don’t mind market volatility and want to earn maximum fees for liquidity provision',
        strategy: 'Active Liquidity',
        path: '/vaults/Active-Liquidity',
        disabled: true,
        contractAddress: '',
        optionAddress: '',
        ginzaId: '',
        currency: 'USDC',
        icon: eth,
        estiMaxDepositGas: 0,
        chainId: 10,
        type: 'lpVault',
        metrics: {
            marketType: '',
            leverage: '',
            downProtection: '',
            termDuration: '',
            riskProfile: '',
        },
        targetedApy: 0,
    },
    {
        id: 'diamondHand&Farm',
        title: 'Diamond Hand & Farm',
        description: 'Uniswap V3 Structured Product.',
        subtitle: '',
        strategy: 'Hand & Farm',
        path: process.env.REACT_APP_SIMULATOR_URL,
        disabled: false,
        contractAddress: '',
        optionAddress: '',
        ginzaId: '',
        currency: 'USDC',
        icon: eth,
        estiMaxDepositGas: 0,
        chainId: 42161,
        type: 'newVault',
        metrics: {
            marketType: '',
            leverage: '',
            downProtection: '',
            termDuration: '',
            riskProfile: '',
        },
        targetedApy: 0,
    },
];
