import React from 'react';
import styled from 'styled-components';
import Colors from '../../styles/Colors';

import Contacts from '../common/Contacts';

const Footer = () => (
    <Container>
        <MainFooter>
            <ContactsContainer>
                <Contacts />
            </ContactsContainer>
        </MainFooter>
    </Container>
);

export default Footer;

const Container = styled.div`
    width: 100vw;
    height: 70px;
    background-color: ${Colors.midNightGreen};
    font-family: Goldman-Sans, Helvetica Neue, Helvetica, Roboto, PingFang TC, 微軟雅黑, Microsoft Yahei, sans-serif;
    @media (max-width: 768px) {
        position: inherit;
    }
`;

const MainFooter = styled.div`
    width: 100%;
    padding: 12px 48px 12px 32px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 992px) {
        justify-content: center;
    }
`;

const ContactsContainer = styled.div`
    @media (max-width: 992px) {
        display: none;
    }
`;
